import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components-old/link';

const ProposalData = ({ items }) => (
  <div className="proposal-data">
    {items.map(item => (
      <div className="proposal-data--item" key={item.label}>
        <span className="proposal-data--label">{item.label}</span>
        <span className="proposal-data--value">
          {item.text && (
            <span className="proposal-data--value-text">{item.text}</span>
          )}
          {item.links &&
            item.links.map(link => <Link key={link.text} {...link} />)}
        </span>
      </div>
    ))}
  </div>
);

ProposalData.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string.isRequired,
      text: PropTypes.string,
      links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes))
    })
  )
};

ProposalData.defaultProps = {
  items: [{ links: [] }],
  onPageEditing: {}
};

export default ProposalData;
