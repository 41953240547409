/* eslint-disable react/no-multi-comp */

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ContentContainer from 'components/content-container';
import ContentWithSidebar from 'components/content-with-sidebar';
import StickyMenuOnTabs from 'components/sticky-menu-on-tabs';
import TabContentSection from 'components/tab-content-section';

const TabContent = ({ menu, contentSections }) => {
  const tabContent = (
    <React.Fragment>
      {contentSections &&
        contentSections.map((contentSection, index) => (
          <TabContentSection {...contentSection} key={index} />
        ))}
    </React.Fragment>
  );

  const className = 'tab-content';
  return !menu ? (
    <ContentContainer
      theme={ContentContainer.themes.medium}
      className={className}
    >
      {tabContent}
    </ContentContainer>
  ) : (
    <ContentContainer
      className={cn(className, 'tab-content__container')}
      theme={ContentContainer.themes.wide}
    >
      <ContentWithSidebar
        theme={ContentWithSidebar.themes.sidebarLeft}
        className="tab-content__grid"
        useContentContainer={false}
      >
        <ContentWithSidebar.Sidebar>
          <StickyMenuOnTabs {...menu} includeHeader />
        </ContentWithSidebar.Sidebar>
        <ContentWithSidebar.Content className="tab-content__content">
          {tabContent}
        </ContentWithSidebar.Content>
      </ContentWithSidebar>
    </ContentContainer>
  );
};

TabContent.propTypes = {
  contentSections: PropTypes.arrayOf(
    PropTypes.exact(TabContentSection.propTypes)
  ),
  menu: PropTypes.exact(StickyMenuOnTabs.propTypes)
};

TabContent.defaultProps = {};

export default TabContent;
