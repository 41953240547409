import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import debounce from 'lodash/debounce';

import Button from 'components-old/button';
import Form from 'components-old/form';
import TextInput from 'components-old/form-elements/text-input';

class GlobalSearch extends React.Component {
  static propTypes = {
    closeGlobalSearch: PropTypes.func,
    closeButtonText: PropTypes.string.isRequired,
    form: PropTypes.exact(Form.propTypes).isRequired,
    input: PropTypes.exact(TextInput.propTypes).isRequired,
    isOpen: PropTypes.bool,
    button: PropTypes.exact(Button.propTypes).isRequired,
    openGlobalSearch: PropTypes.func,
    submitLabel: PropTypes.string,
    endpoint: PropTypes.string
  };

  static propTypesMeta = {
    isOpen: 'exclude'
  };

  static defaultProps = {
    closeGlobalSearch: () => {}
  };

  state = {
    width: 'auto'
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      clearTimeout(this.focusTimeout);
      this.focusTimeout = setTimeout(() => {
        this.input.focus();
      }, 300);
    }
  }

  setWidth = debounce(() => {
    if (!this || !this.container) {
      return;
    }
    this.setState({ width: this.container.offsetWidth || 'auto' });
  }, 500);

  componentDidMount() {
    this.setWidth();
    window.addEventListener('resize', this.setWidth);
  }

  componentWillUnmount() {
    clearTimeout(this.focusTimeout);
    window.removeEventListener('resize', this.setWidth);
  }

  render() {
    return (
      <div
        className="global-search"
        ref={div => (this.container = div)}
        style={{ width: this.props.isOpen ? 430 : this.state.width }}
      >
        <Form
          className={cn('global-search__form', {
            '-is-visible': this.props.isOpen
          })}
          showSubmitButton={false}
          {...this.props.form}
        >
          <div>
            <TextInput
              className="global-search__input"
              ref={input => (this.input = input)}
              type="search"
              theme={[TextInput.themes.rounded, TextInput.themes.hiddenLabel]}
              idPrefix="global"
              {...this.props.input}
            />
            <Button
              className="global-search--close"
              icon="x"
              onClick={this.props.closeGlobalSearch}
              title={this.props.closeButtonText}
            />
            <button className="global-search__submit" type="submit">
              {this.props.submitLabel}
            </button>
          </div>
        </Form>
        <form action={this.props.endpoint}>
          <Button
            className="global-search--open"
            icon="search"
            iconFill
            onClick={this.props.openGlobalSearch}
            theme={[Button.themes.outline, Button.themes.uppercase]}
            {...this.props.button}
            type="submit"
          />
        </form>
      </div>
    );
  }
}

export default GlobalSearch;
