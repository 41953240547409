import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

import Heading from 'components/heading';
import HtmlString from 'components/html-string';
import LinkWrapper from 'components/link-wrapper';
import Metadata from 'components/metadata';
import ListItemImage from 'components/list-item-image';

import FluidImage from 'components/fluid-image';
import DateCardStatus from 'components/date-card/date-card-status';
import DateCardTags from 'components/date-card/date-card-tags';
import DocumentIcon from 'components/document-icon';

const themes = {
  darkText: '-theme-dark-text',
  noBorder: '-theme-no-border',
  sidebar: '-theme-sidebar'
};

const SearchResult = ({
  headingLevel,
  text,
  title,
  theme,
  metadata,
  url,
  trackUrl,
  image,
  themeTags,
  statusList,
  icon,
  isLink,
  descriptiveTitle
}) => {
  const { OuterLink, InnerLink } = LinkWrapper(isLink, url, trackUrl);
  return (
    <OuterLink className={cn('search-result', theme)}>
      {descriptiveTitle && (
        <HtmlString
          className="search-result__descriptive-title"
          text={descriptiveTitle}
        />
      )}
      <InnerLink className="search-result--link">
        <Heading
          level={headingLevel}
          className={cn(
            'search-result__title',
            { 'search-result__title--has-descriptive-title': descriptiveTitle },
            theme
          )}
        >
          <HtmlString text={title} />
        </Heading>
      </InnerLink>
      {statusList && (
        <div className="search-result__status">
          {statusList.map(status => (
            <DateCardStatus key={status.text} {...status}></DateCardStatus>
          ))}
        </div>
      )}
      {metadata && (
        <div className="search-result__metadata">
          <Metadata
            theme={theme === themes.sidebar ? Metadata.themes.vertical : null}
            {...metadata}
          />
        </div>
      )}
      <div className="search-result__content">
        {image && (
          <div className="search-result__image-container">
            <ListItemImage image={image} />
          </div>
        )}
        {icon && !image && (
          <div className="search-result__icon">
            <DocumentIcon
              iconTheme={icon.iconTheme}
              size={DocumentIcon.sizes.xsmall}
              isBlack
            />
          </div>
        )}
        {text && <HtmlString text={text} />}
      </div>
      {themeTags && <DateCardTags {...themeTags} />}
    </OuterLink>
  );
};

SearchResult.propTypes = {
  headingLevel: PropTypes.number,
  text: PropTypes.string,
  theme: propTypeTheme(themes),
  title: PropTypes.string,
  metadata: PropTypes.exact(Metadata.propTypes),
  url: PropTypes.string,
  trackUrl: PropTypes.string,
  image: PropTypes.exact(FluidImage.propTypes),
  icon: PropTypes.exact(DocumentIcon.propTypes),
  statusList: PropTypes.arrayOf(PropTypes.exact(DateCardStatus.propTypes)),
  themeTags: PropTypes.exact(DateCardTags.propTypes),
  isLink: PropTypes.bool,
  descriptiveTitle: PropTypes.string
};

SearchResult.propTypesMeta = {
  headingLevel: 'exclude',
  theme: 'exclude',
  isLink: 'exclude'
};

SearchResult.defaultProps = {
  headingLevel: 2
};

SearchResult.themes = themes;

export default SearchResult;
