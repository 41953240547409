import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useLocalStorageState from 'js/hooks/use-local-storage-state';
import {
  serializeWithTimestamp,
  deserializeWithTimestamp
} from 'js/utils/local-storage';

import MessageGlobal from 'components/message-global';

const MessageCarousel = ({ messageGlobals, initiallyOpen }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isOpen, setIsOpen] = useLocalStorageState(
    messageGlobals.length && messageGlobals[0].id,
    initiallyOpen,
    {
      serialize: serializeWithTimestamp,
      deserialize: deserializeWithTimestamp
    }
  );

  const updateIsOpen = isOpen => {
    setIsOpen(isOpen);

    if (!isOpen) {
      setTimeout(() => setActiveIndex(0), 300);
    }
  };

  return (
    <div
      className="message-carousel"
      style={{ width: `${messageGlobals.length * 100}%` }}
    >
      {messageGlobals.map((messageGlobal, index) => (
        <MessageGlobal
          key={messageGlobal.id}
          {...messageGlobal}
          index={index}
          length={messageGlobals.length}
          setActiveIndex={setActiveIndex}
          activeIndex={activeIndex}
          setIsOpen={updateIsOpen}
          isOpen={isOpen}
        />
      ))}
    </div>
  );
};

MessageCarousel.propTypes = {
  messageGlobals: PropTypes.arrayOf(PropTypes.exact(MessageGlobal.propTypes)),
  initiallyOpen: PropTypes.bool
};

export default MessageCarousel;
