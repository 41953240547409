import React from 'react';
import PropTypes from 'prop-types';


const MetadataList = ({ title, items }) => {
    return (
        <div className="article-metadata">
            <h2 className="article-metadata--title">{title}</h2>
            <dl className="article-metadata--list">
                {items.map(metadata => (
                    <>
                        <dt>{metadata.label}:</dt>
                        {metadata.text.map(paragraph => (
                            <dd>{paragraph}</dd>
                        ))}
                    </>
                ))}
            </dl>
        </div>
    );
}

MetadataList.propTypes = {
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
        PropTypes.exact({
            label: PropTypes.string.isRequired,
            text: PropTypes.arrayOf(PropTypes.string.isRequired)
        }).isRequired
    )
};

export default MetadataList