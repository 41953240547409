import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components-old/link';

const ChapterLink = ({ chapterNumber, hasDash, isCurrent, link }) => (
  <Link
    className="chapter-link"
    theme={isCurrent && [Link.themes.black, Link.themes.bold]}
    {...link}
  >
    {chapterNumber && (
      <span className="chapter-link--number">{chapterNumber}</span>
    )}

    {hasDash && <span className="chapter-link--dash" />}
  </Link>
);

ChapterLink.propTypes = {
  chapterNumber: PropTypes.string,
  hasDash: PropTypes.bool,
  isCurrent: PropTypes.bool,
  link: PropTypes.exact(Link.propTypes)
};

export default ChapterLink;
