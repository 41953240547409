import React from 'react';
import PropTypes from 'prop-types';

const EmptyList = ({ text }) => (
  <div className="empty-list">
    <p>{text}</p>
  </div>
);

EmptyList.propTypes = {
  text: PropTypes.string
};
export default EmptyList;
