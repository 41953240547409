import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';

const ChapterLink = ({ chapterNumber, hasDash, link, isHeader, isTopLevelLink }) => {

  const [linksToCurrentPage, setLinksToCurrentPage] = useState();

  useEffect(() => {
    const currPath = window.location.pathname
    const currLink = link.url
    if (currPath == currLink) {
      setLinksToCurrentPage(true)
    }
  }, [])

  return (
    <Link
      className="chapter-link"
      theme={
        [isHeader || isTopLevelLink && [Link.themes.bold],
        linksToCurrentPage && [Link.themes.underline],
        isTopLevelLink && chapterNumber && [Link.themes.extraIndentation]]
      }
      {...link}
    >
      {chapterNumber && (
        <span className="chapter-link--number">{chapterNumber}</span>
      )}

      {hasDash && <span className="chapter-link--dash" />}
    </Link >
  );
}

ChapterLink.propTypes = {
  chapterNumber: PropTypes.string,
  hasDash: PropTypes.bool,
  link: PropTypes.exact(Link.propTypes),
  isHeader: PropTypes.bool,
  isTopLevelLink: PropTypes.bool
};

ChapterLink.propTypesMeta = {
  isHeader: 'exclude',
  isTopLevelLink: 'exclude'
}

export default ChapterLink;
