import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components-old/icon';
import Heading from 'components-old/heading';
import AutoGrid from 'components-old/auto-grid';
import Link from 'components-old/link';
import LinkWrapper from 'components-old/link-wrapper/link-wrapper';
import useIsScreenSize from 'js/hooks/use-is-screen-size';
import PortfolioTile from './portfolio-tile';

const PortfolioTiles = ({ tiles }) => {
  const isTabletOrDesktop = useIsScreenSize('md');
  const minimumTileSize = isTabletOrDesktop ? 241 : 155;
  return (
    <div className="portfolio-tiles">
      <AutoGrid minWidth={minimumTileSize} flexibleGutter>
        {AutoGridItem =>
          tiles.map(({ theme, link }) => {
            const { OuterLink, InnerLink } = LinkWrapper(true, link.url);
            return (
              <AutoGridItem key={link.text}>
                <OuterLink className="portfolio-tiles--link">
                  <div className="portfolio-tiles--tile-square">
                    <PortfolioTile
                      className="portfolio-tiles--tile"
                      theme={theme}
                    >
                      <InnerLink className="portfolio-tiles--title">
                        <Heading>{link.text}</Heading>
                      </InnerLink>
                    </PortfolioTile>
                  </div>
                </OuterLink>
              </AutoGridItem>
            );
          })
        }
      </AutoGrid>
    </div>
  );
};

PortfolioTiles.propTypes = {
  tiles: PropTypes.arrayOf(
    PropTypes.exact({
      link: PropTypes.exact(Link.propTypes),
      theme: PortfolioTile.propTypes.theme
    })
  )
};

export default PortfolioTiles;
