import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ isActive }) => {
  if (!isActive) return null;
  return (
    <div className="spinner-background">
      <div className="spinner">
        <div className="spinner--wrapper active">
          <div className="spinner-border-background"></div>
          <div className="spinner-layer spinner-red-only">
            <div className="circle-clipper left">
              <div className="circle"></div>
            </div>
            <div className="gap-patch">
              <div className="circle"></div>
            </div>
            <div className="circle-clipper right">
              <div className="circle"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Spinner.propTypes = {
  isActive: PropTypes.bool
};

Spinner.propTypesMeta = 'exclude';

export default Spinner;
