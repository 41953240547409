import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';

const supportedProviders = ['facebook', 'linkedin', 'twitter', 'mail'];

const themes = {
  isProposalOrEvent: '-is-proposal-or-event',
  isPageFooter: '-is-page-footer'
};

const ShareOptions = ({ items }) => {
  return (
    <div className="share-options">
      <div className="share-options__options">
        {items.map(link => (
          <div key={link.text} className="share-options__link-container">
            <div className="share-options__link">
              <a href={link.url} target="_blank" rel="noopener noreferrer">
                <Icon
                  name={
                    link.icon === 'facebook'
                      ? 'facebook-logo'
                      : link.icon === 'linkedin'
                      ? 'linkedin-logo'
                      : link.icon === 'twitter'
                      ? 'twitter-logo'
                      : link.icon === 'mail'
                      ? 'envelope'
                      : null
                  }
                ></Icon>
                <span>{link.text}</span>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

ShareOptions.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
      icon: PropTypes.oneOf(supportedProviders)
    })
  )
};

ShareOptions.defaultProps = {
  items: []
};

ShareOptions.propTypesMeta = {
  theme: 'exclude'
};

ShareOptions.themes = themes;

export default ShareOptions;
