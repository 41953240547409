import React from 'react';
import PropTypes from 'prop-types';

import ContentWithSidebar from 'components-old/content-with-sidebar';
import DateCard from 'components-old/date-card';
import EmptyList from 'components-old/empty-list';
import FilterLayout from 'components-old/filter-layout';
import TopFilter from 'components-old/top-filter';
import Form from 'components-old/form';
import Link from 'components-old/link';
import TabList from 'components-old/tabs/tab-list';
import Spinner from 'components-old/spinner';
import useFetchFilteredResults from 'js/hooks/use-fetch-filtered-results';

const EventListPage = ({
  emptyList,
  events,
  filterLayout,
  form,
  title,
  topFilter,
  fetchFilteredResultsEndpoint
}) => {
  const [
    isLoading,
    {
      emptyList: emptyListState,
      filterLayout: filterLayoutState,
      events: eventsState
    },
    fetchResults
  ] = useFetchFilteredResults(
    { emptyList, filterLayout, events },
    fetchFilteredResultsEndpoint,
    form.endpoint,
    topFilter.timeframe
  );

  return (
    <Form className="event-list-page" showSubmitButton={false} {...form}>
      <ContentWithSidebar>{title && <h1>{title}</h1>}</ContentWithSidebar>
      <FilterLayout
        {...filterLayoutState}
        fetchFilteredResults={fetchResults}
        includeBottomBorder={false}
        topContent={
          topFilter &&
          topFilter.options && (
            <TabList className="event-list-page--tab-list">
              {topFilter.options.map(({ isCurrent, link }, index) => (
                <TabList.Item
                  key={link.url}
                  isCurrent={isCurrent}
                  length={topFilter.options.length}
                  index={index}
                  currentIndex={topFilter.options.findIndex(
                    ({ isCurrent }) => isCurrent
                  )}
                >
                  <Link theme={isCurrent ? Link.themes.black : []} {...link} />
                </TabList.Item>
              ))}
            </TabList>
          )
        }
        isLoading={isLoading}
      >
        <div className="event-list-page--events">
          {eventsState && eventsState.length > 0 ? (
            eventsState.map(event => (
              <DateCard key={event.id} {...event} isEventList />
            ))
          ) : (
            <EmptyList {...emptyListState} />
          )}
          <Spinner isActive={isLoading} />
        </div>
      </FilterLayout>
    </Form>
  );
};

EventListPage.propTypes = {
  emptyList: PropTypes.exact(EmptyList.propTypes),
  events: PropTypes.arrayOf(PropTypes.exact(DateCard.propTypes)),
  filterLayout: PropTypes.exact(FilterLayout.propTypes),
  form: PropTypes.exact(Form.propTypes),
  title: PropTypes.string,
  topFilter: PropTypes.exact(TopFilter.propTypes),
  fetchFilteredResultsEndpoint: PropTypes.string
};

EventListPage.defaultProps = {
  events: [],
  labels: {},
  topFilter: {}
};

export default EventListPage;
