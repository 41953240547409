import React from 'react';
import PropTypes from 'prop-types';

import LinkList from 'components/link-list';
import RichText from 'components/rich-text';
import Icon from 'components/icon';
import Button from 'components/button';

import Heading from 'components/heading';
import Image from 'components/image';
import Link from 'components/link';

const InfoBlock = ({
  includeIcon,
  linkList,
  onPageEditing,
  title,
  text,
  url,
  cta,
  icon
}) => {
  return (
    <div className="info-block">
      {title && (
        <div className="info-block--header">
          <Heading
            className="info-block--header-title"
            onPageEditing={onPageEditing.title}
          >
            {url ? (
              <Link
                {...{
                  url,
                  text: title,
                  theme: [Link.themes.actionLink, Link.themes.large, Link.themes.wrappedHeadingForPrint]
                }}
              />
            ) : (
              title
            )}
          </Heading>
          {icon && (
            <Image
              className="info-block--icon-image"
              responsive={false}
              {...icon}
            />
          )}
          {includeIcon && <Icon className="info-block--icon" name="i" />}
        </div>
      )}
      {text && <RichText className="info-block--text" {...text} />}
      {linkList && (
        <LinkList
          className="info-block--link-list"
          {...linkList}
          linkTheme={Link.themes.actionLink}
        />
      )}
      {cta && (
        <Link
          className="info-block--cta-button"
          theme={Button.themes.primary}
          useButtonStyles={true}
          {...cta}
        />
      )}
    </div>
  );
};

InfoBlock.propTypes = {
  includeIcon: PropTypes.bool,
  linkList: PropTypes.exact(LinkList.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),
  title: PropTypes.string,
  text: PropTypes.exact(RichText.propTypes),
  url: PropTypes.string,
  cta: PropTypes.exact(Link.propTypes),
  icon: PropTypes.exact(Image.propTypes)
};

InfoBlock.defaultProps = {
  onPageEditing: {}
};

export default InfoBlock;
