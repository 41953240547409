import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';

const SectionTitle = ({ sectionLink }) => {
  const linkTheme = '-theme-action-link';

  return <Link theme={linkTheme} {...sectionLink} />;
};

export default SectionTitle;

SectionTitle.propTypes = {
  sectionLink: PropTypes.exact(Link.propTypes)
};
