import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Heading from 'components-old/heading';
import ContentArea from 'components-old/content-area';
import Link from 'components-old/link';
import Icon from 'components-old/icon/icon';

const columns = (blocks, singleColumn) => {
  if (singleColumn) return ContentArea.themes.singleColumn;
  if (blocks.length >= 3) return ContentArea.themes.threeColumns;
  if (blocks.length === 2) return ContentArea.themes.twoColumns;
};

const props = (blocks, singleColumn) => {
  switch (blocks[0].componentName) {
    case 'LinkLists':
      return [
        {
          additionalComponentProps: {
            LinkLists: {
              singleColumn
            }
          },
          decreaseTopMargin: true
        },
        { name: 'link' }
      ];
    case 'LinkWithText':
      return [
        {
          additionalComponentProps: {
            LinkWithText: {
              linkTheme: [
                Link.themes.orangeIcon,
                Link.themes.inlineIcon,
                Link.themes.portfolio
              ]
            }
          },
          theme: [columns(blocks, singleColumn), ContentArea.themes.portfolio],
          decreaseTopMargin: true
        },
        { name: 'book' }
      ];
    case 'ContactList':
      return [{}, { name: 'chat' }];
    default:
      return [{}, {}];
  }
};

const PortfolioContent = ({
  isInsidePriorityBlock,
  title,
  text,
  content = {},
  singleColumn,
  children
}) => {
  const [extraProps, icon] =
    content.blocks && content.blocks.length
      ? props(content.blocks, singleColumn)
      : [{}, {}];

  return (
    <div
      className={cn('portfolio-content', {
        '-inside-priority': isInsidePriorityBlock
      })}
    >
      {isInsidePriorityBlock && (
        <Icon className="portfolio-content--icon" {...icon} />
      )}
      <div className="portfolio-content--content">
        <div className="portfolio-content--heading">
          {title && (
            <Heading className="portfolio-content--title">{title}</Heading>
          )}
          {text && <p className="portfolio-content--text">{text}</p>}
        </div>
        {content && content.blocks && (
          <ContentArea
            className={cn('portfolio-content--blocks', {
              '-top-content-is-text': extraProps.decreaseTopMargin
            })}
            enableElementSizing={true}
            {...extraProps}
            {...content}
          />
        )}
        {children && children}
      </div>
    </div>
  );
};

PortfolioContent.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  text: PropTypes.string,
  content: PropTypes.exact(ContentArea.propTypes),
  isInsidePriorityBlock: PropTypes.bool,
  singleColumn: PropTypes.bool
};

PortfolioContent.propTypesMeta = {
  children: 'exclude'
};
export default PortfolioContent;
