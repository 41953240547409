/*
Component for creating flexible grid that where amount of grid columns
adjust to fit as many columns without column width going below minWidth.
(exactly like 'display grid' with 'auto-fill').
*/

import React, { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import resize from 'js/hooks/resize';
import AutoGridItem from './auto-grid-item';

const calcFlexibleGutterStyle = (minWidth, gridWidth) => {
  if (!gridWidth) return {};
  const gaps = Math.floor(gridWidth / (minWidth + 1)) - 1;
  let gapLength =
    gaps === 0 ? 24 : Math.floor((gridWidth % (minWidth + 1)) / gaps);
  const itemWidth = gaps === 0 ? gridWidth : minWidth;

  const extraPxs = gridWidth - (gaps * gapLength + itemWidth * (gaps + 1));
  if (extraPxs > gaps) gapLength++;
  const gridStyle = {
    gridTemplateColumns: `repeat(auto-fill, ${itemWidth}px)`,
    gap: gapLength
  };
  return { gridStyle, itemStyle: {} };
};

const AutoGridLogic = (minWidth, gutter) => {
  const [gridWidth, setGridWidth] = useState(0);

  const { gridStyle, itemStyle } = calcFlexibleGutterStyle(minWidth, gridWidth);

  const setWidth = node => {
    if (!node) return;
    if (node.offsetWidth === gridWidth) return;
    setGridWidth(node.offsetWidth);
  };

  resize(setWidth);

  return {
    autoGridProps: {
      style: gridStyle,
      ref: setWidth
    },
    itemStyle
  };
};

const AutoGrid = ({
  children = () => { },
  isList,
  minWidth = 250,
  gutter = 24,
  flexibleGutter,
  className
}) => {

  if (isList) {
    minWidth = null;
    gutter = null;
  }

  let autoGridProps, itemStyle;

  if (flexibleGutter) {
    autoGridProps = AutoGridLogic(minWidth, gutter).autoGridProps;
  } else {
    autoGridProps = {
      style: {
        gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth}px, 1fr))`
      }
    };
  }
  const ItemComponent = AutoGridItem(itemStyle);

  return (
    <ul
      className={cn('auto-grid', { '-theme-grid': minWidth }, className)}
      {...autoGridProps}
    >
      {children(ItemComponent)}
    </ul>
  );
};

AutoGrid.propTypes = {
  children: PropTypes.func,
  minWidth: PropTypes.number,
  gutter: PropTypes.number,
  isList: PropTypes.bool,
  flexibleGutter: PropTypes.bool,
  className: PropTypes.string
};

AutoGrid.propTypesMeta = 'exclude';

export default AutoGrid;
