import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components-old/button';
import Form from 'components-old/form';
import NewsletterAdministrationLayout from 'components-old/newsletter-administration-layout';
import TextInput from 'components-old/form-elements/text-input';

const NewsletterUnsubscribePage = ({
  form,
  input,
  layout,
  submitButton,
  title
}) => (
  <NewsletterAdministrationLayout
    {...layout}
    className="newsletter-unsubscribe-page"
  >
    <Form {...form} showSubmitButton={false}>
      {title && <h1>{title}</h1>}
      <TextInput
        className="newsletter-unsubscribe-page--text-input"
        theme={TextInput.themes.hiddenLabel}
        type="text"
        {...input}
      />
      <Button
        theme={[Button.themes.big, Button.themes.fill]}
        type="submit"
        {...submitButton}
      />
    </Form>
  </NewsletterAdministrationLayout>
);

NewsletterUnsubscribePage.propTypes = {
  form: PropTypes.exact(Form.propTypes),
  input: PropTypes.exact(TextInput.propTypes),
  layout: PropTypes.exact(NewsletterAdministrationLayout.propTypes),
  submitButton: PropTypes.exact(Button.propTypes),
  title: PropTypes.string
};

export default NewsletterUnsubscribePage;
