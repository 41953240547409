import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

import OPE from 'js/on-page-editing';

const themes = {
  border: '-theme-border'
};

const Byline = ({ items, theme }) => (
  <div className={cn('byline', theme)}>
    {items.map(item =>
      item.text || item.onPageEditing ? (
        <div
          className="byline--item"
          key={item.text}
          {...OPE(item.onPageEditing)}
        >
          {item.text}
        </div>
      ) : null
    )}
  </div>
);

Byline.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      onPageEditing: PropTypes.string,
      text: PropTypes.string
    })
  ),
  theme: propTypeTheme(themes)
};

Byline.defaultProps = {
  items: [],
  theme: []
};

Byline.propTypesMeta = {
  theme: 'exclude'
};

Byline.themes = themes;

export default Byline;
