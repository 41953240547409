import React from 'react';
import PropTypes from 'prop-types';

import ContentArea from 'components-old/content-area';
import ContentWithSidebar from 'components-old/content-with-sidebar';
import PageFooter from 'components-old/page-footer';
import ResponsiveIframe from 'components-old/responsive-iframe';
import RichText from 'components-old/rich-text';

const ResponsiveIframePage = ({ footer, iframe, richText, sidebar, title }) => (
  <div className="responsive-iframe-page">
    <ContentWithSidebar>
      <ContentWithSidebar.Content>
        {title && <h1>{title}</h1>}
      </ContentWithSidebar.Content>
      <ContentWithSidebar.Sidebar />
    </ContentWithSidebar>
    <ContentWithSidebar>
      <ContentWithSidebar.Content>
        <RichText {...richText} />
        <ResponsiveIframe {...iframe} />
        <div className="responsive-iframe-page--footer">
          <PageFooter {...footer} />
        </div>
      </ContentWithSidebar.Content>
      <ContentWithSidebar.Sidebar>
        <ContentArea enableElementSizing={false} {...sidebar} />
      </ContentWithSidebar.Sidebar>
    </ContentWithSidebar>
  </div>
);

ResponsiveIframePage.propTypes = {
  footer: PropTypes.exact(PageFooter.propTypes),
  iframe: PropTypes.exact(ResponsiveIframe.propTypes),
  richText: PropTypes.exact(RichText.propTypes),
  sidebar: PropTypes.exact(ContentArea.propTypes),
  title: PropTypes.string
};

export default ResponsiveIframePage;
