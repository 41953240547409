import React from 'react';
import PropTypes from 'prop-types';

import Accordion from 'components-old/accordion';
import Button from 'components-old/button';
import DateCard from 'components-old/date-card';
import Metadata from 'components-old/metadata';
import RichText from 'components-old/rich-text';
import Icon from 'components-old/icon';

import cn from 'classnames';

const ProposalGroup = ({
  accordion,
  numberOfVisibleItems,
  proposals,
  subtitle,
  text,
  title,
  headerAccordion
}) => {
  const visibleItems = proposals.slice(0, numberOfVisibleItems);
  const hiddenItems = proposals.slice(numberOfVisibleItems);

  return (
    <div className="proposal-group">
      <Accordion {...headerAccordion} buttonTextForLabel={title}>
        {({ Collapse, Button, isOpen, toggle }) => (
          <div className="proposal-group--header" onClick={toggle}>
            <Button className="proposal-group--header--button">
              {title && <h2>{title}</h2>}
              <Icon
                className={cn('proposal-group--header--icon', {
                  '-is-open': isOpen
                })}
                name="small-arrow-down"
              />
            </Button>
            <Collapse>
              <div className="proposal-group--content">
                <p>{subtitle}</p>
                <RichText className="proposal-group--text" {...text} />
              </div>
            </Collapse>
          </div>
        )}
      </Accordion>

      {visibleItems.map(proposal => (
        <DateCard
          key={proposal.id}
          headingLevel={3}
          metadataTheme={Metadata.themes.vertical}
          {...proposal}
        />
      ))}

      <Accordion showButtonText={true} {...accordion}>
        {({ Button: AccordionButton, Collapse }) => (
          <React.Fragment>
            <Collapse>
              {hiddenItems.map(proposal => (
                <DateCard
                  key={proposal.id}
                  headingLevel={3}
                  metadataTheme={Metadata.themes.vertical}
                  {...proposal}
                />
              ))}
            </Collapse>

            <AccordionButton
              className="proposal-group--toggle"
              theme={Button.themes.orangeOutline}
            />
          </React.Fragment>
        )}
      </Accordion>
    </div>
  );
};

ProposalGroup.propTypes = {
  accordion: PropTypes.exact(Accordion.propTypes),
  headerAccordion: PropTypes.exact(Accordion.propTypes),
  numberOfVisibleItems: PropTypes.number,
  proposals: PropTypes.arrayOf(PropTypes.exact(DateCard.propTypes)),
  subtitle: PropTypes.string,
  text: PropTypes.exact(RichText.propTypes),
  title: PropTypes.string
};

ProposalGroup.defaultProps = {
  numberOfVisibleItems: 3,
  proposals: []
};

export default ProposalGroup;
