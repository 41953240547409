import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';

import Button from 'components-old/button';
import FluidImage from 'components-old/fluid-image';
import Link from 'components-old/link';
import Icon from 'components-old/icon/icon';

const themes = {
  blue: '-theme-blue',
  yellow: '-theme-yellow'
};

const EducationBlock = ({
  link,
  editorTheme,
  image,
  onPageEditing,
  title,
  grade,
  time,
  imageLeft,
  ingress
}) => (
  <div
    className={cn('education-block', editorTheme, {
      '-reverse-order': imageLeft
    })}
  >
    <div className="education-block--text">
      {(grade || time) && (
        <span className="education-block--info">
          {grade && <span {...OPE(onPageEditing.grade)}>{grade}</span>}
          {grade && time && '|'}
          {time && (
            <span
              className="education-block--time"
              {...OPE(onPageEditing.time)}
            >
              <Icon name="clock" fill className="education-block--clock" />
              {time}
            </span>
          )}
        </span>
      )}

      {title && (
        <h2 className="education-block--title" {...OPE(onPageEditing.title)}>
          {title}
        </h2>
      )}
      {ingress && (
        <p className="education-block--ingress" {...OPE(onPageEditing.ingress)}>
          {ingress}
        </p>
      )}
      {link && (
        <Link
          className="education-block--link"
          theme={Button.themes.fill}
          useButtonStyles={true}
          {...link}
        />
      )}
    </div>
    {image && (
      <div className="education-block--image">
        <FluidImage {...image} />
      </div>
    )}
  </div>
);

EducationBlock.propTypes = {
  grade: PropTypes.string,
  time: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  editorTheme: PropTypes.oneOf(Object.values(themes)),
  image: PropTypes.exact(FluidImage.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string,
    ingress: PropTypes.string,
    time: PropTypes.string,
    grade: PropTypes.string
  }),
  imageLeft: PropTypes.bool,
  title: PropTypes.string.isRequired,
  ingress: PropTypes.string
};

EducationBlock.defaultProps = {
  onPageEditing: {}
};

export default EducationBlock;
