import React from 'react';

import ContentArea from 'components-old/content-area';

const RichText = props => (
  <ContentArea enableElementSizing={false} {...props} />
);

RichText.propTypes = ContentArea.propTypes;

export default RichText;
