import { useState, useCallback } from 'react';

import useIsScreenSize from 'js/hooks/use-is-screen-size';
import useOnFocusInsideChange from 'js/hooks/use-on-focus-inside-change';

const useTooltip = toggleFocusListener => {
  const [showTooltip, setShowTooltip] = useState();
  const [showTabTooltip, setShowTabTooltip] = useState();

  const isMediumScreen = useIsScreenSize('md');

  const ref = useOnFocusInsideChange(toggleTabTooltip, toggleFocusListener);
  const openTooltip = () => {
    setShowTooltip(true);
    setShowTabTooltip(false);
  };

  const closeTooltip = useCallback(() => {
    setShowTabTooltip(false);
  }, []);

  function toggleTabTooltip(open) {
    if (open) {
      document.addEventListener('mousemove', closeTooltip);
      setShowTooltip(false);
      setShowTabTooltip(true);
    } else {
      setShowTabTooltip(false);
    }
  }

  return {
    ref,
    handleMouseEnter: openTooltip,
    handleMouseLeave: () => setShowTooltip(false),
    toggleTabTooltip,
    showTabTooltip: isMediumScreen && showTabTooltip,
    showTooltip: isMediumScreen && showTooltip
  };
};

export default useTooltip;
