import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Link from 'components-old/link';

const GroupedSearchPageLink = ({ isCurrent, link }) => (
  <Link
    className={cn('grouped-search-page-link', {
      '-is-current': isCurrent,
      '-has-no-link': !isCurrent && !link.url
    })}
    {...link}
  />
);

GroupedSearchPageLink.propTypes = {
  isCurrent: PropTypes.bool,
  link: PropTypes.exact(Link.propTypes)
};

export default GroupedSearchPageLink;
