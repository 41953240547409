import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import EventData from 'components-old/event-page/event-data';

const Tooltip = ({ eventData, useTabStyle }) => {
  const [mousePos, setMousePos] = useState();

  const onMove = useCallback(e => {
    setMousePos({ x: e.clientX, y: e.clientY });
  }, []);

  useEffect(() => {
    if (useTabStyle) return;
    window.addEventListener('mousemove', onMove);
    return () => window.removeEventListener('mousemove', onMove);
  }, []);

  return mousePos || useTabStyle ? (
    <div
      className={cn('tooltip', { '-tab-triggered': useTabStyle })}
      style={
        mousePos && {
          transform: `translate(${mousePos.x + 5}px, ${mousePos.y}px)`
        }
      }
    >
      <EventData {...eventData} enableTabbing />
    </div>
  ) : null;
};

Tooltip.propTypes = {
  eventData: PropTypes.exact(EventData.propTypes)
};

export default Tooltip;
