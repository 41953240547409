import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Image from 'components/image';
import Heading from 'components/heading';
import LinkWrapper from 'components/link-wrapper';

const ProcessItem = ({ icon, text, index, url, title }) => {
  const { OuterLink } = LinkWrapper(!!url, url);

  return (
    <div className={cn('process-item')}>
      <OuterLink
        className={cn('process-item--link', {
          'process-item--link--has-link': url
        })}
        noHoverEffect={true}
      >
        <div className="process-item--wrapper">
          <div className="process-item--header">
            {icon ? (
              <div className="process-item--icon-container">
                <Image className="process-item--icon" {...icon} />
              </div>
            ) : (
              <Heading className="process-item--number" level={2}>
                {index + 1}
              </Heading>
            )}
          </div>

          {title && (
            <p className="process-item--title">
              <span>{title}</span>
            </p>
          )}
          {text && (
            <p
              className={cn('process-item--text', {
                'process-item--text-hover': !title
              })}
            >
              <span>{text}</span>
            </p>
          )}
        </div>
      </OuterLink>
    </div>
  );
};

ProcessItem.propTypes = {
  icon: PropTypes.exact(Image.propTypes),
  text: PropTypes.string,
  index: PropTypes.number,
  url: PropTypes.string,
  title: PropTypes.string
};

ProcessItem.propTypesMeta = {
  index: 'exclude'
};

export default ProcessItem;
