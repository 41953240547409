import React from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';

import isRunningOnClient from '@creuna/utils/is-running-on-client';
const { Loader } = isRunningOnClient ? require('google-maps') : {};

import Button from 'components-old/button';
import MapPopup from './map-popup';

import mapOptions from './map-options';
import markerIcon from '../../assets-old/images/map-marker.png';

class Map extends React.Component {
  static propTypes = {
    googleMapsAPIKey: PropTypes.string.isRequired,
    labels: PropTypes.exact({
      zoomIn: PropTypes.string,
      zoomOut: PropTypes.string
    }),
    markers: PropTypes.arrayOf(
      PropTypes.exact({
        name: PropTypes.string.isRequired,
        latitude: PropTypes.number.isRequired,
        longitude: PropTypes.number.isRequired,
        popup: PropTypes.exact(MapPopup.propTypes)
      })
    )
  };

  static defaultProps = {
    labels: {},
    markers: []
  };

  static propTypesMeta = {
    markers: [{ latitude: 'float', longitude: 'float' }]
  };

  state = {};

  google = null;
  map = null;

  getBounds = markers => {
    const google = this.google;
    const bounds = new google.maps.LatLngBounds(); //loop through all markers and create bounds

    if (markers && markers.length) {
      markers.forEach(marker => {
        bounds.extend(
          new google.maps.LatLng(marker.latitude, marker.longitude)
        );
      });
    }

    return bounds;
  };

  fitMapToMarkers = markers => {
    const bounds = this.getBounds(markers);

    if (markers.length === 1) {
      this.map.setCenter(bounds.getCenter());
      this.map.setZoom(16);
    } else {
      // fit to bounds
      this.map.fitBounds(bounds);
    }
  };

  zoomIn = () => {
    this.map.setZoom(this.map.getZoom() + 1);
  };

  zoomOut = () => {
    this.map.setZoom(this.map.getZoom() - 1);
  };

  componentDidMount() {
    const loader = new Loader(this.props.googleMapsAPIKey);
    loader.load().then(google => {
      this.google = google;
      this.map = new google.maps.Map(this.wrapper, mapOptions);

      this.fitMapToMarkers(this.props.markers);
      const popupsAndMarkers = this.props.markers.map(marker => {
        return {
          popup: new google.maps.InfoWindow({
            content: ReactDOMServer.renderToString(
              <MapPopup {...marker.popup} />
            )
          }),
          marker: new google.maps.Marker({
            position: {
              lat: marker.latitude,
              lng: marker.longitude
            },
            icon: markerIcon,
            title: marker.name,
            map: this.map
          })
        };
      });
      popupsAndMarkers.forEach(({ popup, marker }) => {
        marker.addListener('click', () => {
          // Close all popups:
          popupsAndMarkers.forEach(({ popup }) => popup.close());
          // Open popup for the clicked marker:
          popup.open(this.map, marker);
        });
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.markers !== this.props.markers) {
      this.fitMapTomarkers(this.props.markers);
    }
  }

  render() {
    return (
      <div className="map">
        <div className="map--container" ref={div => (this.wrapper = div)} />

        <div className="map--controls">
          <Button
            className="map--button"
            icon="plus-small"
            onClick={this.zoomIn}
            title={this.props.labels.zoomIn}
          />
          <Button
            className="map--button"
            icon="minus-small"
            onClick={this.zoomOut}
            title={this.props.labels.zoomOut}
          />
        </div>
      </div>
    );
  }
}

export default Map;
