import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ChapterLink from 'components/chapter-link';
import ChapterNavigationList from './chapter-navigation-list';
import Accordion from 'components/accordion';
import cn from 'classnames'
import useIsScreenSize from 'js/hooks/use-is-screen-size';

const TableOfContents = ({ items, title, accordion }) => {
  const isDesktop = useIsScreenSize('lg');

  return (
    <Accordion expandOnMount={windowWidth => windowWidth >= 1024} {...accordion}>
      {({ Button, Collapse, isOpen, toggle }) => {

        if (!isOpen && isDesktop) {
          toggle()
        }

        return (
          <div className="table-of-contents">
            <div className="table-of-contents__content">
              <div className="table-of-contents__header">
                {
                  isDesktop && title && <h2 className="table-of-contents__title">{title}</h2>
                }
                {
                  !isDesktop &&
                  <Button
                    icon="micro-arrow-down"
                    className={
                      cn('table-of-contents__button', {
                        '-is-active': isOpen
                      })}>
                    {title && <h2 className="table-of-contents__title">{title}</h2>}
                  </Button>
                }
              </div>
              <Collapse>
                <ul className="table-of-contents__list">
                  {items.map(({ link, linkOrLinkList }, index) => {
                    if (link) {
                      return (
                        <li
                          className="table-of-contents__list-item"
                          key={link.chapterNumber + String(index)}
                        >
                          <ChapterLink {...link} isTopLevelLink={true} />
                        </li>
                      );
                    }

                    if (linkOrLinkList) {
                      return (
                        <li
                          className="table-of-contents__list-item"
                          key={linkOrLinkList.title + String(index)}
                        >
                          <ChapterNavigationList {...linkOrLinkList} />
                        </li>
                      );
                    }

                    return null;
                  })}
                </ul>
              </Collapse>
            </div>
          </div>
        );
      }}
    </Accordion>
  );
}

TableOfContents.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      link: PropTypes.exact(ChapterLink.propTypes),
      linkOrLinkList: PropTypes.exact(ChapterNavigationList.propTypes)
    })
  ),
  title: PropTypes.string.isRequired,
  accordion: PropTypes.exact(Accordion.propTypes)
};

TableOfContents.defaultProps = {
  items: []
};

export default TableOfContents;
