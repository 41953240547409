import { useState } from 'react';
import resizeHook from 'js/hooks/resize';

const screens = {
  smMax: 'screen and (max-width: 510px)',
  mdMax: 'screen and (max-width: 768px)',
  md: 'print, screen and (min-width: 768px)',
  lg: 'print, screen and (min-width: 1024px)'
};

const useIsScreenSize = (screenSize = 'lg') => {
  const [isScreenSize, setIsScreenSize] = useState(undefined);

  const screen = screens[screenSize];

  const onResize = () => {
    setIsScreenSize(window.matchMedia(screen).matches);
  };

  resizeHook(onResize);

  return isScreenSize;
};

export default useIsScreenSize;
