import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Accordion from 'components/accordion/accordion';
import Icon from 'components/icon/icon';

const TableMobileSort = ({
  options,
  selectedOption,
  setSortOption,
  title,
  accordion
}) => {
  return (
    <div>
      <label className="table-mobile-sort--label" htmlFor="sort-button">
        {title}
      </label>
      <Accordion {...accordion} animate={false}>
        {({ Collapse, isOpen, toggle }) => (
          <div className="table-mobile-sort">
            <button
              id="sort-button"
              className="table-mobile-sort--dropdown-button"
              onClick={toggle}
            >
              {selectedOption}
              <Icon name="arrow-down" />
            </button>
            <Collapse className="table-mobile-sort--collapse">
              <div className="table-mobile-sort--dropdown">
                <h4 className="table-mobile-sort--dropdown-title">{title}</h4>
                <ul className="table-mobile-sort--dropdown-list">
                  {options.map(option => (
                    <li
                      className="table-mobile-sort--dropdown-item"
                      key={option}
                    >
                      <button
                        className={cn('table-mobile-sort--sort-button', {
                          '-activated': option === selectedOption
                        })}
                        onClick={() => {
                          setSortOption(option);
                          toggle();
                        }}
                      >
                        {option}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </Collapse>
            {isOpen && (
              <button className="table-mobile-sort--overlay" onClick={toggle} />
            )}
          </div>
        )}
      </Accordion>
    </div>
  );
};
TableMobileSort.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  setSortOption: PropTypes.func,
  selectedOption: PropTypes.string,
  accordion: PropTypes.exact(Accordion.propTypes),
  title: PropTypes.string
};

TableMobileSort.propTypesMeta = {
  options: 'exclude',
  selectedOption: 'exclude',
  setSortOption: 'exclude'
};

export default TableMobileSort;
