import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import debounce from 'lodash/debounce';
import clamp from '@creuna/utils/clamp';
import rangeMap from '@creuna/utils/range-map';

import Button from 'components-old/button';
import TextInput from 'components-old/form-elements/text-input';

class RangeSlider extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    from: PropTypes.exact(TextInput.propTypes).isRequired,
    label: PropTypes.string.isRequired,
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    submit: PropTypes.exact(Button.propTypes).isRequired,
    to: PropTypes.exact(TextInput.propTypes).isRequired
  };

  static propTypesMeta = {
    className: 'exclude'
  };

  static defaultProps = {
    from: {},
    to: {}
  };

  state = {
    draggingFrom: false,
    draggingTo: false,
    from: parseInt(this.props.from.value) || this.props.min,
    screenLeft: 0,
    to: parseInt(this.props.to.value) || this.props.max,
    width: 0
  };

  // Intentional duplicate of state equivalents. On mouseup, these are both set to false. The state equivalents are not reset on mouseup, to allow positioning the last modified knob on top
  draggingFrom = false;
  draggingTo = false;

  measure = () => {
    if (!this.slider) {
      return;
    }

    this.setState({
      width: this.slider.offsetWidth,
      screenLeft: this.slider.getBoundingClientRect().left
    });
  };

  clampFrom = (value, state) => {
    return clamp(value, this.props.min, state.to - 1);
  };

  clampTo = (value, state) => {
    return clamp(value, state.from + 1, this.props.max);
  };

  setFrom = e => {
    e.persist();
    this.setState(state => ({
      from: this.clampFrom(parseInt(e.target.value), state)
    }));
  };

  setTo = e => {
    e.persist();
    this.setState(state => ({
      to: this.clampTo(parseInt(e.target.value), state)
    }));
  };

  onDrag = xPosition => {
    if (!this.draggingFrom && !this.draggingTo) {
      return;
    }

    this.setState(state => {
      const position = xPosition - state.screenLeft;
      const value = Math.round(
        rangeMap(position, 0, state.width, this.props.min, this.props.max)
      );

      if (state.draggingFrom) {
        return { from: this.clampFrom(value, state) };
      }

      if (state.draggingTo) {
        return { to: this.clampTo(value, state) };
      }
    });
  };

  onMouseMove = e => {
    this.onDrag(e.clientX);
  };

  onTouchMove = e => {
    this.onDrag(e.touches[0].clientX);
  };

  onFromMouseDown = () => {
    this.draggingFrom = true;
    this.setState({ draggingFrom: true, draggingTo: false });
  };

  onToMouseDown = () => {
    this.draggingTo = true;
    this.setState({ draggingFrom: false, draggingTo: true });
  };

  resetDragging = () => {
    this.draggingFrom = false;
    this.draggingTo = false;
  };

  onResize = debounce(this.measure, 100);

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('mouseup', this.resetDragging);
    window.addEventListener('touchend', this.resetDragging);
    window.addEventListener('mousemove', this.onMouseMove);
    window.addEventListener('touchmove', this.onTouchMove);
    this.measure();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('mouseup', this.resetDragging);
    window.removeEventListener('touchend', this.resetDragging);
    window.removeEventListener('mousemove', this.onMouseMove);
    window.removeEventListener('touchmove', this.onTouchMove);
  }

  render() {
    const { min, max } = this.props;
    const { from, to } = this.state;

    // Positions in percent
    const fromPosition = ((from - min) / (max - min)) * 100;
    const toPosition = ((to - min) / (max - min)) * 100;

    return (
      <fieldset className={cn('range-slider', this.props.className)}>
        <legend>{this.props.label}</legend>

        <div className="range-slider--inputs">
          <TextInput
            controlled={true}
            onChange={this.setFrom}
            type="number"
            theme={TextInput.themes.small}
            {...Object.assign({}, this.props.from, {
              value: String(this.state.from)
            })}
          />
          <TextInput
            controlled={true}
            onChange={this.setTo}
            type="number"
            theme={TextInput.themes.small}
            {...Object.assign({}, this.props.to, {
              value: String(this.state.to)
            })}
          />
        </div>

        <div className="range-slider--slider" ref={div => (this.slider = div)}>
          <div className="range-slider--slider-inner">
            {this.state.width > 0 && (
              <React.Fragment>
                <div
                  className="range-slider--active-region"
                  style={{
                    left: `${fromPosition}%`,
                    width: `${toPosition - fromPosition}%`
                  }}
                />
                <div
                  className="range-slider--knob -from"
                  onMouseDown={this.onFromMouseDown}
                  onTouchStart={this.onFromMouseDown}
                  style={{
                    left: `${fromPosition}%`,
                    zIndex: this.state.draggingFrom ? 2 : null
                  }}
                />
                <div
                  className="range-slider--knob -to"
                  onMouseDown={this.onToMouseDown}
                  onTouchStart={this.onToMouseDown}
                  style={{
                    left: `${toPosition}%`,
                    zIndex: this.state.draggingTo ? 2 : null
                  }}
                />
              </React.Fragment>
            )}
          </div>
        </div>

        <Button
          className="range-slider--submit"
          type="submit"
          theme={Button.themes.fill}
          {...this.props.submit}
        />
      </fieldset>
    );
  }
}

export default RangeSlider;
