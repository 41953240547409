import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-tiny-transition';
import cn from 'classnames';

import useIsScreenSize from 'js/hooks/use-is-screen-size';

import Button from 'components/button';
import ContentWithSidebar from 'components/content-with-sidebar';
import Filters from 'components/filters';
import ContentArea from 'components/content-area';
import useBlockScrolling from 'js/hooks/use-block-scrolling';
import ContentContainer from 'components/content-container';

const FilterLayout = ({
  children,
  contentArea,
  filters,
  topContent,
  includeBottomBorder,
  className,
  fetchFilteredResults,
  isLeft,
  isLoading,
  updateFilterQuery
}) => {
  const [mobileFiltersVisible, setMobileFiltersVisible] = React.useState(false);
  const isDesktop = useIsScreenSize('lg');

  useBlockScrolling(mobileFiltersVisible);
  return (
    <div className={cn('filter-layout', className)}>
      <div className="filter-layout--header">
        <ContentContainer theme={ContentContainer.themes.wide}>
          <div
            className={cn('filter-layout--top-line', {
              'bottom-border': includeBottomBorder
            })}
          >
            {topContent}
          </div>
        </ContentContainer>
        <ContentWithSidebar
          theme={
            isLeft && isDesktop
              ? ContentWithSidebar.themes.sidebarLeft
              : undefined
          }
        >
          {filters && (
            <Button
              className="filter-layout--toggle"
              onClick={() => setMobileFiltersVisible(!mobileFiltersVisible)}
              icon={mobileFiltersVisible ? 'close-small' : 'filter-small'}
              iconFill
              text={filters.mobileTitle}
            />
          )}
        </ContentWithSidebar>
      </div>

      <Transition>
        {filters && mobileFiltersVisible && !isDesktop && (
          <div className="filter-layout--filters -is-mobile">
            <Filters
              {...filters}
              fetchFilteredResults={fetchFilteredResults}
              isDesktop={isDesktop}
              close={() => setMobileFiltersVisible(false)}
              updateFilterQuery={updateFilterQuery}
              isLoading={isLoading}
            />
          </div>
        )}
      </Transition>

      <ContentWithSidebar
        theme={
          isLeft && isDesktop
            ? ContentWithSidebar.themes.sidebarLeft
            : undefined
        }
      >
        <ContentWithSidebar.Content className="filter-layout--content">
          {children}
        </ContentWithSidebar.Content>

        <ContentWithSidebar.Sidebar>
          {filters && isDesktop && (
            <div className="filter-layout--filters -is-desktop">
              <Filters
                {...filters}
                fetchFilteredResults={fetchFilteredResults}
                updateFilterQuery={updateFilterQuery}
              />
            </div>
          )}
          {contentArea && <ContentArea {...contentArea} />}
        </ContentWithSidebar.Sidebar>
      </ContentWithSidebar>
    </div>
  );
};

FilterLayout.propTypes = {
  children: PropTypes.node,
  contentArea: PropTypes.exact(ContentArea.propTypes),
  filters: PropTypes.exact(Filters.propTypes),
  topContent: PropTypes.node,
  includeBottomBorder: PropTypes.bool,
  fetchFilteredResults: PropTypes.func,
  className: PropTypes.string,
  isLeft: PropTypes.bool,
  isLoading: PropTypes.bool,
  updateFilterQuery: PropTypes.func
};

FilterLayout.defaultProps = {
  labels: {},
  includeBottomBorder: true
};

FilterLayout.propTypesMeta = {
  includeBottomBorder: 'exclude',
  className: 'exclude',
  isLoading: 'exclude',
  updateFilterQuery: 'exclude'
};

export default FilterLayout;
