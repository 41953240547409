import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components-old/icon';
import Button from 'components-old/button';
import VolumeSlider from './volume-slider';
import PlayerTimeline from './player-timeline';

const timeString = val => {
  const toDoubleDigits = n => (n.length < 2 ? `0${n}` : n);
  const seconds = parseInt(val);

  const sec = (seconds % 60).toString();
  const min = Math.floor(seconds / 60).toString();

  return `${toDoubleDigits(min)}:${toDoubleDigits(sec)}`;
};

const AudioPlayer = ({ url, labels = {}, altText }) => {
  if (!url) return null;
  const [volume, setVolume] = useState(0.5);
  const [isPlaying, setIsPlaying] = useState(false);
  const [secondsPlayed, setSecondsPlayed] = useState(0);
  const [secondsTotal, setSecondsTotal] = useState(0);
  const [showVolumeSlider, setShowVolumeSlider] = useState(false);

  const audioPlayer = useRef();
  const volumeBtn = useRef();

  useEffect(() => {
    isPlaying ? audioPlayer.current.play() : audioPlayer.current.pause();
  }, [isPlaying]);

  useEffect(() => {
    audioPlayer.current.volume = volume;
  }, [volume]);

  useEffect(() => {
    volumeBtn.current.addEventListener('touchstart', function (e) {
      e.preventDefault; // Prevents scrolling
      setShowVolumeSlider(true);
    });
  }, []);

  const setTime = value => {
    const newTime = value * secondsTotal;
    audioPlayer.current.currentTime = newTime;
  };

  return (
    <div className="audio-player">
      <div className="audio-player--row">
        <Button
          className="audio-player--button"
          onClick={() => setIsPlaying(prevState => !prevState)}
          icon={isPlaying ? 'stop' : 'play'}
          iconFill
          attributes={{ 'aria-label': isPlaying ? labels.pause : labels.play }}
        />
        <span className="audio-player--duration">
          {timeString(secondsPlayed)}/{timeString(secondsTotal)}
        </span>

        <PlayerTimeline
          className="audio-player--timeline-desktop"
          progress={secondsPlayed / secondsTotal}
          setTime={setTime}
        />
        <div
          className="audio-player--volume audio-player--button"
          onMouseEnter={() => setShowVolumeSlider(true)}
          onTouchEnd={() => setShowVolumeSlider(false)}
          onFocus={() => setShowVolumeSlider(true)}
          onBlur={() => setShowVolumeSlider(false)}
          ref={volumeBtn}
          tabIndex={0}
        >
          {showVolumeSlider && (
            <VolumeSlider
              volume={volume}
              setVolume={setVolume}
              close={() => setShowVolumeSlider(false)}
            />
          )}
          <Icon name="volume" fill />
        </div>
        <a
          className="audio-player--button"
          aria-label={labels.download}
          href={url}
          download
        >
          <Icon name="download" fill />
        </a>
        <audio
          src={url}
          ref={audioPlayer}
          onTimeUpdate={e => setSecondsPlayed(e.target.currentTime)}
          onCanPlay={e => setSecondsTotal(e.target.duration)}
        >
          {altText}
        </audio>
      </div>
      <PlayerTimeline
        className="audio-player--timeline-mobile"
        progress={secondsPlayed / secondsTotal}
        setTime={setTime}
      />
    </div>
  );
};

AudioPlayer.propTypes = {
  url: PropTypes.string.isRequired,
  labels: PropTypes.exact({
    play: PropTypes.string,
    download: PropTypes.string,
    pause: PropTypes.string
  }),
  altText: PropTypes.string
};

export default AudioPlayer;
