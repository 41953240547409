import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';

import Accordion from 'components/accordion';
import Icon from 'components/icon';
import RichText from 'components/rich-text';

const FactBox = ({
  accordion,
  onPageEditing,
  title,
  text,
  topBorder,
  transparentRows
}) => {
  const isEditMode = Object.keys(onPageEditing).length > 0;

  return (
    <Accordion {...accordion}>
      {({ Collapse, Button, isOpen, toggle }) => (
        <div
          className={cn('fact-box', {
            'fact-box--with-top-border': topBorder,
            'fact-box--bg-transparent': transparentRows
          })}
        >
          <Button
            className="fact-box--header"
            onClick={isEditMode ? () => { } : toggle}
            text={null}
          >
            <h2
              className="fact-box--header-title"
              {...OPE(onPageEditing.title)}
            >
              <span> {title}</span>
              <Icon
                className={cn('fact-box--icon', {
                  '-is-open': isOpen
                })}
                name="small-arrow-down"
                fill={true}
              />
            </h2>
          </Button>

          <Collapse>
            <div className="fact-box--content">
              <RichText {...text} />
            </div>
          </Collapse>
        </div>
      )}
    </Accordion>
  );
};

FactBox.propTypes = {
  accordion: PropTypes.exact(Accordion.propTypes),
  topBorder: PropTypes.bool,
  transparentRows: PropTypes.bool,
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),

  title: PropTypes.string,
  text: PropTypes.exact(RichText.propTypes)
};

FactBox.propTypesMeta = {
  topBorder: 'exclude',
  transparentRows: 'exclude'
};

FactBox.defaultProps = {
  topBorder: true,
  transparentRows: false,
  onPageEditing: {}
};

export default FactBox;
