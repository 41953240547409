import React from 'react';
import PropTypes from 'prop-types';

import ArticleBlock from 'components-old/article-block';
import RelatedContent from 'components-old/related-content';

const RelatedArticles = ({
  articles,
  relatedContent,
  usedInSidebar,
  isGrid,
  isPublication
}) => {
  const articleTheme = () => {
    const themes = [ArticleBlock.themes.noMargin];
    if (usedInSidebar) themes.push(ArticleBlock.themes.sidebar);
    if (isGrid) themes.push(ArticleBlock.themes.vertical);
    if (isPublication) themes.push(ArticleBlock.themes.publication);
    return themes;
  };
  const relatedContentProps = { ...relatedContent, isGrid };
  return (
    <RelatedContent {...relatedContentProps}>
      {articles.map(article => (
        <ArticleBlock
          usedInSidebar={usedInSidebar}
          headingLevel={3}
          key={article.title.text}
          theme={articleTheme()}
          {...article}
        />
      ))}
    </RelatedContent>
  );
};

RelatedArticles.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.exact(ArticleBlock.propTypes)),
  relatedContent: PropTypes.exact(RelatedContent.propTypes),
  usedInSidebar: PropTypes.bool,
  isGrid: PropTypes.bool,
  isPublication: PropTypes.bool
};

RelatedArticles.defaultProps = {
  articles: []
};

export default RelatedArticles;
