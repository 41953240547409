import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'components-old/accordion/accordion';
import Heading from 'components-old/heading/heading';
import Icon from 'components-old/icon/icon';

const TableMobileItem = ({ list, accordion }) => {
  const title = list[1].value;
  const subtitle = `${list[0].label} ${list[0].value}`;
  return (
    <div className="table-mobile-item">
      <Accordion {...accordion} buttonTextForLabel={`${title}`}>
        {({ Button, Collapse, toggle }) => (
          <>
            <div className="table-mobile-item--header" onClick={toggle}>
              <div>
                <Button>
                  <Heading className="table-mobile-item--title">
                    {title}
                  </Heading>
                </Button>
                <div>{subtitle}</div>
              </div>
              <Icon
                className="table-mobile-item--icon"
                name="small-arrow-down"
              />
            </div>
            <Collapse>
              <div className="table-mobile-item--list">
                {list.slice(2).map(({ displayValue, label, value }) => (
                  <div className="table-mobile-item--list-item" key={value}>
                    <div>{label}:</div>
                    <div>{displayValue || value}</div>
                  </div>
                ))}
              </div>
            </Collapse>
          </>
        )}
      </Accordion>
    </div>
  );
};

TableMobileItem.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.string,
      displayValue: PropTypes.string
    })
  ),
  accordion: PropTypes.exact(Accordion.propTypes)
};

export default TableMobileItem;
