import React from 'react';
import PropTypes from 'prop-types';
import OPE from 'js/on-page-editing';
import YoutubeVideo from 'components-old/youtube-video';
import EmbedBlock from 'components-old/embed-block';
import FluidImage from 'components-old/fluid-image';
import Link from 'components-old/link';

import cn from 'classnames';

const MediaBlock = ({
  title,
  onPageEditing,
  youTubeVideo,
  embed,
  image,
  url
}) => {
  const LinkWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;
  const hasTitle = Boolean(title || onPageEditing.title);
  const titleElement = url ? (
    <Link
      iconBeforeChildren="small-arrow-right"
      iconClassName="media-block--title-icon"
      url={image && url ? null : url}
      text={title}
    />
  ) : (
    title
  );
  return (
    <div className={cn('media-block', { '-has-title': hasTitle })}>
      <LinkWrapper
        condition={url && image}
        wrapper={children => <Link url={url}>{children}</Link>}
      >
        {title && (
          <h2
            className={cn({ '-has-link': url })}
            {...OPE(onPageEditing.title)}
          >
            {titleElement}
          </h2>
        )}
        <div className="media-block__content">
          {youTubeVideo && <YoutubeVideo {...youTubeVideo} />}
          {embed && (
            <div className="media-block__embed">
              <EmbedBlock {...embed}></EmbedBlock>
            </div>
          )}
          {image && (
            <div className="media-block__image">
              <FluidImage {...image}></FluidImage>
            </div>
          )}
        </div>
      </LinkWrapper>
    </div>
  );
};

MediaBlock.propTypes = {
  title: PropTypes.string,
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),
  youTubeVideo: PropTypes.exact(YoutubeVideo.propTypes),
  embed: PropTypes.exact(EmbedBlock.propTypes),
  image: PropTypes.exact(FluidImage.propTypes),
  url: PropTypes.string
};

MediaBlock.defaultProps = {
  onPageEditing: {}
};

export default MediaBlock;
