import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ htmlId, title }) => {
  return <h2 id={htmlId}>{title}</h2>;
};

Label.propTypes = {
  htmlId: PropTypes.string,
  title: PropTypes.string
};

export default Label;
