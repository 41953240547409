import PropTypes from 'prop-types';

// Creates proptype definition from themes
export default function (themes) {
  const singlePropType = PropTypes.oneOf(
    Object.keys(themes).map(key => themes[key])
  );

  return PropTypes.oneOfType([
    singlePropType,
    PropTypes.arrayOf(singlePropType)
  ]);
}
