import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Heading from 'components-old/heading';
import ProcessItem from './process-item';
import ProcessCarousel from './process-carousel';
import AutoGrid from 'components-old/auto-grid';
import Icon from 'components-old/icon';
import Image from 'components-old/image';

const Process = ({ title, introText, isCarousel, items, labels }) => {
  const processRef = useRef();

  return (
    <>
      <div className="process" ref={processRef}>
        {title && <Heading>{title}</Heading>}
        {introText && <p>{introText}</p>}
        {items.length &&
          (isCarousel ? (
            <ProcessCarousel items={items} labels={labels} />
          ) : (
            <AutoGrid>
              {AutoGridItem =>
                items.map((item, index) => (
                  <AutoGridItem key={item.title || item.text || index}>
                    <ProcessItem key={item.text} {...item} index={index} />
                  </AutoGridItem>
                ))
              }
            </AutoGrid>
          ))}
      </div>
      {items.length && (
        <div className="process-print">
          {items.map(({ title, text, icon }, index) => (
            <div className="process-print--item" key={title || text || index}>
              {icon ? (
                <Image {...icon} />
              ) : (
                <Heading className="process-item--number" level={2}>
                  {index + 1}
                </Heading>
              )}
              <Heading className="process-item--title">{title}</Heading>
              <p>{text}</p>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

Process.defaultProps = {
  items: []
};

Process.propTypes = {
  title: PropTypes.string,
  introText: PropTypes.string,
  isCarousel: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.exact(ProcessItem.propTypes)),
  labels: ProcessCarousel.propTypes.labels
};

export default Process;
