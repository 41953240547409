import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import ContentArea from 'components-old/content-area';
import ContentAreaItem from 'components-old/content-area-item';
import Link from 'components-old/link';

const OfficeListBlock = ({ links, offices }) => (
  <div className="office-list-block">
    <ul className="office-list-block--links">
      {links.map(link => (
        <li key={link.id} className={cn({ '-is-active': link.isActive })}>
          <Link
            theme={link.isActive ? Link.themes.black : null}
            {...link.link}
          />
        </li>
      ))}
    </ul>

    <div className="office-list-block--offices">
      <ContentArea
        additionalItemProps={{
          OfficeBlock: { theme: ContentAreaItem.themes.largeMargin }
        }}
        {...offices}
      />
    </div>
  </div>
);

OfficeListBlock.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
      link: PropTypes.exact(Link.propTypes).isRequired
    })
  ),
  offices: PropTypes.exact(ContentArea.propTypes)
};

OfficeListBlock.defaultProps = {
  links: []
};

export default OfficeListBlock;
