import React from 'react';
import PropTypes from 'prop-types';

import Heading from 'components/heading';

const DescriptionListBlock = ({ headingLevel, items, title }) => (
  <div className="description-list-block">
    {title && (
      <Heading className="description-list-block--title" level={headingLevel}>
        {title}
      </Heading>
    )}

    <dl className="description-list-block">
      {items.map(item => (
        <span key={item.label + item.text}>
          <dt>{item.label}</dt>
          <dd>{item.text}</dd>
        </span>
      ))}
    </dl>
  </div>
);

DescriptionListBlock.propTypes = {
  headingLevel: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      text: PropTypes.string
    })
  ),
  title: PropTypes.string
};

DescriptionListBlock.propTypesMeta = {
  headingLevel: 'exclude'
};

DescriptionListBlock.defaultProps = {
  headingLevel: 2,
  items: []
};

export default DescriptionListBlock;
