import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import icons from '../../assets/inline-svg-icons/icons';

const Icon = ({ className, fill, name, style }) =>
  icons[name]
    ? React.createElement(icons[name], {
        className: cn('icon', className, { '-use-fill-color': fill }),
        focusable: 'false',
        style
      })
    : null;

Icon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.bool,
  name: PropTypes.oneOf(Object.keys(icons)).isRequired
};

Icon.propTypesMeta = {
  className: 'exclude',
  fill: 'exclude',
  name: 'exclude'
};

export default Icon;
