import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';

const EmployeeSearchResult = ({ labeledLinks, subTitle, title }) => (
  <div className="employee-search-result">
    {title && <h3>{title}</h3>}

    {subTitle && <h4>{subTitle}</h4>}

    {labeledLinks.length > 0 && (
      <ul className="employee-search-result--links">
        {labeledLinks.map(({ label, link }) => (
          <li key={label}>
            <span>{label}: </span>
            {link.url ? (
              <Link {...link} theme={Link.themes.underline} />
            ) : (
              <span>{link.text}</span>
            )}
          </li>
        ))}
      </ul>
    )}
  </div>
);

EmployeeSearchResult.propTypes = {
  labeledLinks: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      link: PropTypes.exact(Link.propTypes)
    })
  ),
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired
};

EmployeeSearchResult.defaultProps = {
  labeledLinks: []
};

export default EmployeeSearchResult;
