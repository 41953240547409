/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const getZIndex = (length, index, currentIndex) => {
  if (index === currentIndex) return length;
  if (index < currentIndex) return index;
  return length - index;
};

const TabList = ({ children, className }) => {
  return (
    <div className={cn('tab-list', className)}>
      <ul>{children}</ul>
    </div>
  );
};
TabList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

TabList.propTypesMeta = 'exclude';

TabList.Item = ({
  children,
  isCurrent,
  length,
  index,
  currentIndex,
  disabled
}) => (
  <li
    className={cn({ '-is-active': isCurrent }, { disabled: disabled })}
    style={{
      zIndex: getZIndex(length, index, currentIndex)
    }}
  >
    {children}
  </li>
);

TabList.Item.propTypes = {
  children: PropTypes.node,
  isCurrent: PropTypes.bool,
  length: PropTypes.number,
  index: PropTypes.number,
  currentIndex: PropTypes.number,
  disabled: PropTypes.bool
};
export default TabList;
