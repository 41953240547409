import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Link from 'components-old/link';
import Button from 'components-old/button';

const PageNavigation = ({ previous, next, isLargeArticle, isFirstPage }) => {
  const articleStyleLinkProps = isLargeArticle
    ? {
        previous: { theme: [Button.themes.outline], useButtonStyles: true },
        next: { theme: [Button.themes.fill], useButtonStyles: true }
      }
    : {};

  return (
    <div
      className={cn('page-navigation', {
        '-large-article-theme': isLargeArticle
      })}
    >
      {previous && (
        <Link
          className="page-navigation--icon-left"
          iconBeforeChildren={isLargeArticle ? 'arrow-short' : 'long-arrow'}
          iconClassName="page-navigation--icon"
          theme={[Link.themes.smallText]}
          {...previous}
          {...articleStyleLinkProps.previous}
        />
      )}
      {next && (
        <Link
          className={cn('page-navigation--icon-right', {
            '-is-first-page': isLargeArticle && isFirstPage
          })}
          icon={isLargeArticle ? 'arrow-short' : 'long-arrow'}
          iconClassName="page-navigation--icon"
          theme={[Link.themes.smallText]}
          {...next}
          {...articleStyleLinkProps.next}
        />
      )}
    </div>
  );
};

PageNavigation.propTypes = {
  previous: PropTypes.shape(Link.propTypes),
  next: PropTypes.shape(Link.propTypes)
};

export default PageNavigation;
