import React, { useState, useEffect } from 'react';
import Message from 'components-old/message';
import PropTypes from 'prop-types';

import Checkbox from 'components-old/form-elements/checkbox';

const CheckboxGroup = ({
  options,
  setIsChecked,
  title,
  required,
  requiredText,
  setMessage,
  validationError
}) => {
  const [checkedList, setCheckedList] = useState(options.map(() => false));
  const onCheck = (e, i) => {
    if (!setIsChecked) return;
    const checked = e.target.checked;
    setCheckedList(state =>
      state.map((stateChecked, index) => (i === index ? checked : stateChecked))
    );
  };
  useEffect(() => {
    if (!setIsChecked) return;
    if (required) {
      if (!checkedList.some(checked => !!checked)) {
        setMessage(requiredText);
      }
      setIsChecked(checkedList.some(checked => !!checked));
    }
  }, [...checkedList]);

  return (
    <div className="checkbox-group">
      {title && <h2 className="checkbox-group--title">{title}</h2>}
      {validationError && (
        <div className="checkbox-group--validation-error">
          {validationError}
        </div>
      )}

      {options.map((checkbox, i) => (
        <Checkbox
          className="checkbox-group--checkbox"
          key={checkbox.name}
          onChange={e => onCheck(e, i)}
          theme={Checkbox.themes.gray}
          {...checkbox}
        />
      ))}
    </div>
  );
};

CheckboxGroup.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.exact(Checkbox.propTypes)),
  validationError: PropTypes.string,
  setIsChecked: PropTypes.func,
  required: PropTypes.bool,
  requiredText: PropTypes.exact(Message.propTypes),
  setMessage: PropTypes.func
};

export default CheckboxGroup;
