/* eslint-disable react/no-multi-comp */

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ContentContainer from 'components/content-container';
import ContentWithSidebar from 'components/content-with-sidebar';
import StickyMenuOnTabs from 'components/sticky-menu-on-tabs';
import SimpleTabContentSection from 'components/tab-content-section-simple';

const buildMenuData = (menuTitle, contentSections) => {
  return {
    title: menuTitle,

    navGroups: contentSections.map(section => {
      var titleLink = section.sectionTitle && {
        text: section.sectionTitle,
        url: `#${section.htmlId}`
      };

      var links = section.subSections && {
        items: section.subSections.map(x => {
          return {
            link: {
              text: x.title,
              url: `#${x.htmlId}`
            }
          };
        })
      };

      return {
        ...(titleLink != null && { titleLink: titleLink }),
        ...(links != null && { links: links })
      };
    })
  };
};

const TibiTabContent = ({ menuTitle, contentSections }) => {
  const className = 'tab-content';
  const menu = buildMenuData(menuTitle, contentSections);

  const tibiTabContent = (
    <React.Fragment>
      {contentSections &&
        contentSections.map((contentSection, index) => (
          <SimpleTabContentSection {...contentSection} key={index} />
        ))}
    </React.Fragment>
  );

  return !menu ? (
    <ContentContainer
      theme={ContentContainer.themes.medium}
      className={className}
    >
      {tibiTabContent}
    </ContentContainer>
  ) : (
    <ContentContainer
      className={cn(className, 'tab-content__container')}
      theme={ContentContainer.themes.wide}
    >
      <ContentWithSidebar
        theme={ContentWithSidebar.themes.sidebarLeft}
        className="tab-content__grid"
        useContentContainer={false}
      >
        <ContentWithSidebar.Sidebar>
          <StickyMenuOnTabs {...menu} includeHeader />
        </ContentWithSidebar.Sidebar>
        <ContentWithSidebar.Content className="tab-content__content">
          {tibiTabContent}
        </ContentWithSidebar.Content>
      </ContentWithSidebar>
    </ContentContainer>
  );
};

TibiTabContent.propTypes = {
  menuTitle: PropTypes.string,
  contentSections: PropTypes.arrayOf(
    PropTypes.exact(SimpleTabContentSection.propTypes)
  )
};

TibiTabContent.defaultProps = {};

export default TibiTabContent;
