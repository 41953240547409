import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';
import propTypeTheme from 'js/utils/prop-type-theme';

import Link from 'components-old/link';

const themes = {
  big: '-theme-big',
  portfolio: '-theme-portfolio'
};

const LinkWithText = ({ link, linkTheme, onPageEditing, text, theme }) => (
  <div className={cn('link-with-text', theme)}>
    <h3 {...OPE(onPageEditing.link)}>
      <Link
        iconBeforeChildren="small-arrow-right"
        theme={linkTheme}
        {...link}
      />
    </h3>
    <p className="link-with-text--text" {...OPE(onPageEditing.text)}>
      {text}
    </p>
  </div>
);

LinkWithText.propTypes = {
  link: PropTypes.exact(Link.propTypes),
  linkTheme: propTypeTheme(Link.themes),
  onPageEditing: PropTypes.exact({
    link: PropTypes.string,
    text: PropTypes.string
  }),
  text: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes))
};

LinkWithText.propTypesMeta = {
  linkTheme: 'exclude',
  theme: 'exclude'
};

LinkWithText.defaultProps = {
  onPageEditing: {}
};

LinkWithText.themes = themes;

export default LinkWithText;
