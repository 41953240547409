import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components-old/link';

const SearchSuggestions = ({ label, suggestions }) => (
  <div className="search-suggestions">
    <span className="search-suggestions__label">{label}</span>
    {suggestions.map((suggestion, index) => (
      <React.Fragment key={`${suggestion}-${index}`}>
        <Link text={suggestion} url={`?q=${suggestion}`} />
        {index < suggestions.length - 1 && <span>,&nbsp;</span>}
      </React.Fragment>
    ))}
  </div>
);

SearchSuggestions.propTypes = {
  label: PropTypes.string.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default SearchSuggestions;
