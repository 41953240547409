import React from 'react';
import PropTypes from 'prop-types';

import ChapterLink from 'components-old/chapter-link';
import ChapterNavigationList from './chapter-navigation-list';
import Accordion from 'components-old/accordion';
import Icon from 'components-old/icon';

const TableOfContents = ({ items, title, accordion }) => (
  <Accordion expandOnMount={windowWidth => windowWidth >= 1024} {...accordion}>
    {({ Button, Collapse, isOpen, toggle }) => {
      const open = () => !isOpen && toggle();

      return (
        <div className="table-of-contents" onClick={open}>
          <div className="table-of-contents__content">
            <div className="table-of-contents__header">
              {title && <h2 className="table-of-contents__title">{title}</h2>}
              <Button className="table-of-contents__button">
                <Icon
                  className="table-of-contents__button-icon"
                  name={isOpen ? 'x-circle' : 'plus-circle'}
                  fill
                />
              </Button>
            </div>
            <Collapse>
              <ul className="table-of-contents__list">
                {items.map(({ link, linkOrLinkList }, index) => {
                  if (link) {
                    return (
                      <li
                        className="table-of-contents__list-item"
                        key={link.chapterNumber + String(index)}
                      >
                        <ChapterLink {...link} />
                      </li>
                    );
                  }

                  if (linkOrLinkList) {
                    return (
                      <li
                        className="table-of-contents__list-item"
                        key={linkOrLinkList.title + String(index)}
                      >
                        <ChapterNavigationList {...linkOrLinkList} />
                      </li>
                    );
                  }

                  return null;
                })}
              </ul>
            </Collapse>
          </div>
        </div>
      );
    }}
  </Accordion>
);

TableOfContents.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      link: PropTypes.exact(ChapterLink.propTypes),
      linkOrLinkList: PropTypes.exact(ChapterNavigationList.propTypes)
    })
  ),
  title: PropTypes.string.isRequired,
  accordion: PropTypes.exact(Accordion.propTypes)
};

TableOfContents.defaultProps = {
  items: []
};

export default TableOfContents;
