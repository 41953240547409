import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Button from 'components-old/button';
import ContentContainer from 'components-old/content-container';
import GlobalSearch from './global-search';
import Link from 'components-old/link';
import Menu from 'components-old/menu';
import SimpleHeader from './simple-header';
import TabMenu from 'components-old/tab-menu';
import useOutsideClick from 'js/hooks/use-outside-click';

const Header = ({
  globalSearch,
  linkToHome,
  linkList,
  logo,
  menu,
  menuText,
  satellite,
  tabMenu,
  priorityLink
}) => {
  const [globalSearchIsOpen, setGlobalSearchIsOpen] = useState();
  const [isMounted, setIsMounted] = useState();
  const [menuIsOpen, setMenuIsOpen] = useState();

  const containerRef = useRef();

  const openGlobalSearch = e => {
    e.preventDefault();
    setGlobalSearchIsOpen(true);
    setMenuIsOpen(false);
  };

  const closeGlobalSearch = () => {
    setGlobalSearchIsOpen(false);
  };

  const openMenu = () => {
    document.body.style.position = 'fixed';
    setMenuIsOpen(true);
    setGlobalSearchIsOpen(false);
  };

  const closeMenu = () => {
    document.body.style.position = '';
    setMenuIsOpen(false);
  };

  useOutsideClick(containerRef, closeGlobalSearch);

  useEffect(() => setIsMounted(true));

  const menuId = 'main-menu';
  const menuLinkList = priorityLink ? [priorityLink, ...linkList] : linkList;
  return (
    <React.Fragment>
      <TabMenu openMenu={openMenu} {...tabMenu} />
      {satellite.isVisible && <SimpleHeader {...satellite.header} />}
      <header
        className={cn('header', {
          '-search-is-open': globalSearchIsOpen
        })}
        ref={containerRef}
      >
        <ContentContainer
          element="nav"
          className="header--content"
          theme={ContentContainer.themes.wide}
        >
          <a
            href={linkToHome.url}
            className={cn('header--logo', {
              '-search-is-open': globalSearchIsOpen
            })}
          >
            <span>{linkToHome.text}</span>
            <img
              alt="Logo"
              src={globalSearchIsOpen ? logo.white : logo.default}
            />
          </a>
          <ul
            className={cn('header--nav', {
              '-menu-is-open': menuIsOpen
            })}
          >
            {linkList.map(link => (
              <li key={link.url} className="header--link">
                <Link openInNewWindow={link.isExternal} {...link} />
              </li>
            ))}
            {priorityLink && (
              <li key={priorityLink.url} className="header--link">
                <Link
                  openInNewWindow={priorityLink.isExternal}
                  {...priorityLink}
                />
              </li>
            )}
            <li className="header--search">
              {globalSearch && globalSearch.input && (
                <GlobalSearch
                  closeGlobalSearch={closeGlobalSearch}
                  isOpen={globalSearchIsOpen}
                  openGlobalSearch={openGlobalSearch}
                  {...globalSearch}
                />
              )}
            </li>
            <li className="header--menu">
              <Button
                className="header--menu-button"
                icon="menu"
                iconClassName="header--menu-icon"
                iconFill
                theme={[
                  Button.themes.white,
                  Button.themes.smallMargin,
                  Button.themes.medium,
                  globalSearchIsOpen
                    ? Button.themes.outline
                    : Button.themes.fill
                ]}
                onClick={openMenu}
                attributes={{
                  'aria-controls': isMounted ? menuId : null,
                  'aria-expanded': menuIsOpen
                }}
              >
                {menuText}
              </Button>
            </li>
          </ul>

          <Menu
            closeMenu={closeMenu}
            globalSearch={globalSearch}
            id={menuId}
            isOpen={menuIsOpen}
            linkList={menuLinkList}
            {...menu}
          />
        </ContentContainer>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  globalSearch: PropTypes.exact(GlobalSearch.propTypes).isRequired,
  linkToHome: PropTypes.exact(Link.propTypes),
  linkList: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)).isRequired,
  logo: PropTypes.exact({
    default: PropTypes.string,
    white: PropTypes.string
  }),
  menu: PropTypes.exact(Menu.propTypes),
  menuText: PropTypes.string.isRequired,
  satellite: PropTypes.exact({
    isVisible: PropTypes.bool,
    header: PropTypes.exact(SimpleHeader.propTypes)
  }),
  tabMenu: PropTypes.exact(TabMenu.propTypes),
  priorityLink: PropTypes.exact(Link.propTypes)
};

Header.defaultProps = {
  linkList: [],
  linkToHome: {},
  logo: {
    default: require('../../assets-old/images/logo.png').default,
    white: require('../../assets-old/images/logo-white.png').default
  },
  satellite: {}
};

export default Header;
