import React from 'react';
import PropTypes from 'prop-types';

import NewsletterAdministrationLayout from 'components-old/newsletter-administration-layout';

const NewsletterUnsubscribeConfirmationPage = ({ layout, title, text }) => (
  <NewsletterAdministrationLayout
    {...layout}
    className="newsletter-unsubscribe-confirmation-page"
  >
    {title && <h1>{title}</h1>}
    <p>{text}</p>
  </NewsletterAdministrationLayout>
);

NewsletterUnsubscribeConfirmationPage.propTypes = {
  layout: PropTypes.exact(NewsletterAdministrationLayout.propTypes),
  title: PropTypes.string,
  text: PropTypes.string
};

export default NewsletterUnsubscribeConfirmationPage;
