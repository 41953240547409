/* eslint-disable react/no-multi-comp */

import React from 'react';
import PropTypes from 'prop-types';
import RichTextWithTitle from 'components/rich-text-with-title';
import RichText from 'components/rich-text';

const SimpleTabContentSection = ({
  sectionTitle,
  sectionText,
  htmlId,
  subSections
}) => {
  return (
    <div className="tab-content-section">
      {sectionTitle && (
        <h2
          id={htmlId}
          data-epi-type="title"
          className="tab-content-section-title"
        >
          {sectionTitle}
        </h2>
      )}
      {sectionText && (
        <div className="tab-content-section--text" key={sectionTitle}>
          <RichText {...sectionText} />
        </div>
      )}

      {subSections &&
        subSections.map(
          text =>
            text && (
              <div className="tab-content-section--text" key={text.title}>
                <RichTextWithTitle {...text} />
              </div>
            )
        )}
    </div>
  );
};

SimpleTabContentSection.propTypes = {
  sectionTitle: PropTypes.string,
  sectionText: PropTypes.exact(RichText.propTypes),
  htmlId: PropTypes.string,
  subSections: PropTypes.arrayOf(PropTypes.exact(RichTextWithTitle.propTypes))
};

export default SimpleTabContentSection;
