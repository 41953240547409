import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';
import Icon from 'components/icon';
import ShareOptions from 'components/share-options';
import DownloadList from 'components/download-list';
import TabTrapper from 'components/modal/tab-trapper';

import cn from 'classnames';

import useOutsideClick from 'js/hooks/use-outside-click';

const themes = {
  isArticleHeader: '-is-article-header',
  isProposalOrEvent: '-is-proposal-or-event',
  isTextWithSidebar: '-is-text-with-sidebar',
  isPageFooter: '-is-page-footer'
};

const OptionsModal = ({
  theme,
  openButtonText,
  closeButtonLabel,
  shareContent,
  downloadContent,
  className
}) => {
  const [show, setShow] = React.useState(false);
  const openModal = () => setShow(true);
  const closeModal = () => setShow(false);

  const clickOutsideRef = useRef();
  useOutsideClick(clickOutsideRef, () => {
    closeModal();
  });

  const iconBeforeChildren = theme => {
    if (theme === themes.isTextWithSidebar) return 'share-small';
    if (theme === themes.isArticleHeader) return 'share-new-profile-small';
    return undefined;
  };

  return (
    <div
      ref={clickOutsideRef}
      className={cn('options-modal', theme, className)}
    >
      <div className="options-modal__container">
        <Button
          iconBeforeChildren={iconBeforeChildren(theme)}
          icon={
            theme === themes.isPageFooter || !theme
              ? 'share-small'
              : theme === themes.isProposalOrEvent
              ? 'arrow-down'
              : undefined
          }
          theme={Button.themes.smallMargin}
          className={cn(
            'options-modal__button',
            show && 'options-modal__button--is-open'
          )}
          onClick={show ? closeModal : openModal}
        >
          {openButtonText}
        </Button>
        <TabTrapper isActive={show}>
          <div
            className={cn(
              show
                ? 'options-modal__modal-container'
                : 'options-modal__modal-container--hidden'
            )}
          >
            <Button
              title={closeButtonLabel}
              className="options-modal__close-button"
              onClick={closeModal}
            >
              <Icon
                className="options-modal__close-button--icon"
                name="close-small"
              ></Icon>
            </Button>
            {shareContent && <ShareOptions {...shareContent} />}
            {downloadContent && (
              <div className="options-modal__download-list">
                <DownloadList {...downloadContent} />
              </div>
            )}
          </div>
        </TabTrapper>
      </div>
    </div>
  );
};

OptionsModal.propTypes = {
  shareContent: PropTypes.exact(ShareOptions.propTypes),
  downloadContent: PropTypes.exact(DownloadList.propTypes),
  theme: PropTypes.oneOf(Object.values(themes)),
  openButtonText: PropTypes.string,
  closeButtonLabel: PropTypes.string,
  className: PropTypes.string
};

OptionsModal.propTypesMeta = {
  theme: 'exclude',
  className: 'exclude'
};

OptionsModal.themes = themes;

export default OptionsModal;
