import React from 'react';

import ContactInfo from 'components/contact-info';
import Link from 'components/link';

const ProposalContact = ({ details, title }) => {
  return (
    <div className="proposal-contact">
      <span>{title}</span>
      {details.map(detail => (
        <Link noHoverEffect={true} key={detail.text} {...detail} />
      ))}
    </div>
  );
};

ProposalContact.propTypes = ContactInfo.propTypes;

ProposalContact.defaultProps = ContactInfo.defaultProps;

export default ProposalContact;
