import React from 'react';
import PropTypes from 'prop-types';
import OPE from 'js/on-page-editing';

import Button from 'components/button';
import ContentArea from 'components/content-area';
import ContentContainer from 'components/content-container';
import StickyMenuOnTabs from 'components/sticky-menu-on-tabs';
import ContentWithSidebar from 'components/content-with-sidebar';
import EventData from './event-data';
import EventPageLink from './event-page-link';
import Link from 'components/link';
import RichText from 'components/rich-text';
import OptionsModal from 'components/options-modal';
import DateCardMedia from 'components/date-card/date-card-media';
import EventImage from 'components/event-image';
import Label from 'components/label';
import DatesMore from 'components/dates-more';

const EventPage = ({
  contactInfo,
  metadata,
  labels,
  links,
  onPageEditing,
  registrationLink,
  eventImage,
  richText,
  schedule,
  speakers,
  title,
  share,
  date,
  media,
  menu,
  moreDates,
  eventTag
}) => {
  return (
    <div className="event-page">
      <ContentContainer
        theme={ContentContainer.themes.wide}
        className="event-page--header"
      >
        <div className="event-page--header-left">
          <div className="event-page--image-container">
            <EventImage className="event-page--event-image" {...eventImage} />
          </div>
        </div>
        <div className="event-page--header-text">
          {eventTag && <div className="event-page--header-tag">{eventTag}</div>}
          {title && (
            <h1
              data-epi-type="title"
              className="event-page--title"
              {...OPE(onPageEditing.title)}
            >
              {title}
            </h1>
          )}
          <div className="event-page--header-content" data-epi-type="content">
            <EventData {...metadata} />
            <div className="event-page--date-container">
              {date && (
                <time 
                  className="event-page--date-container-date"
                  datetime={date}
                  >{date}</time>
              )}
              {moreDates && (
                <time 
                  className="event-page--date-container-dates-more-container"
                  datetime={date}
                  >
                  <DatesMore
                    className="event-page--date-container-dates-more"
                    {...moreDates}
                  />
                </time>
              )}
            </div>
            <DateCardMedia className="event-page--header-media" {...media} />
            <div className="event-page--header-registration">
              <Link
                theme={[Button.themes.primary, Button.themes.big]}
                useButtonStyles={true}
                {...registrationLink}
              />
            </div>
          </div>
        </div>
      </ContentContainer>
      <ContentContainer
        theme={ContentContainer.themes.wide}
        className="event-page--content-container"
      >
        <ContentWithSidebar
          className="event-page--content-with-sidebar"
          useContentContainer={false}
        >
          <ContentWithSidebar.Sidebar className="event-page--sidebar">
            <StickyMenuOnTabs {...menu} className="event-page--menu" />
          </ContentWithSidebar.Sidebar>
          <ContentWithSidebar.Content className="event-page--content">
            <div className="event-page--links">
              {links.map(link => (
                <EventPageLink key={link.url} {...link} />
              ))}
              {share && (
                <OptionsModal
                  theme={OptionsModal.themes.isProposalOrEvent}
                  {...share}
                />
              )}
            </div>
            {richText && (
              <div className="event-page--text" data-epi-type="content">
                {labels.about && <Label {...labels.about} />}
                <RichText {...richText} />
              </div>
            )}
            {speakers && (
              <div className="event-page--speakers" data-epi-type="content">
                {labels.speakers && <Label {...labels.speakers} />}
                <ContentArea {...speakers} />
              </div>
            )}
            {schedule && (
              <div className="event-page--schedule" data-epi-type="content">
                {labels.schedule && <Label {...labels.schedule} />}
                <ContentArea
                  className="event-page--schedule-content"
                  {...schedule}
                />
              </div>
            )}
            {contactInfo && (
              <div className="event-page--contact" id={labels.contact.htmlId}>
                <ContentArea
                  enableElementSizing={false}
                  theme={ContentArea.themes.twoColumns}
                  {...contactInfo}
                />
              </div>
            )}
          </ContentWithSidebar.Content>
        </ContentWithSidebar>
      </ContentContainer>
    </div>
  );
};

EventPage.propTypes = {
  contactInfo: PropTypes.exact(ContentArea.propTypes),
  labels: PropTypes.exact({
    about: PropTypes.exact(Label.propTypes),
    contact: PropTypes.exact(Label.propTypes),
    speakers: PropTypes.exact(Label.propTypes),
    schedule: PropTypes.exact(Label.propTypes)
  }),
  links: PropTypes.arrayOf(PropTypes.exact(EventPageLink.propTypes)),
  metadata: PropTypes.exact(EventData.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),
  speakers: PropTypes.exact(ContentArea.propTypes),
  richText: PropTypes.exact(RichText.propTypes),
  registrationLink: PropTypes.exact(Link.propTypes),
  schedule: PropTypes.exact(ContentArea.propTypes),
  title: PropTypes.string,
  share: PropTypes.exact(OptionsModal.propTypes),
  eventImage: PropTypes.exact(EventImage.propTypes),
  media: PropTypes.exact(DateCardMedia.propTypes),
  menu: PropTypes.exact(StickyMenuOnTabs.propTypes),
  moreDates: PropTypes.exact(DatesMore.propTypes),
  date: PropTypes.string,
  eventTag: PropTypes.string
};

EventPage.defaultProps = {
  labels: {},
  links: [],
  onPageEditing: {},
  dateContainer: {},
  eventImage: {}
};

export default EventPage;
