import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import useSlider from 'js/hooks/use-slider';
import useIsScreenSize from 'js/hooks/use-is-screen-size';

const VolumeSlider = ({ volume, setVolume, close }) => {
  const [isCursorInside, setIsCursorInside] = useState(true);
  const [markerPosition, setMarkerPosition] = useState(0);

  const trackRef = useRef();

  const {
    value,
    activate,
    deactivate,
    isActive,
    activateKeys,
    deactivateKeys
  } = useSlider(trackRef, volume, true);

  const isMobile = useIsScreenSize('lg') === false;
  useEffect(() => isMobile && activate(), [isMobile]);

  useEffect(() => {
    const ratio = trackRef.current.offsetHeight / 1;
    const OffsetBottomPosition = volume * ratio;
    setMarkerPosition(OffsetBottomPosition);
  }, [volume]);

  useEffect(() => {
    if (!isActive && !isCursorInside) close();
  }, [isActive, isCursorInside]);

  useEffect(() => value && setVolume(value), [value]);

  useEffect(() => {
    activateKeys();
    return () => deactivateKeys();
  }, []);

  return (
    <div
      className="volume-slider"
      onMouseDown={activate}
      onMouseUp={deactivate}
      onMouseEnter={() => setIsCursorInside(true)}
      onMouseLeave={() => setIsCursorInside(false)}
    >
      <div className="volume-slider--track" ref={trackRef}>
        <span
          className="volume-slider--marker"
          style={{
            transform: `translate(-50%,calc(-${markerPosition}px + 50%))`
          }}
        />
      </div>
    </div>
  );
};

VolumeSlider.propTypes = {
  volume: PropTypes.number,
  setVolume: PropTypes.func,
  close: PropTypes.func
};

VolumeSlider.propTypesMeta = 'exclude';

export default VolumeSlider;
