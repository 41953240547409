import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableMobileSort from './table-mobile-sort';
import TableMobileItem from './table-mobile-item';
import FlipMotion from 'react-flip-motion';
import sort from 'js/utils/sort-table';

const extractValue = (x, sortOption) => {
  const item = x.list.find(({ label }) => label === sortOption);
  const value = item && item.value;

  return value;
};

const TableMobile = ({ items, sortMenu }) => {
  if (!items || !items.length || !items[0]) return null;
  const [sortOption, setSortOption] = useState(items[0].list[0].label);
  const [itemsState, setitemsState] = useState(items);

  useEffect(() => {
    if (!sortOption) return;
    const newItems = JSON.parse(JSON.stringify(itemsState));
    newItems.sort((a, b) =>
      sort(extractValue(a, sortOption), extractValue(b, sortOption))
    );
    setitemsState(newItems);
  }, [sortOption]);

  const sortOptions = items[0].list.map(({ label }) => label);

  return (
    <div className="table-mobile">
      <TableMobileSort
        options={sortOptions}
        selectedOption={sortOption}
        setSortOption={setSortOption}
        {...sortMenu}
      />
      <FlipMotion>
        {itemsState.map(item => (
          <TableMobileItem {...item} key={item.list[0].value} />
        ))}
      </FlipMotion>
    </div>
  );
};

TableMobile.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact(TableMobileItem.propTypes)),
  sortMenu: PropTypes.exact(TableMobileSort.propTypes)
};

export default TableMobile;
