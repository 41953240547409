import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';

const StickyMenuOnTabsItem = ({ link }) => {
  return (
    <li className="sticky-menu-on-tabs-item">
      <Link {...link} theme={Link.themes.actionLink} />
    </li>
  );
};

StickyMenuOnTabsItem.propTypes = {
  link: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string
  })
};

export default StickyMenuOnTabsItem;
