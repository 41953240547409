import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components-old/button';
import CheckboxGroup from 'components-old/checkbox-group';
import Form from 'components-old/form';
import Link from 'components-old/link';
import NewsletterAdministrationLayout from 'components-old/newsletter-administration-layout';
import TextInput from 'components-old/form-elements/text-input';
import Validation from 'components-old/validation';

const NewsletterAdminPage = ({
  checkboxGroups,
  form,
  inputFieldsTitle,
  inputFieldsItems,
  layout,
  submitButton,
  title,
  unsubscribeLink,
  validation
}) => (
  <NewsletterAdministrationLayout {...layout} className="newsletter-admin-page">
    <Form {...form} showSubmitButton={false}>
      {title && <h1>{title}</h1>}
      {validation && <Validation {...validation} />}
      <div className="newsletter-admin-page--input-fields">
        {inputFieldsTitle && <h2>{inputFieldsTitle}</h2>}
        {inputFieldsItems.map(input => (
          <TextInput
            className="newsletter-admin-page--text-input"
            key={input.name}
            type="text"
            {...input}
          />
        ))}
      </div>
      {checkboxGroups.map(checkboxGroup => (
        <CheckboxGroup key={checkboxGroup.title} {...checkboxGroup} />
      ))}
      <div className="newsletter-admin-page--button-wrapper">
        <Button
          theme={[Button.themes.big, Button.themes.fill]}
          type="submit"
          {...submitButton}
        />
        <Link
          className="newsletter-admin-page--unsubscribe-link"
          theme={Link.themes.underline}
          {...unsubscribeLink}
        />
      </div>
    </Form>
  </NewsletterAdministrationLayout>
);

NewsletterAdminPage.propTypes = {
  checkboxGroups: PropTypes.arrayOf(PropTypes.exact(CheckboxGroup.propTypes)),
  form: PropTypes.exact(Form.propTypes),
  inputFieldsTitle: PropTypes.string,
  inputFieldsItems: PropTypes.arrayOf(PropTypes.exact(TextInput.propTypes)),
  layout: PropTypes.exact(NewsletterAdministrationLayout.propTypes),
  submitButton: PropTypes.exact(Button.propTypes),
  title: PropTypes.string,
  unsubscribeLink: PropTypes.exact(Link.propTypes),
  validation: PropTypes.exact(Validation.propTypes)
};

NewsletterAdminPage.defaultProps = {
  checkboxGroups: [],
  inputFieldsItems: []
};

export default NewsletterAdminPage;
