import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import propTypeTheme from 'utils/prop-type-theme';

import Icon from 'components-old/icon';
import InputControlled from './input-controlled';
import InputUncontrolled from './input-uncontrolled';

const themes = {
  gray: '-theme-gray'
};

const Checkbox = ({
  className,
  checked = false,
  label,
  name,
  onChange,
  theme,
  value,
  dataId,
  isControlled
}) => {
  const id = `checkbox-${name}-${label.replace(/ /g, '_')}`;
  const Input = isControlled ? InputControlled : InputUncontrolled;

  return (
    <div className={cn('checkbox', theme, className)}>
      <Input
        id={id}
        name={name}
        label={label}
        onChange={onChange}
        value={value}
        checked={checked}
        dataId={dataId}
      />
      <label htmlFor={id}>
        <span>{label}</span>
        <Icon fill className="checkbox--checkmark" name="checkmark" />
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  theme: propTypeTheme(themes),
  value: PropTypes.string,
  isControlled: PropTypes.bool,
  dataId: PropTypes.number
};

Checkbox.defaultProps = {
  label: '',
  theme: themes.gray
};

Checkbox.propTypesMeta = {
  className: 'exclude',
  theme: 'exclude',
  isControlled: 'exclude',
  dataId: 'exclude'
};

Checkbox.themes = themes;

export default Checkbox;
