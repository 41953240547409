import analytics from './analytics';
import toQueryString from '@creuna/utils/to-query-string';

const defaultFetchOptions = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
};

function parseResponse(response) {
  return response.json().then(json => ({ json, response }));
}

function handleUserMessages({ json, response }) {
  return { json, response };
}

function handleAnalytics({ json, response }) {
  if (json.analytics) {
    analytics.send(json.analytics);
  }

  return { json, response };
}

function handleNotOk({ json, response }) {
  // NOTE: `response.ok` is true when the returned status is in the inclusive range 200-299.
  if (!response.ok && !json.messageToUser) {
    const error = new Error(response.statusText);

    error.name = `${response.status} on ${response.url}`;

    throw error;
  }

  return { json, response };
}

function handleResponse({ json }) {
  return json.payload || json;
}

function handleFetchError(error) {
  return Promise.reject(error);
}

function request(url, options) {
  return fetch(url, options)
    .then(parseResponse)
    .then(handleUserMessages)
    .then(handleAnalytics)
    .then(handleNotOk)
    .then(handleResponse)
    .catch(handleFetchError);
}

function post(endpoint, data) {
  return request(
    endpoint,
    Object.assign({}, defaultFetchOptions, {
      body: data,
      method: 'post'
    })
  );
}

function get(endpoint, data) {
  const queryString = data ? toQueryString(data) : '';

  return request(endpoint + queryString, {
    ...defaultFetchOptions,
    method: 'get'
  });
}

function execute(endpoint = '', data, method) {
  if (endpoint.indexOf('/static-site/api') !== -1) {
    // eslint-disable-next-line no-console
    console.log('Requesting mock data from', endpoint, data);
    return new Promise(resolve => {
      setTimeout(() => {
        if (method === 'get') {
          resolve(get(endpoint, data));
        } else {
          return resolve(request(endpoint, defaultFetchOptions));
        }
      }, 1000);
    });
  }

  if (method === 'get') {
    return get(endpoint, data);
  } else {
    return post(endpoint, JSON.stringify(data));
  }
}

export default {
  execute,
  get
};
