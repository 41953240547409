/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import ContentContainer from 'components-old/content-container';

const themes = {
  sidebarLeft: '-theme-sidebar-left'
};

const ContentWithSidebar = ({
  children,
  className,
  theme,
  useContentContainer
}) => {
  const WrapperElement = useContentContainer
    ? ContentContainer
    : React.Fragment;

  return (
    <WrapperElement>
      <div className={cn('content-with-sidebar', theme, className)}>
        {children}
      </div>
    </WrapperElement>
  );
};

ContentWithSidebar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  theme: PropTypes.string,
  useContentContainer: PropTypes.bool
};

ContentWithSidebar.propTypesMeta = 'exclude';

ContentWithSidebar.defaultProps = {
  useContentContainer: true
};

ContentWithSidebar.themes = themes;

ContentWithSidebar.Content = ({ className, children }) => (
  <div className={cn('content-with-sidebar--content', className)}>
    {children}
  </div>
);

ContentWithSidebar.Content.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

ContentWithSidebar.Content.displayName = 'ContentWithSidebar.Content';

ContentWithSidebar.Sidebar = ({ className, children }) => (
  <aside className={cn('content-with-sidebar--sidebar', className)}>
    {children}
  </aside>
);

ContentWithSidebar.Sidebar.displayName = 'ContentWithSidebar.Sidebar';

ContentWithSidebar.Sidebar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default ContentWithSidebar;
