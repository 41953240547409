import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import MessageGlobal from 'components-old/message-global';
import {
  getKeysFromLocalStorageThatMatchesPrefix,
  deleteFromLocalStorage
} from 'js/utils/local-storage';
import Message from 'components-old/message';
import ContentContainer from 'components-old/content-container';

const MessageGlobalList = ({
  messageGlobals = [],
  prefix,
  printDescription,
  defaultPrintMessage
}) => {
  const [globalMessageContainer, setGlobalMessageContainer] = useState(null);

  useEffect(() => {
    let keysFromLocalStorage = getKeysFromLocalStorageThatMatchesPrefix(prefix);
    deleteFromLocalStorage(keysFromLocalStorage, 7, 'DAYS');

    const div = document.createElement('div');
    const main = document.getElementById('main');
    main.parentElement.insertBefore(div, main);
    setGlobalMessageContainer(div);
  }, []);

  return (
    <div className="message-global-list">
      {globalMessageContainer &&
        createPortal(
          messageGlobals.map((messageGlobal, index) => (
            <MessageGlobal
              key={messageGlobal.message.text.text}
              {...messageGlobal}
              zIndex={40 - index}
              style={{ marginTop: `${80 * index}px` }}
            />
          )),
          globalMessageContainer
        )}
      <div className="message-global-list--print">
        <ContentContainer>
          <div className="message-global-list--print-line" />
          {printDescription && (
            <p className="message-global-list--print-description">
              {printDescription}
            </p>
          )}
          {messageGlobals.length ? (
            messageGlobals.map(({ message }) => (
              <div
                className="message-global-list--print-message"
                key={message.text.text}
              >
                <Message {...message} />
              </div>
            ))
          ) : (
            <div
              className="message-global-list--print-message"
              key={defaultPrintMessage}
            >
              <Message
                text={{ text: defaultPrintMessage }}
                theme={Message.themes.gray}
              />
            </div>
          )}
        </ContentContainer>
      </div>
    </div>
  );
};

MessageGlobalList.propTypes = {
  messageGlobals: PropTypes.arrayOf(PropTypes.exact(MessageGlobal.propTypes)),
  prefix: PropTypes.string,
  printDescription: PropTypes.string,
  defaultPrintMessage: PropTypes.string
};

export default MessageGlobalList;
