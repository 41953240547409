import React from 'react';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import useOnIntersect from 'js/hooks/use-on-intersect';
import OPE from 'js/on-page-editing';

const EmbedBlock = ({ height, onPageEditing, src, title, width }) => {
  const wrapper = useRef();
  const hasDimensions = height && width;
  const [minHeight, setMinHeight] = useState(0);
  const [srcState, setSrcState] = useState(undefined);

  useOnIntersect(wrapper, () => setSrcState(src));

  useEffect(() => {
    setMinHeight(
      hasDimensions ? 0 : (9 / 16) * (wrapper.current.offsetWidth || 0)
    );
  });

  const wrapperClass = cn('embed-block', { '-has-dimensions': hasDimensions });

  return (
    <div className={wrapperClass} ref={wrapper} {...OPE(onPageEditing)}>
      <iframe
        allowFullScreen
        src={srcState}
        height={height}
        width={width}
        title={title}
        style={{ minHeight }}
      />
    </div>
  );
};

EmbedBlock.propTypes = {
  height: PropTypes.number,
  onPageEditing: PropTypes.string,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.number
};

EmbedBlock.propTypesMeta = {
  height: 'int?',
  width: 'int?'
};

export default EmbedBlock;
