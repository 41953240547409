import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const AutoGridItem = style => {
  const GridItem = ({ children, className }) => {
    return (
      <li className={cn('auto-grid__item', className)} style={style}>
        {children}
      </li>
    );
  };

  GridItem.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
  };

  return GridItem;
};

AutoGridItem.propTypes = 'exclude';

export default AutoGridItem;
