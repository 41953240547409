import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Button from 'components-old/button';
import FluidImage from 'components-old/fluid-image';
import Link from 'components-old/link';
import Ref from 'components-old/ref';
import useIsScreenSize from 'js/hooks/use-is-screen-size';
import Heading from 'components-old/heading/heading';

const themes = {
  darkBlue: '-theme-dark-blue'
};

const CampaignBlock = ({
  cta,
  editorTheme,
  image,
  onPageEditing,
  title,
  isColumn
}) => {
  const [lowerHalfHeight, setLowerHalfHeight] = useState();
  const isMobile = !useIsScreenSize('md');
  const inlineStyle =
    isColumn || isMobile ? { height: `${lowerHalfHeight}px` } : undefined;

  return (
    <div
      className={cn('campaign-block', editorTheme, { '-is-column': isColumn })}
    >
      {image && (
        <div className="campaign-block--image" style={inlineStyle}>
          <FluidImage {...image} />
        </div>
      )}
      <Ref
        className="campaign-block--text"
        refValue={setLowerHalfHeight}
        refKey={'offsetHeight'}
      >
        {title && (
          <Heading
            className="campaign-block--title"
            onPageEditing={onPageEditing.title}
          >
            {title}
          </Heading>
        )}
        <Link
          theme={
            editorTheme === themes.darkBlue
              ? [Button.themes.orangeOutline, Button.themes.white]
              : [Button.themes.fill]
          }
          useButtonStyles
          {...cta}
        />
      </Ref>
    </div>
  );
};

CampaignBlock.propTypes = {
  cta: PropTypes.exact(Link.propTypes),
  editorTheme: PropTypes.oneOf(Object.values(themes)),
  image: PropTypes.exact(FluidImage.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),
  title: PropTypes.string.isRequired,
  isColumn: PropTypes.bool
};

CampaignBlock.defaultProps = {
  onPageEditing: {}
};

CampaignBlock.propTypesMeta = {
  isColumn: 'exclude'
};

export default CampaignBlock;
