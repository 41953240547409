import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Accordion from 'components-old/accordion/accordion';
import Icon from 'components-old/icon/icon';
import Button from 'components-old/button';

const TableMobileSort = ({
  options,
  selectedOption,
  setSortOption,
  title,
  accordion
}) => {
  return (
    <Accordion {...accordion} animate={false} buttonTextForLabel={title}>
      {({ Button: AccordionButton, Collapse, isOpen, toggle }) => (
        <div className="table-mobile-sort">
          <div className="table-mobile-sort--button" onClick={toggle}>
            {selectedOption}
            <AccordionButton>
              <Icon name="sort-down" fill />
            </AccordionButton>
          </div>
          <Collapse className="table-mobile-sort--collapse">
            <div className="table-mobile-sort--dropdown">
              <h4 className="table-mobile-sort--dropdown-title">{title}</h4>
              <ul className="table-mobile-sort--dropdown-list">
                {options.map(option => (
                  <li className="table-mobile-sort--dropdown-item" key={option}>
                    <Button
                      className={cn({
                        'table-mobile-sort--button -center-text':
                          option === selectedOption
                      })}
                      theme={
                        option === selectedOption
                          ? Button.themes.fill
                          : undefined
                      }
                      onClick={() => {
                        setSortOption(option);
                        toggle();
                      }}
                    >
                      {option}
                    </Button>
                  </li>
                ))}
              </ul>
            </div>
          </Collapse>
          {isOpen && (
            <button className="table-mobile-sort--overlay" onClick={toggle} />
          )}
        </div>
      )}
    </Accordion>
  );
};
TableMobileSort.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  setSortOption: PropTypes.func,
  selectedOption: PropTypes.string,
  accordion: PropTypes.exact(Accordion.propTypes),
  title: PropTypes.string
};

TableMobileSort.propTypesMeta = {
  options: 'exclude',
  selectedOption: 'exclude',
  setSortOption: 'exclude'
};

export default TableMobileSort;
