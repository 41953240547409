import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import ContentContainer from 'components/content-container';
import useOnIntersect from 'js/hooks/use-on-intersect';

const ResponsiveIframe = ({ src, title }) => {
  const [height, setHeight] = useState(700);
  const [srcState, setSrcState] = useState(undefined);
  const iframeRef = useRef();

  useOnIntersect(iframeRef, () => setSrcState(src));

  const getHeight = event => {
    setHeight(event.currentTarget.contentWindow.document.body.scrollHeight);
  };

  return (
    <ContentContainer className="responsive-iframe">
      <iframe
        className="responsive-iframe--iframe"
        onLoad={getHeight}
        src={srcState}
        title={title}
        height={`${height}px`}
        ref={iframeRef}
      />
    </ContentContainer>
  );
};

ResponsiveIframe.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default ResponsiveIframe;
