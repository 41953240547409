import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Link from 'components-old/link';
import NavigationList from './navigation-list';
import BackgroundHoverEffect from 'components-old/background-hover-effect';

const NavigationGroup = ({ items, title }) => {
  return (
    <div className="navigation-group">
      {title && <h2>{title}</h2>}
      <ul>
        {items.map(({ link, linkList }, index) => {
          if (link) {
            const linkRef = useRef();
            return (
              <li key={link.url + String(index)} ref={linkRef}>
                <BackgroundHoverEffect
                  theme={BackgroundHoverEffect.themes.menu}
                  parentToCover={linkRef.current}
                >
                  <Link
                    {...link}
                    theme={[Link.themes.white, Link.themes.fullWidth]}
                  />
                </BackgroundHoverEffect>
              </li>
            );
          }

          if (linkList) {
            const linkListRef = useRef();
            return (
              <li key={linkList.title + String(index)} ref={linkListRef}>
                <NavigationList {...linkList} parent={linkListRef.current} />
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
};

NavigationGroup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      link: PropTypes.exact(Link.propTypes),
      linkList: PropTypes.exact(NavigationList.propTypes)
    })
  ),
  title: PropTypes.string.isRequired
};

NavigationGroup.defaultProps = {
  items: []
};

export default NavigationGroup;
