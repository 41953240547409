/* HOC for separating logic for IE.
   Passes function to the Component argument used for executing a function only for IE browser
*/
import React, { useState, useEffect } from 'react';

const IEWrapper = Comp => props => {
  const [isDOMLoaded, setIsDOMLoaded] = useState(false);

  useEffect(() => setIsDOMLoaded(true), []);

  const isIE = () => window.document.documentMode;

  return isDOMLoaded ? <Comp isIEBrowser={isIE()} {...props} /> : null;
};

export default IEWrapper;
