import React from 'react';
import PropTypes from 'prop-types';

import Form from 'components-old/form';
import SearchInput from 'components-old/form-elements/search-input';

const MenuSearch = ({ form, input, submitLabel }) => (
  <Form className="menu-search" showSubmitButton={false} {...form}>
    <SearchInput
      idPrefix="menu-search"
      theme={SearchInput.themes.inverted}
      {...input}
    />
    <button className="menu-search--submit" type="submit">
      {submitLabel}
    </button>
  </Form>
);

MenuSearch.propTypes = {
  form: PropTypes.exact(Form.propTypes),
  input: PropTypes.exact(SearchInput.propTypes),
  submitLabel: PropTypes.string
};

export default MenuSearch;
