import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import transform from 'css-transform-string';

import clamp from '@creuna/utils/clamp';

class Carousel extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    nextLabel: PropTypes.string,
    nextButton: PropTypes.func,
    numberOfSlidesToShow: PropTypes.number,
    previousButton: PropTypes.func,
    previousLabel: PropTypes.string,
    startIndex: PropTypes.number,
    theme: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  };

  static defaultProps = {
    numberOfSlidesToShow: 1,
    startIndex: 0
  };

  static propTypesMeta = {
    className: 'exclude',
    numberOfSlidesToShow: 'exclude',
    startIndex: 'exclude',
    theme: 'exclude'
  };

  getNewState = newIndex => {
    const numberOfItems = React.Children.count(this.props.children);
    const slidesToShow = this.props.numberOfSlidesToShow;
    const lastIndex = Math.round(Math.max(0, numberOfItems - slidesToShow));
    const newIndexClamped = clamp(newIndex, 0, lastIndex);

    return {
      currentIndex: newIndexClamped,
      currentIndexRounded: Math.round(newIndexClamped),
      hasNextItem: newIndexClamped < lastIndex,
      hasPreviousItem: newIndexClamped > 0
    };
  };

  state = Object.assign(
    {
      isDragging: false,
      isMounted: false,
      shouldShowNavigation: React.Children.count(this.props.children) > 1
    },
    this.getNewState(this.props.startIndex)
  );

  onTouchEnd = startPropName => {
    this.goToItem(this.state.currentIndex);
    this[startPropName] = undefined;
    this.travel = 0;
    this.previousX = 0;
  };

  onTouchMove = (e, touch, startPropName, wrapperWidth, travelModifier = 1) => {
    this.isZoomed = Math.abs(window.innerWidth - this.initialScreenWidth) > 10;

    if (this.isAnimating || this.isZoomed || this.hasMultiTouch || !touch) {
      return;
    }

    const xDiff = this[startPropName] - touch.clientX;

    if (Math.abs(xDiff) > 5) {
      e.preventDefault();
    }

    if (Math.abs(touch.clientX - this.previousX) > 20) {
      this.hasFastSwipe = true;
      this.fastSwipeModifier = xDiff > 0 ? 1 : -1;
    }

    this.previousX = touch.clientX;
    this.travel = this.hasFastSwipe
      ? this.fastSwipeModifier
      : (xDiff / wrapperWidth) * travelModifier;
    this.isAnimating = true;

    this.setState({ isDragging: !this.hasFastSwipe }, () => {
      this.setState(
        this.getNewState(this.indexOnTouchStart + this.travel),
        () => {
          this.isAnimating = false;
        }
      );
    });
  };

  onTouchStart = (touch, startPropName) => {
    if (!touch) {
      return;
    }

    this[startPropName] = touch.clientX;
    this.fastSwipeModifier = 0;
    this.hasFastSwipe = false;
    this.indexOnTouchStart = this.state.currentIndex;
    this.previousX = touch.clientX;
    this.travel = 0;
    this.setState({ isDragging: true });
  };

  onItemsWrapperTouchEnd = () => {
    this.onTouchEnd('itemsTouchStartX');
  };

  onItemsWrapperTouchMove = e => {
    this.hasMultiTouch = e.touches.length > 1;

    this.onTouchMove(
      e,
      e.touches[0],
      'itemsTouchStartX',
      this.itemsWrapper.offsetWidth
    );
  };

  onItemsWrapperTouchStart = e => {
    this.onTouchStart(e.touches[0], 'itemsTouchStartX');
  };

  componentDidUpdate(prevProps) {
    const children = React.Children.toArray(this.props.children);
    const prevChildren = React.Children.toArray(prevProps.children);
    const childrenDidChange =
      children.length !== prevChildren.length ||
      children.some(
        (c, i) => !prevChildren[i] || c.key !== prevChildren[i].key
      );

    if (
      childrenDidChange ||
      this.props.numberOfSlidesToShow !== prevProps.numberOfSlidesToShow
    ) {
      this.setState(this.getNewState(prevProps.startIndex));
    }
  }

  componentDidMount() {
    this.setState({ isMounted: true });

    this.initialScreenWidth = window.innerWidth;

    if (this.itemsWrapper) {
      this.itemsWrapper.addEventListener(
        'touchend',
        this.onItemsWrapperTouchEnd
      );
      this.itemsWrapper.addEventListener(
        'touchmove',
        this.onItemsWrapperTouchMove
      );
      this.itemsWrapper.addEventListener(
        'touchstart',
        this.onItemsWrapperTouchStart
      );
    }
  }

  componentWillUnmount() {
    if (this.itemsWrapper) {
      this.itemsWrapper.removeEventListener(
        'touchend',
        this.onItemsWrapperTouchEnd
      );
      this.itemsWrapper.removeEventListener(
        'touchmove',
        this.onItemsWrapperMove
      );
      this.itemsWrapper.removeEventListener(
        'touchstart',
        this.onItemsWrapperStart
      );
    }
  }

  goToItem = newIndex => {
    this.setState(
      Object.assign({}, this.getNewState(Math.round(newIndex)), {
        isDragging: false
      })
    );
  };

  goToNextItem = () => {
    this.goToItem(this.state.currentIndex + 1);
  };

  goToPreviousItem = () => {
    this.goToItem(this.state.currentIndex - 1);
  };

  handleOnItemFocus = index => {
    // Scroll item into view if outside
    this.itemsWrapper.scroll({ left: 0 }); // Prevents automatic scroll-focus-target-to-view
    const isTargetOutsideView =
      index >= this.state.currentIndex + this.props.numberOfSlidesToShow ||
      index < this.state.currentIndex;
    if (isTargetOutsideView) {
      this.setState(this.getNewState(index));
      // Manually scroll page in Y axis
      const distanceToTopOfPage =
        this.itemsWrapper.getBoundingClientRect().top +
        window.pageYOffset -
        window.innerHeight / 2;
      window.scrollTo(0, distanceToTopOfPage);
    }
  };

  render() {
    const slidesToShow = this.props.numberOfSlidesToShow;
    const slidesCount = React.Children.count(this.props.children);
    const currentSlide = this.state.currentIndexRounded;

    const x = this.state.currentIndex;
    const itemWidth = 100 / slidesCount;

    return slidesCount === 0 ? null : (
      <div
        className={cn('carousel', this.props.className, {
          'shows-multiple-slides': slidesToShow > 1
        })}
      >
        <div className="carousel-content">
          {this.state.shouldShowNavigation &&
            (this.props.previousButton ? (
              this.props.previousButton(
                this.goToPreviousItem,
                this.state.hasPreviousItem
              )
            ) : (
              <button
                className={cn('carousel-prev', this.props.theme)}
                disabled={!this.state.hasPreviousItem}
                key="carousel-prev"
                onClick={this.goToPreviousItem}
                type="button"
              >
                <span>{this.props.previousLabel}</span>
              </button>
            ))}
          <div
            className="carousel-items-wrapper"
            ref={e => (this.itemsWrapper = e)}
          >
            <ul
              className="carousel-items"
              style={
                this.state.isMounted
                  ? {
                      transform: transform({
                        x: `-${(itemWidth * x) / slidesToShow}%`
                      }),
                      transition: this.state.isDragging && 'none',
                      width: `${slidesCount * 100}%`
                    }
                  : null
              }
            >
              {React.Children.map(this.props.children, (child, index) => (
                <li
                  className={cn({ 'is-current': currentSlide === index })}
                  style={{ width: `${itemWidth / slidesToShow}%` }}
                  onFocus={() => this.handleOnItemFocus(index)}
                >
                  {child}
                </li>
              ))}
            </ul>
          </div>
          {this.state.shouldShowNavigation &&
            (this.props.nextButton ? (
              this.props.nextButton(this.goToNextItem, this.state.hasNextItem)
            ) : (
              <button
                className={cn('carousel-next', this.props.theme)}
                disabled={!this.state.hasNextItem}
                key="carousel-next"
                onClick={this.goToNextItem}
                type="button"
              >
                <span>{this.props.nextLabel}</span>
              </button>
            ))}
        </div>
      </div>
    );
  }
}

const themes = {
  green: 'theme-green',
  small: 'theme-small'
};

Carousel.themes = themes;

export default Carousel;
