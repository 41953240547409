import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ContentArea from 'components/content-area';
import Heading from 'components/heading';
import RichText from 'components/rich-text';
import OptionsModal from 'components/options-modal';

const TextWithSidebar = ({
  headingLevel,
  sidebar,
  text,
  title,
  htmlId,
  share,
  isOpen = true,
  isProposalPage = false
}) => (
  <div
    className={cn('text-with-sidebar', {
      '-is-proposal-page': isProposalPage
    })}
    useContentContainer={false}
  >
    <div className="text-with-sidebar--top-line" />
    <div className="text-with-sidebar--content">
      {(share && isOpen) && (
        <div className="text-with-sidebar--links">
          <OptionsModal
            theme={OptionsModal.themes.isTextWithSidebar}
            {...share}
          ></OptionsModal>
        </div>
      )}
      {title && (
        <Heading
          id={htmlId}
          className="text-with-sidebar--title"
          level={headingLevel}
        >
          {title}
        </Heading>
      )}
      <RichText {...text} />
    </div>
    {sidebar && (
      <div className="text-with-sidebar--sidebar">
        <ContentArea {...sidebar} />
      </div>
    )}
  </div>
);

TextWithSidebar.propTypes = {
  headingLevel: PropTypes.number,
  sidebar: PropTypes.exact(ContentArea.propTypes),
  text: PropTypes.exact(RichText.propTypes),
  title: PropTypes.string,
  htmlId: PropTypes.string,
  isProposalPage: PropTypes.bool,
  share: PropTypes.exact(OptionsModal.propTypes),
  isOpen: PropTypes.bool
};

TextWithSidebar.propTypesMeta = {
  headingLevel: 'exclude',
  isProposalPage: 'exclude',
  share: 'exclude',
  isOpen: 'exclude'
};

TextWithSidebar.defaultProps = {
  headingLevel: 2
};

export default TextWithSidebar;
