import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Accordion from 'components-old/accordion';
import Link from 'components-old/link';
import LinkList from 'components-old/link-list';
import BackgroundHoverEffect from 'components-old/background-hover-effect';

const NavigationList = ({ accordion, title, linkList, parent }) => {
  return (
    <Accordion {...accordion} buttonTextForLabel={title}>
      {({ Button, Collapse, isOpen }) => (
        <div className="navigation-list">
          <BackgroundHoverEffect
            parentToCover={parent}
            enable={!isOpen}
            theme={BackgroundHoverEffect.themes.menu}
          >
            <Button
              icon="small-arrow-down"
              className={cn('navigation-list__toggle', {
                '-is-active': isOpen
              })}
            >
              <span className="navigation-list__toggle-text">{title}</span>
            </Button>
          </BackgroundHoverEffect>
          <Collapse className="navigation-list__content">
            <LinkList
              {...linkList}
              linkTheme={[Link.themes.white, Link.themes.fullWidth]}
              theme={LinkList.themes.border}
            />
          </Collapse>
        </div>
      )}
    </Accordion>
  );
};

NavigationList.propTypes = {
  accordion: PropTypes.exact(Accordion.propTypes),
  title: PropTypes.string.isRequired,
  linkList: PropTypes.exact(LinkList.propTypes).isRequired,
  parent: PropTypes.oneOfType([PropTypes.node, PropTypes.object])
};

NavigationList.defaultProps = {
  linkList: []
};

NavigationList.propTypesMeta = {
  parent: 'exclude'
};

export default NavigationList;
