import React from 'react';
import PropTypes from 'prop-types';
import OPE from 'js/on-page-editing';
import Link from 'components/link';

const getDetailType = (details) => {
  return {
    regText: details.filter(x => !x.hasOwnProperty("url"))
      .map(elem => <Link {...elem} />),
    links: details.filter(x => x.hasOwnProperty("url"))
      .map(elem => <Link {...elem} />),
  }
}

const ContactInfo = ({ details, onPageEditing, title }) => (

  <div className="contact-info">
    <h3 {...OPE(onPageEditing.title)}>{title}</h3>
    {getDetailType(details).regText.length > 0 &&
      <ul className='reg-text-details'>
        {getDetailType(details).regText}
      </ul>
    }
    {getDetailType(details).links.length > 0 &&
      <ul className='link-details'>
        {getDetailType(details).links}
      </ul>
    }
  </div>
);

ContactInfo.propTypes = {
  details: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),
  title: PropTypes.string.isRequired
};

ContactInfo.propTypesMeta = {
  theme: 'exclude'
};

ContactInfo.defaultProps = {
  onPageEditing: {},
  details: []
};

export default ContactInfo;
