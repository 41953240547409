import React from 'react';
import PropTypes from 'prop-types';

import ContentContainer from 'components/content-container';
import HtmlString from 'components/html-string';
import Link from 'components/link';
import RichText from 'components/rich-text';
import SocialMediaLinkList from 'components/social-media-link-list';
import AccordionContainer from 'components/accordion-container';
import Accordion from 'components/accordion';
import Button from 'components/button';

const Footer = ({
  contactInfo,
  newsLetter,
  linkLists,
  socialMedia,
  logo,
  about,
  trademark
}) => (
  <footer className="footer">
    <ContentContainer className="footer--content">
      <div className="footer--top">
        <div className="footer--top-left">
          {logo && (
            <a href={logo.url} aria-label={logo.text} className="footer--logo">
              <img alt="Logo" src={logo.src} />
            </a>
          )}
          {about && <p className="footer--about">{about}</p>}
          {newsLetter && (
            <Link
              className="footer--newsletter-link"
              theme={[
                Button.themes.primary,
                Button.themes.large,
                Button.themes.light
              ]}
              useButtonStyles
              {...newsLetter}
            />
          )}
        </div>
        <div className="footer--top-right-mobile">
          {linkLists &&
            linkLists.map(({ title, links, id, accordion }) => (
              <div className="footer--link-list" key={id}>
                <AccordionContainer buttonText={title} accordion={accordion}>
                  {links && !!links.length && (
                    <ul>
                      {links.map(link => (
                        <li key={link.text}>
                          <Link theme={[Link.themes.white]} {...link} />
                        </li>
                      ))}
                    </ul>
                  )}
                </AccordionContainer>
              </div>
            ))}
        </div>
        <div className="footer--top-right-desktop">
          {linkLists &&
            linkLists.map(({ title, links, id }) => (
              <div className="footer--link-list" key={id}>
                {title && <h2>{title}</h2>}
                {links && !!links.length && (
                  <ul>
                    {links.map(link => (
                      <li key={link.text}>
                        <Link theme={[Link.themes.white]} {...link} />
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
        </div>
      </div>
      {contactInfo && (
        <div className="footer--contact-info">
          <RichText
            {...contactInfo}
            additionalComponentProps={{
              HtmlString: { theme: HtmlString.themes.white }
            }}
          />
        </div>
      )}
      <div className="footer--bottom">
        <div>{trademark && trademark}</div>
        <SocialMediaLinkList
          className="footer--bottom-some-links"
          theme={SocialMediaLinkList.themes.white}
          {...socialMedia}
        />
      </div>
    </ContentContainer>
  </footer>
);

Footer.propTypes = {
  contactInfo: PropTypes.exact(RichText.propTypes),
  newsLetter: PropTypes.exact(Link.propTypes),
  linkLists: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number,
      title: PropTypes.string,
      links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
      accordion: PropTypes.exact(Accordion.propTypes)
    })
  ),
  socialMedia: PropTypes.exact(SocialMediaLinkList.propTypes),
  logo: PropTypes.exact({
    url: PropTypes.string,
    text: PropTypes.string,
    src: PropTypes.string
  }),
  about: PropTypes.string,
  trademark: PropTypes.string
};

export default Footer;
