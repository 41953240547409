import React from 'react';
import PropTypes from 'prop-types';

import NewsletterAdministrationLayout from 'components/newsletter-administration-layout';

const NewsletterUpdatedPage = ({
  categoriesTitle,
  categoriesList,
  emailTitle,
  emailText,
  layout,
  title
}) => (
  <NewsletterAdministrationLayout
    {...layout}
    className="newsletter-updated-page"
  >
    {title && <h1>{title}</h1>}
    <div className="newsletter-updated-page--section">
      {emailTitle && <h2>{emailTitle}</h2>}
      <p>{emailText}</p>
    </div>
    <div className="newsletter-updated-page--section">
      {categoriesTitle && <h2>{categoriesTitle}</h2>}
      <ul>
        {categoriesList.map(category => (
          <React.Fragment key={category.name}>
            <li>
              {category.name}
              {category.description && <div>{category.description}</div>}
            </li>
          </React.Fragment>
        ))}
      </ul>
    </div>
  </NewsletterAdministrationLayout>
);

NewsletterUpdatedPage.propTypes = {
  categoriesTitle: PropTypes.string,
  categoriesList: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      description: PropTypes.string
    })
  ),
  emailTitle: PropTypes.string,
  emailText: PropTypes.string,
  layout: PropTypes.exact(NewsletterAdministrationLayout.propTypes),
  title: PropTypes.string
};

NewsletterUpdatedPage.defaultProps = {
  categoriesList: []
};

export default NewsletterUpdatedPage;
