import React from 'react';
import PropTypes from 'prop-types';

import ContentWithSidebar from 'components/content-with-sidebar';
import EmptyList from 'components/empty-list';
import FilterLayout from 'components/filter-layout';
import Form from 'components/form';
import Pagination from 'components/pagination';
import ArticleBlock from 'components/article-block';
import Spinner from 'components/spinner';
import useFetchFilteredResults from 'js/hooks/use-fetch-filtered-results';
import CategoryListItem from 'components/category-list-item';

const CategoryListPage = ({
  emptyList,
  filterLayout,
  form,
  groups,
  title,
  pagination,
  fetchFilteredResultsEndpoint
}) => {
  const [
    isLoading,
    {
      emptyList: emptyListState,
      filterLayout: filterLayoutState,
      pagination: paginationState,
      groups: groupsState
    },
    fetchResults
  ] = useFetchFilteredResults(
    { emptyList, filterLayout, pagination, groups },
    fetchFilteredResultsEndpoint,
    form.endpoint
  );

  return (
    <Form className="category-list-page" showSubmitButton={false} {...form}>
      <ContentWithSidebar>
        {title && <h1 className="category-list-page--title">{title}</h1>}
      </ContentWithSidebar>
      <FilterLayout
        {...filterLayoutState}
        fetchFilteredResults={fetchResults}
        isLoading={isLoading}
      >
        <React.Fragment>
          {groupsState && groupsState.length > 0 ? (
            groupsState.map((group, index) => (
              <CategoryListItem
                key={group.cardData && group.cardData.title + index}
                {...group}
              />
            ))
          ) : (
            <EmptyList {...emptyListState} />
          )}
          <Spinner isActive={isLoading} />
        </React.Fragment>
        <Pagination {...paginationState} />
      </FilterLayout>
    </Form>
  );
};

CategoryListPage.propTypes = {
  emptyList: PropTypes.exact(EmptyList.propTypes),
  filterLayout: PropTypes.exact(FilterLayout.propTypes),
  form: PropTypes.exact(Form.propTypes),
  groups: PropTypes.arrayOf(PropTypes.exact(CategoryListItem.propTypes)),
  title: PropTypes.string,
  pagination: PropTypes.exact(Pagination.propTypes),
  fetchFilteredResultsEndpoint: PropTypes.string
};

CategoryListPage.defaultProps = {
  groups: []
};

export default CategoryListPage;
