import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Accordion from 'components/accordion';
import Link from 'components/link';
import LinkList from 'components/link-list';

const NavigationList = ({ accordion, title, linkList }) => {
  return (
    <Accordion {...accordion} buttonTextForLabel={title}>
      {({ Button, Collapse, isOpen }) => (
        <div className="navigation-list">
          <Button
            icon="arrow-down"
            className={cn('navigation-list__toggle', {
              '-is-active': isOpen
            })}
          >
            <span className="navigation-list__toggle-text">{title}</span>
          </Button>
          <Collapse className="navigation-list__content">
            <LinkList {...linkList} />
          </Collapse>
        </div>
      )}
    </Accordion>
  );
};

NavigationList.propTypes = {
  accordion: PropTypes.exact(Accordion.propTypes),
  title: PropTypes.string.isRequired,
  linkList: PropTypes.exact(LinkList.propTypes).isRequired,
  parent: PropTypes.oneOfType([PropTypes.node, PropTypes.object])
};

NavigationList.defaultProps = {
  linkList: []
};

NavigationList.propTypesMeta = {
  parent: 'exclude'
};

export default NavigationList;
