import React from 'react';
import PropTypes from 'prop-types';
const DataTagList = ({ list }) => {
  return (
    <ul className="data-tag-list">
      {list.map((tag, idx) => (
        <li
          key={"data-tag-" + idx}
          className="data-tag-list--item">
          {tag}
        </li>
      ))}
    </ul>
  );
};
DataTagList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string),
};
export default DataTagList;