import React from 'react';

import GroupedSearchPage from 'components/grouped-search-page';

const PublicationsPage = props => (
  <GroupedSearchPage {...props} hasLowerCaseSectionFilters />
);

PublicationsPage.propTypes = GroupedSearchPage.propTypes;

export default PublicationsPage;
