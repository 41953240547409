import React from 'react';
import PropTypes from 'prop-types';

import ContentArea from 'components/content-area';
import ContentAreaItem from 'components/content-area-item';
import ContentContainer from 'components/content-container';
import FrontpageHeader from 'components/frontpage-header';
import HtmlString from 'components/html-string';
import RichTextBlock from 'components/rich-text-block';
import LinkList from 'components/link-list';

const narrowTextProps = {
  HtmlString: { theme: HtmlString.themes.narrow },
};

const Frontpage = ({ content, header, linkList }) => (
  <div className="frontpage">
    {header && <FrontpageHeader {...header} />}
    {linkList && (
      <LinkList
        className="frontpage--link-list"
        {...linkList}
        theme={LinkList.themes.frontpageLinks}
        withIcon
      />
    )}
    <ContentContainer className="frontpage--content">
      <ContentArea
        additionalComponentProps={{
          ...narrowTextProps,
          // NOTE: RichText is just a proxy for ContentArea, so themes need to be applied using additionalComponentProps
          RichText: { additionalComponentProps: narrowTextProps }
        }}
        additionalItemProps={{
          PageLinksBlock: { size: ContentAreaItem.sizes.fullScreen }
        }}
        {...content}
      />
    </ContentContainer>
  </div>
);

Frontpage.propTypes = {
  content: PropTypes.exact(ContentArea.propTypes),
  header: PropTypes.exact(FrontpageHeader.propTypes),
  linkList: PropTypes.exact(LinkList.propTypes)
};

export default Frontpage;
