import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components-old/link';
import RichText from 'components-old/rich-text';

const MapPopup = ({ address, link, title }) => (
  <div className="map-popup">
    {title && <h2>{title}</h2>}
    <div className="map-popup--text">
      <RichText {...address} />
    </div>

    <Link theme={Link.themes.underline} {...link} />
  </div>
);

MapPopup.propTypes = {
  address: PropTypes.exact(RichText.propTypes),
  link: PropTypes.exact(Link.propTypes),
  title: PropTypes.string
};

export default MapPopup;
