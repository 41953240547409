import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';

import Accordion from 'components-old/accordion';
import Icon from 'components-old/icon';
import RichText from 'components-old/rich-text';

const FactBox = ({ accordion, onPageEditing, title, text }) => {
  const isEditMode = Object.keys(onPageEditing).length > 0;

  return (
    <Accordion {...accordion}>
      {({ Collapse, Button, isOpen, toggle }) => (
        <div className="accordion-block">
          <div
            className="accordion-block--header"
            onClick={isEditMode ? () => {} : toggle}
          >
            <h2 {...OPE(onPageEditing.title)}>
              {title}
              <Button>
                <Icon
                  className={cn('accordion-block--icon', {
                    '-is-open': isOpen
                  })}
                  name="small-arrow-down"
                />
              </Button>
            </h2>
          </div>

          <Collapse>
            <div className="accordion-block--content">
              <RichText {...text} />
            </div>
          </Collapse>
        </div>
      )}
    </Accordion>
  );
};

FactBox.propTypes = {
  accordion: PropTypes.exact(Accordion.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),

  title: PropTypes.string,
  text: PropTypes.exact(RichText.propTypes)
};

FactBox.defaultProps = {
  onPageEditing: {}
};

export default FactBox;
