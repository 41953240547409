import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Accordion from 'components/accordion';
import Icon from 'components/icon';
import Link from 'components/link';
import useIsScreenSize from 'js/hooks/use-is-screen-size';

const NestedMenu = ({
  accordion,
  title,
  linkList = [],
  linkLists = [],
  mainPageLink
}) => {
  const isDesktop = useIsScreenSize('lg');

  return (
    isDesktop !== undefined && (
      <Accordion
        {...accordion}
        initiallyOpen={isDesktop}
        buttonTextForLabel={title}
      >
        {({ Button, Collapse, isOpen }) => (
          <div className="nested-menu">
            <div className="nested-menu--inner">
              {!isDesktop && (
                <Button className="nested-menu--button">
                  {title && <h2 className="nested-menu--title">{title}</h2>}
                  <Icon
                    className={cn('nested-menu--button-icon', {
                      '-close': isOpen
                    })}
                    name={isOpen ? 'x' : 'menu'}
                    fill={!isOpen}
                  />
                </Button>
              )}
              {React.createElement(
                isDesktop ? React.Fragment : Collapse,
                {},
                <>
                  {isDesktop && title && (
                    <h2 className="nested-menu--title">
                      <Link
                        url={mainPageLink ? mainPageLink.url : null}
                        text={title}
                      />
                    </h2>
                  )}
                  {!isDesktop && mainPageLink && (
                    <Link
                      className="nested-menu--main-page-link"
                      {...mainPageLink}
                    />
                  )}
                  <ul className="nested-menu--list">
                    {linkList.map(({ link, isCurrent }) => (
                      <li
                        className={cn(
                          'nested-menu--inner-list-item',
                          '-single-link',
                          {
                            '-current-page': isCurrent
                          }
                        )}
                        key={link.text}
                      >
                        <Link {...link} noHoverEffect />
                      </li>
                    ))}
                    {linkLists.map(
                      (
                        { accordion, linkList, section = {}, isCurrent },
                        linkListIndex
                      ) => (
                        <Accordion
                          {...accordion}
                          initiallyOpen={isCurrent}
                          key={`${section.title}-${linkListIndex}`}
                        >
                          {({ Button, Collapse, isOpen }) => (
                            <li
                              className={cn('nested-menu--list-item', {
                                '-current-section': isCurrent
                              })}
                            >
                              <Button className="nested-menu--list-item-button">
                                <Icon
                                  className={cn('nested-menu--list-item-icon', {
                                    '-is-open': isOpen
                                  })}
                                  name="small-arrow-down"
                                  fill={true}
                                />
                                <h3 className="nested-menu--list-item-title">
                                  <b>{section.number}</b> {section.title}
                                </h3>
                              </Button>
                              <Collapse>
                                <ul className="nested-menu--inner-list">
                                  {linkList.map(({ link = {}, isCurrent }) => (
                                    <li
                                      className={cn(
                                        'nested-menu--inner-list-item',
                                        {
                                          '-current-page': isCurrent
                                        }
                                      )}
                                      key={link.text}
                                    >
                                      <Link {...link} noHoverEffect />
                                    </li>
                                  ))}
                                </ul>
                              </Collapse>
                            </li>
                          )}
                        </Accordion>
                      )
                    )}
                  </ul>
                </>
              )}
            </div>
          </div>
        )}
      </Accordion>
    )
  );
};

NestedMenu.propTypes = {
  accordion: PropTypes.exact(Accordion.propTypes),
  title: PropTypes.string,
  linkList: PropTypes.arrayOf(
    PropTypes.exact({
      link: PropTypes.exact(Link.propTypes),
      isCurrent: PropTypes.bool
    })
  ),
  linkLists: PropTypes.arrayOf(
    PropTypes.exact({
      accordion: PropTypes.exact(Accordion.propTypes),
      isCurrent: PropTypes.bool,
      linkList: PropTypes.arrayOf(
        PropTypes.exact({
          link: PropTypes.exact(Link.propTypes),
          isCurrent: PropTypes.bool
        })
      ),
      section: PropTypes.exact({
        number: PropTypes.string,
        title: PropTypes.string
      })
    })
  ),
  mainPageLink: PropTypes.exact(Link.propTypes)
};

export default NestedMenu;
