// See https://developers.google.com/maps/documentation/javascript/styling for examples of styling.
// See https://developers.google.com/maps/documentation/javascript/style-reference for element types reference

export default {
  gestureHandling: 'cooperative',
  disableDefaultUI: true,
  styles: [
    {
      featureType: 'landscape',
      elementType: 'geometry',
      stylers: [{ color: '#E8ECEE' }]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [{ color: '#9EE6F4' }]
    }
  ]
};
