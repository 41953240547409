import React from 'react';
import PropTypes from 'prop-types';

import LinkWithText from 'components/link-with-text';

const LinkWithTextList = ({ links }) => {
  return (
    <div className="link-with-text-list">
      {links &&
        links.map(linkWithText => (
          <LinkWithText key={linkWithText.text} {...linkWithText} />
        ))}
    </div>
  );
};

LinkWithTextList.propTypes = {
  links: PropTypes.arrayOf(PropTypes.exact(LinkWithText.propTypes))
};

export default LinkWithTextList;
