/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import RelatedDates from 'components-old/related-dates';
import Accordion from 'components-old/accordion';
import Icon from 'components-old/icon';
import Heading from 'components-old/heading/heading';

const escapeSpecialChar = str => str.split('?').join('\\?');

const ProposalShortcuts = props => {
  const [openOnPageLoad, setOpenOnPageLoad] = useState();

  useEffect(() => {
    if (!props.initiallyOpenUrls) return;
    const isMatch = props.initiallyOpenUrls.some(url => {
      const regExp = new RegExp(`${escapeSpecialChar(url)}/?$`);
      return regExp.test(window.location.href);
    });
    setOpenOnPageLoad(isMatch);
  }, []);

  return (
    <Accordion {...props.accordion} buttonTextForLabel={props.title}>
      {accordionProps => (
        <ProposalShortcutsContent
          shouldOpen={openOnPageLoad && !accordionProps.isOpen}
          setOpenOnPageLoad={setOpenOnPageLoad}
          {...accordionProps}
          {...props}
        />
      )}
    </Accordion>
  );
};

const ProposalShortcutsContent = ({
  Button,
  Collapse,
  isOpen,
  shouldOpen,
  setOpenOnPageLoad,
  toggle,
  relatedDates,
  title,
  description
}) => {
  useEffect(() => {
    if (!shouldOpen) return;
    toggle();
    setOpenOnPageLoad(false);
  }, [shouldOpen]);

  const relatedDatesWithShowMoreDates = {
    ...relatedDates,
    dates: relatedDates.dates.map(date => ({
      ...date,
      dateContainer: {
        ...(date.dateContainer ? date.dateContainer : {}),
        moreDates: date.dateContainer.moreDates
          ? date.dateContainer.moreDates
          : { showMoreDates: true }
      }
    }))
  };
  return (
    <div className="proposal-shortcuts">
      <Button className="proposal-shortcuts__button">
        <div className="proposal-shortcuts__button-left">
          <Icon className="proposal-shortcuts__button-icon" name="flag" />
          <Heading>{title}</Heading>
        </div>
        <Icon
          className={cn({
            '-is-active': isOpen
          })}
          name="arrow-down-wide"
        />
      </Button>
      <Collapse>
        <div className="proposal-shortcuts__collapse">
          <p className="proposal-shortcuts__description">{description}</p>
          <RelatedDates {...relatedDatesWithShowMoreDates} />
        </div>
      </Collapse>
    </div>
  );
};

ProposalShortcuts.propTypes = {
  relatedDates: PropTypes.exact(RelatedDates.propTypes),
  accordion: PropTypes.exact(Accordion.propTypes),
  title: PropTypes.string,
  description: PropTypes.string,
  initiallyOpenUrls: PropTypes.arrayOf(PropTypes.string)
};

export default ProposalShortcuts;
