import React from 'react';
import PropTypes from 'prop-types';

import OPE from 'js/on-page-editing';

const Quote = ({ onPageEditing, text, quoteBy }) => (
  <blockquote>
    <span>“</span>
    <p {...OPE(onPageEditing.text)}>{text}</p>
    <cite {...OPE(onPageEditing.quoteBy)}>{quoteBy}</cite>
  </blockquote>
);

Quote.propTypes = {
  onPageEditing: PropTypes.exact({
    text: PropTypes.string,
    quoteBy: PropTypes.string
  }),
  text: PropTypes.string,
  quoteBy: PropTypes.string
};

Quote.defaultProps = {
  onPageEditing: {}
};

export default Quote;
