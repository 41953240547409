import React from 'react';
import PropTypes from 'prop-types';
import StatsLink from 'components/stats-link-list/stats-link';
import Link from 'components/link';
import Button from 'components/button';
import PortfolioContent from 'components/portfolio-content';
import Image from 'components/image';

const StatsLinkList = ({
  heading,
  statsLinks = [],
  aboutImage = {},
  seeMoreLink
}) => {
  return (
    <PortfolioContent title={heading}>
      <div className="stats-link-list">
        <div className="stats-link-list--links">
          {statsLinks.map((statsLink, index) => (
            <StatsLink {...statsLink} key={`${statsLink.title}-${index}`} />
          ))}
        </div>
        {aboutImage.src && (
          <div className="stats-link-list--image">
            <Image {...aboutImage} />
          </div>
        )}
        {seeMoreLink && (
          <Link
            className="stats-link-list--see-more-link"
            {...seeMoreLink}
            theme={Button.themes.orangeOutline}
            useButtonStyles
          />
        )}
      </div>
    </PortfolioContent>
  );
};

StatsLinkList.propTypes = {
  heading: PropTypes.string,
  statsLinks: PropTypes.arrayOf(PropTypes.exact(StatsLink.propTypes)),
  aboutImage: PropTypes.exact(Image.propTypes),
  seeMoreLink: PropTypes.exact(Link.propTypes)
};

export default StatsLinkList;
