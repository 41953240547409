const editTypes = {
  floating: 'floating',
  flyout: 'flyout',
  inline: 'inline',
  webcontrol: 'webcontrol'
};

const getOnPageEditingProps = (propertyName, editType) =>
  !propertyName
    ? {}
    : {
        'data-epi-property-name': propertyName,
        'data-epi-use-mvc': 'True',
        'data-epi-property-edittype': editTypes[editType]
      };

export default getOnPageEditingProps;
