import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import NewsletterRegistrationPage from 'components-old/newsletter-registration-page';

const NewsletterCrmRegistrationPage = ({ registrationPage }) => {
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isChecked, setIsChecked] = useState(true);
  const [triedSubmitting, setTriedSubmitting] = useState(false);
  const [offsetTop, setOffsetTop] = useState(0);

  const messageRef = useRef();

  const showEmailError = !isValidEmail && triedSubmitting;
  const showFormError = !isChecked && triedSubmitting;

  const onSubmit = e => {
    if (!isChecked || !isValidEmail) {
      setTriedSubmitting(true);
      e.preventDefault();
      if (!isChecked) window.scrollTo(0, offsetTop);
    }
  };

  useEffect(() => {
    setOffsetTop(messageRef.current.offsetTop);
  }, [isChecked]);

  return (
    <div className="newsletter-crm-registration-page">
      <NewsletterRegistrationPage
        {...registrationPage}
        setIsValidEmail={setIsValidEmail}
        onSubmit={onSubmit}
        showFormError={showFormError}
        showEmailError={showEmailError}
        setIsChecked={setIsChecked}
        ref={messageRef}
      />
    </div>
  );
};

NewsletterCrmRegistrationPage.propTypes = {
  registrationPage: PropTypes.exact(NewsletterRegistrationPage.propTypes)
};

export default NewsletterCrmRegistrationPage;
