/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ContentContainer from 'components-old/content-container';

const getZIndex = (length, index, currentIndex) => {
  if (index === currentIndex) return length;
  if (index < currentIndex) return index;
  return length - index;
};

const TabList = ({ children, className }) => {
  return (
    <ContentContainer
      className={cn('tab-list', className)}
      theme={ContentContainer.themes.medium}
    >
      <ul>{children}</ul>
    </ContentContainer>
  );
};
TabList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

TabList.propTypesMeta = 'exclude';

TabList.Item = ({ children, isCurrent, length, index, currentIndex }) => (
  <li
    className={cn({ '-is-active': isCurrent })}
    style={{
      zIndex: getZIndex(length, index, currentIndex)
    }}
  >
    {children}
  </li>
);

TabList.Item.propTypes = {
  children: PropTypes.node,
  isCurrent: PropTypes.bool,
  length: PropTypes.number,
  index: PropTypes.number,
  currentIndex: PropTypes.number
};
export default TabList;
