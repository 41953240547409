import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import ContentContainer from 'components/content-container';
import EmptyList from 'components/empty-list';
import FilterLayout from 'components/filter-layout';
import Form from 'components/form';
import GroupedSearchPageSectionFilterList from 'components/grouped-search-page-section-filter-list';
import Pagination from 'components/pagination';
import Search from 'components/search';
import SearchResultGroup from 'components/search-result-group';
import Spinner from 'components/spinner';
import useFetchFilteredResults from 'js/hooks/use-fetch-filtered-results';

const GroupedSearchPage = ({
  emptyList,
  filterLayout,
  form,
  sections,
  pagination,
  results,
  resultsDescription,
  search,
  title,
  hasLowerCaseSectionFilters,
  fetchFilteredResultsEndpoint
}) => {
  const currentSectionFilter = (function () {
    const currentSection =
      sections.list && sections.list.find(section => section.isCurrent);
    if (currentSection) return { [currentSection.name]: currentSection.value };
  })();
  // This is a merger of <Filters> searchQueryParamter state and section filter
  const [filterQuery, setFilterQuery] = useState(currentSectionFilter);
  const updatedFilterQuery = filters =>
    setFilterQuery(prev => ({ ...prev, ...filters }));

  const [
    isLoading,
    {
      results: resultsState,
      pagination: paginationState,
      resultsDescription: resultsDescriptionState,
      emptyList: emptyListState,
      search: searchState,
      filterLayout: filterLayoutState,
      sections: sectionsState
    },
    fetchResults
  ] = useFetchFilteredResults(
    {
      results,
      pagination,
      resultsDescription,
      emptyList,
      search,
      filterLayout,
      sections
    },
    fetchFilteredResultsEndpoint,
    form.endpoint,
    search.input
  );

  const fetchResultsWithSearch = searchQueryParameter => {
    fetchResults({ ...filterQuery, ...searchQueryParameter });
  };

  const fetchResultsWithSection = (name, value) => {
    if (value) {
      const queryParameters = {
        ...filterQuery,
        ...{ [name]: value }
      };
      updatedFilterQuery(queryParameters);
      fetchResults(queryParameters);
    } else {
      const copy = { ...filterQuery };
      delete copy[name];
      const queryParameters = {
        ...copy
      };
      setFilterQuery(queryParameters)
      fetchResults(queryParameters);
    }
  };

  const topContent = (
    <div className="grouped-search-page--results-description">
      {resultsDescriptionState}
    </div>
  );

  return (
    <Form showSubmitButton={false} {...form}>
      <ContentContainer className="grouped-search-page">
        {title && <h1 className="grouped-search-page--title">{title}</h1>}
        <Search {...searchState} />

        {sectionsState && (
          <GroupedSearchPageSectionFilterList
            list={sectionsState.list}
            fetchResultsWithSection={fetchResultsWithSection}
            hasLowerCaseSectionFilters={hasLowerCaseSectionFilters}
          />
        )}
      </ContentContainer>
      <FilterLayout
        {...filterLayoutState}
        topContent={topContent}
        fetchFilteredResults={fetchResultsWithSearch}
        isLoading={isLoading}
        includeBottomBorder={false}
        updateFilterQuery={updatedFilterQuery}
      >
        {resultsState.length > 0 ? (
          resultsState.map((result, index) => (
            <SearchResultGroup key={result.title + String(index)} {...result} />
          ))
        ) : (
          <EmptyList {...emptyListState} />
        )}
        <Spinner isActive={isLoading} />
        <Pagination {...paginationState} />
      </FilterLayout>
    </Form>
  );
};

GroupedSearchPage.defaultProps = {
  sections: {},
  results: []
};

GroupedSearchPage.propTypes = {
  emptyList: PropTypes.exact(EmptyList.propTypes),
  filterLayout: PropTypes.exact(FilterLayout.propTypes),
  form: PropTypes.exact(Form.propTypes),
  sections: PropTypes.exact(GroupedSearchPageSectionFilterList.propTypes),
  pagination: PropTypes.exact(Pagination.propTypes),
  results: PropTypes.arrayOf(PropTypes.exact(SearchResultGroup.propTypes)),
  resultsDescription: PropTypes.string,
  search: PropTypes.exact(Search.propTypes),
  hasLowerCaseSectionFilters: PropTypes.bool,
  title: PropTypes.string,
  fetchFilteredResultsEndpoint: PropTypes.string
};

GroupedSearchPage.propTypesMeta = {
  hasLowerCaseSectionFilters: 'exclude'
};

export default GroupedSearchPage;
