import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components-old/link';

const TopFilter = ({ options, title, timeframe }) => (
  <div className="top-filter">
    <p>{title}</p>
    <input
      type="hidden"
      id={timeframe.id}
      name={timeframe.name}
      value={timeframe.value}
    />
    {options.map(option => {
      return (
        <div className="top-filter--input" key={option.link.url}>
          <Link
            theme={option.isCurrent ? Link.themes.black : []}
            {...option.link}
          />
        </div>
      );
    })}
  </div>
);

TopFilter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.exact({
      isCurrent: PropTypes.bool,
      link: PropTypes.exact(Link.propTypes)
    })
  ),
  title: PropTypes.string,
  timeframe: PropTypes.exact({
    value: PropTypes.number,
    id: PropTypes.string,
    name: PropTypes.string
  })
};

TopFilter.defaultProps = {
  options: []
};

export default TopFilter;
