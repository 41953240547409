import { useEffect, useState, useRef } from 'react';
import useResize from 'js/hooks/resize';

const useContentHeight = () => {
  const [height, setHeight] = useState('0px');
  const ref = useRef();

  useEffect(() => {
    if (ref.current) setHeight(ref.current.offsetHeight);
  }, []);

  useResize(() => ref.current && setHeight(ref.current.offsetHeight));

  return [height, ref];
};

export default useContentHeight;
