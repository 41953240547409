import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import cn from 'classnames';

import Shape from 'components/nysgjerrigper/shape';

import StepsMenu from 'components/nysgjerrigper/steps-menu';
import Path from 'components/nysgjerrigper/path/path';
import StepsContent from 'components/nysgjerrigper/steps-content';
import Step from 'components/nysgjerrigper/step';
import Heading from 'components/heading';

const NpMethod = ({ title, ingress, link, menu = [], stepsContent }) => {
  const [menuState, setStepsMenuState] = useState(menu);
  const [menuAnimationTrigger, setMenuAnimationTrigger] = useState();

  const activateStep = index => {
    const nextSteps = menu.map((step, i) =>
      i === index ? { ...step, isActive: true } : step
    );
    setStepsMenuState(nextSteps);
    setMenuAnimationTrigger(true);
  };

  const deactiveSteps = () => {
    setStepsMenuState(steps =>
      steps.map(step => ({ ...step, isActive: false }))
    );
    setMenuAnimationTrigger(false);
  };
  const isOverviewPage = !menuState.some(step => step.isActive);

  return (
    <>
      <div className="np-method--background"></div>
      <div className="np-method">
        {isOverviewPage && (
          <>
            <Path
              className="np-method-path -desktop"
              start={[5, 15]}
              relativeBeziers={[-2, 556, -170, 273]}
              end={[82, 36]}
            />
            <Path
              className="np-method-path -desktop"
              start={[52, 0]}
              relativeBeziers={[-2, 198, -477, 0]}
              end={[100, 64]}
            />
            <Path
              className="np-method-path -desktop"
              start={[34, 100]}
              relativeBeziers={[-21, -399, -411, 0]}
              end={[100, 25]}
            />
            <Path
              className="np-method-path -mobile"
              start={[100, 20]}
              relativeBeziers={[-300, 100, 0, -440]}
              end={[2, 100]}
            />
            <Path
              className="np-method-path -mobile"
              start={[0, 5]}
              relativeBeziers={[350, 100, 400, -100]}
              end={[25, 90]}
            />
          </>
        )}
        <div className="np-method--header">
          <div className="np-method--header-shapes">
            {isOverviewPage && (
              <>
                <Shape color={Shape.colors.red} type={Shape.types.rectangle} />
                <Shape color={Shape.colors.orange} type={Shape.types.circle} />
              </>
            )}
            <Shape
              className={cn({
                'np-method--header-shapes--half-hidden': !isOverviewPage
              })}
              color={Shape.colors.teal}
              type={Shape.types.circle}
            />
          </div>
          <div className="np-method--header-content">
            {title && (
              <Heading level={1} className="np-method--header-title">
                {title}
              </Heading>
            )}
            <p className="np-method--header-ingress">{ingress}</p>
            <Link
              className="np-method--header-link"
              iconClassName="np-method--header-link-icon"
              {...link}
              icon="link-arrow-circled"
            />
          </div>
          <Shape
            className="np-method--header-snake"
            color={Shape.colors.teal}
            type={Shape.types.snakeH}
          />
        </div>
        <StepsMenu
          menu={menuState}
          activateStep={activateStep}
          menuAnimationTrigger={menuAnimationTrigger}
        />
        <StepsContent
          {...stepsContent}
          onClose={deactiveSteps}
          activeStepIndex={menuState.findIndex(step => !!step.isActive)}
          isActive={!isOverviewPage}
        />
      </div>
    </>
  );
};

NpMethod.propTypes = {
  title: PropTypes.string,
  ingress: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  menu: PropTypes.arrayOf(PropTypes.exact(Step.propTypes)),
  stepsContent: PropTypes.exact(StepsContent.propTypes)
};

export default NpMethod;
