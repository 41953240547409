import React from 'react';
import PropTypes from 'prop-types';

import DatesMoreLink from './dates-more-link';
import useOnFocusInsideChange from 'js/hooks/use-on-focus-inside-change';
import useOutsideClick from 'js/hooks/use-outside-click';

const DatesMoreLinkList = ({ dates, isOpen, toggleAccordion }) => {
  const listRef = useOnFocusInsideChange(
    isInside => !isInside && toggleAccordion(),
    isOpen
  );

  useOutsideClick(listRef, () => isOpen && toggleAccordion());

  return (
    <ul className="dates-more-link-list" ref={listRef}>
      {dates.map((date, index) => (
        <DatesMoreLink
          {...date}
          key={`${date.title}-${index}`}
          isOpen={isOpen}
        />
      ))}
    </ul>
  );
};

DatesMoreLinkList.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.exact(DatesMoreLink.propTypes))
};

DatesMoreLinkList.propTypesMeta = 'exclude';

export default DatesMoreLinkList;
