/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

const Tags = ({ list }) => {
  return (
    <div className="tags">
      {list &&
        list.map(tag => (
          <div className="tags-item" key={tag}>
            {tag}
          </div>
        ))}
    </div>
  );
};

Tags.propTypes = {
  list: PropTypes.arrayOf(PropTypes.string)
};

export default Tags;
