import { useState, useEffect } from 'react';
import api from 'js/api-helper';
import { error } from 'js/log';
import toQueryString from 'js/utils/to-query-string';

const useFetchFilteredResults = (
  initialData,
  endpoint,
  currentPage,
  urlQuery
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(initialData);

  const handleSetData = data => {
    const rand = Math.random();
    if (data?.filterLayout?.filters) data.filterLayout.filters.dataId = rand;
    if (data.search?.input) data.search.input.dataId = rand;
    setData(data);
  };

  const fetchFilteredResults = searchQueryParameter => {
    setIsLoading(true);

    const parameterString = toQueryString(searchQueryParameter);

    if (urlQuery) {
      const queryObject = { [urlQuery.name]: urlQuery.value };
      searchQueryParameter = Object.assign(queryObject, searchQueryParameter);
    }

    let urlQueryString = "?";
    if (urlQuery)
      urlQueryString = urlQuery.value
        ? `?${urlQuery.name}=${urlQuery.value}&`
        : '?';

    const urlEndpoint = currentPage
      .concat(urlQueryString)
      .concat(parameterString);

    api
      .execute(endpoint, searchQueryParameter, 'post')
      .then(response => {
        handleSetData(response);
        window.history.pushState('', '', urlEndpoint);
        setIsLoading(false);
      })
      .catch(e => {
        if (endpoint.includes('localhost:')) {
          error(
            'Got error when requesting data. \n Have you started the mock-server?\nStart mock server with "yarn mock-server"\n',
            e
          );
        } else {
          error('Got error when requesting data: ', e);
        }
      });
  };

  useEffect(() => {
    // Fetch new data on back/forward browser clicks
    const groupParamsByKey = queryParams => {
      //Stolen from: https://stackoverflow.com/questions/8648892/how-to-convert-url-parameters-to-a-javascript-object

      const params = new URLSearchParams(queryParams);
      return [...params.entries()].reduce((acc, tuple) => {
        // getting the key and value from each tuple
        const [key, val] = tuple;
        if (acc.hasOwnProperty(key)) {
          // if the current key is already an array, we'll add the value to it
          if (Array.isArray(acc[key])) {
            acc[key] = [...acc[key], val];
          } else {
            // if it's not an array, but contains a value, we'll convert it into an array
            // and add the current value to it
            acc[key] = [acc[key], val];
          }
        } else {
          // plain assignment if no special case is present
          acc[key] = [val];
        }

        return acc;
      }, {});
    };

    const handlePageChange = () => {
      const searchQueryParameter = groupParamsByKey(window.location.search);
      if (Array.isArray(searchQueryParameter.q))
        searchQueryParameter.q = searchQueryParameter.q[0];
      setIsLoading(true);
      api
        .execute(endpoint, searchQueryParameter, 'post')
        .then(response => {
          handleSetData(response);
          setIsLoading(false);
        })
        .catch(e => {
          setIsLoading(false);
          error('Got error when requesting data: ', e);
        });
    };

    window.addEventListener('popstate', handlePageChange);
    return () => window.removeEventListener('popstate', handlePageChange);
  }, []);
  return [isLoading, data, fetchFilteredResults];
};

export default useFetchFilteredResults;
