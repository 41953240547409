import React from 'react';
import PropTypes from 'prop-types';

import DateCard from 'components/date-card';
import RelatedContent from 'components/related-content';
import MetaData from 'components/metadata';

const RelatedDates = ({ dates, relatedContent, usedInSidebar, isGrid }) => {
  const theme = () => {
    const theme = [DateCard.themes.noPadding, DateCard.themes.noBorder];
    if (usedInSidebar) return theme.concat(DateCard.themes.sidebar);
    if (isGrid) return theme.concat(DateCard.themes.vertical);
    return theme;
  };
  const relatedContentProps = { ...relatedContent, isGrid };
  return (
    <div className="related-dates">
      <RelatedContent {...relatedContentProps} itemMinWidth={280}>
        {dates.map(date => (
          <DateCard
            headingLevel={3}
            key={date.title}
            usedInSidebar={usedInSidebar}
            theme={theme()}
            {...date}
            isImageLink
            metadataTheme={MetaData.themes.vertical}
          />
        ))}
      </RelatedContent>
    </div>
  );
};

RelatedDates.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.exact(DateCard.propTypes)),
  relatedContent: PropTypes.exact(RelatedContent.propTypes),
  usedInSidebar: PropTypes.bool,
  isGrid: PropTypes.bool
};

RelatedDates.defaultProps = {
  dates: []
};

export default RelatedDates;
