import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';
import useOnIntersect from 'js/hooks/use-on-intersect';
import getNewSrc from 'js/responsive-images';

const FluidImage = ({
  alt,
  className,
  focusPoint,
  initialSize,
  onPageEditing,
  src,
  darkFilter
}) => {
  const container = useRef();
  const [currentSrc, setSrc] = useState(undefined);

  const darkGradient = darkFilter
    ? 'linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),'
    : '';

  useOnIntersect(container, () =>
    setSrc(getNewSrc(src, container.current.offsetWidth, initialSize))
  );

  return (
    <div
      className={cn('fluid-image', className)}
      ref={container}
      {...OPE(onPageEditing === null ? null : onPageEditing.image)}
    >
      {currentSrc && (
        <>
          <div
            className={cn('fluid-image--image')}
            style={{
              backgroundImage: `${darkGradient} url(${currentSrc})`,
              backgroundPosition: focusPoint
                ? `${focusPoint.x}% ${focusPoint.y}%`
                : 'center'
            }}
          />
          <img src={currentSrc} alt={alt} />
        </>
      )}
    </div>
  );
};

FluidImage.propTypes = {
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  focusPoint: PropTypes.exact({
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired
  }),
  initialSize: PropTypes.number,
  onPageEditing: PropTypes.exact({
    image: PropTypes.string
  }),
  src: PropTypes.string,
  darkFilter: PropTypes.bool
};

FluidImage.propTypesMeta = {
  className: 'exclude',
  darkFilter: 'exclude'
};

FluidImage.defaultProps = {
  initialSize: 200,
  onPageEditing: {}
};

export default FluidImage;
