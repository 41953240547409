import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Shape from 'components/nysgjerrigper/shape/shape';

import { getColor } from 'js/utils/np-colors';

const types = ['longH', 'snakeV', 'longV'];

// This is a function to prevent all RandomShapes component from sharing the same type
const pickType = () => types[Math.floor(Math.random() * 10000) % types.length];

const RandomShapes = React.forwardRef(
  ({ index, blockIndex, stepIndex, style }, ref) => {
    const randomType = useState(pickType)[0]; // This becomes the return of pickType() and not the function pickType
    const coinFlip = useState(Math.random() > 0.5)[0];

    const colorIndex =
      index >= blockIndex ? stepIndex + index + 1 : stepIndex + index;

    const classes = () => {
      const isRight = index % 2 === 1;
      const isColumn = randomType === 'snakeV' || randomType === 'longV';
      return {
        '-right': isRight,
        '-right-horizontal': isRight && !isColumn,
        '-column': isColumn,
        '-reverse': coinFlip
      };
    };

    return (
      <div className="random-shapes" ref={ref}>
        <div className="random-shapes--parallax-scrolling" style={style}>
          <div className={cn('random-shapes--shapes', classes())}>
            <Shape
              className={cn('random-shapes--shapes-shape -long', randomType)}
              type={Shape.types[randomType]}
              color={getColor(stepIndex)}
            />
            <Shape
              className={cn('random-shapes--shapes-shape -circle')}
              type={Shape.types.circle}
              color={getColor(colorIndex)}
            />
          </div>
        </div>
      </div>
    );
  }
);

RandomShapes.propTypes = {
  index: PropTypes.number,
  blockIndex: PropTypes.number,
  stepIndex: PropTypes.number,
  style: PropTypes.object
};

RandomShapes.propTypesMeta = 'exclude';

export default RandomShapes;
