import { useEffect } from 'react';

const useBlockScrolling = shouldBlock => {
  useEffect(() => {
    if (shouldBlock === undefined) return;
    if (shouldBlock) {
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.position = 'fixed';
    } else {
      const scrollPosition = Math.abs(parseInt(document.body.style.top)) || 0;
      document.body.style.position = '';
      document.body.style.top = '';

      const scrollBehavior = document.documentElement.style.scrollBehavior;
      document.documentElement.style.scrollBehavior = 'auto';
      window.scrollTo(0, scrollPosition);
      document.documentElement.style.scrollBehavior = scrollBehavior;
    }
  }, [shouldBlock]);
};

export default useBlockScrolling;
