import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';

import Link from 'components/link';
import propTypeTheme from 'js/utils/prop-type-theme';

const themes = {
  gray: '-theme-gray',
  prominent: '-theme-prominent'
};

const LinkWithText = ({ link, onPageEditing, text, theme }) => (
  <div className={cn('link-with-text', theme)}>
    <h2 {...OPE(onPageEditing.link)}>
      <Link theme={[Link.themes.actionLink, Link.themes.large, Link.themes.wrappedHeadingForPrint]} {...link} />
    </h2>
    <p className="link-with-text--text" {...OPE(onPageEditing.text)}>
      {text}
    </p>
  </div>
);

LinkWithText.propTypes = {
  link: PropTypes.exact(Link.propTypes),
  onPageEditing: PropTypes.exact({
    link: PropTypes.string,
    text: PropTypes.string
  }),
  text: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes))
};

LinkWithText.defaultProps = {
  onPageEditing: {}
};

export default LinkWithText;
