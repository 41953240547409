import React from 'react';
import PropTypes from 'prop-types';

import ArticleHeader from 'components/article-header';
import ContentWithSidebar from 'components/content-with-sidebar';
import PageNavigation from 'components/page-navigation';
import TableOfContents from 'components/table-of-contents';
import RichText from 'components/rich-text';
import ContentArea from 'components/content-area';


const LargeDocumentsPage = ({
  content,
  header,
  ingress,
  pageNavigation,
  tableOfContents,
  title,
  contentArea,
}) => (
  <ContentWithSidebar
    theme={ContentWithSidebar.themes.largeDocuments}
    className="large-documents-page"
  >
    <ContentWithSidebar.Header>
      {title && <h1>{title}</h1>}
      <div className="large-documents-page--ingress">
        <p>{ingress}</p>
      </div>
    </ContentWithSidebar.Header>
    <ContentWithSidebar.Sidebar>
      <TableOfContents {...tableOfContents} />
    </ContentWithSidebar.Sidebar>
    <ContentWithSidebar.Content className="large-documents-page--content">
      <ArticleHeader {...header} />


      <RichText {...content} />
      <ContentArea {...contentArea} />
      {pageNavigation && <PageNavigation {...pageNavigation} />}
      <div className="large-documents-page--footer">

      </div>
    </ContentWithSidebar.Content>
  </ContentWithSidebar>
);

LargeDocumentsPage.propTypes = {
  content: PropTypes.exact(RichText.propTypes),

  header: PropTypes.exact(ArticleHeader.propTypes),
  ingress: PropTypes.string,
  pageNavigation: PropTypes.exact(PageNavigation.propTypes),
  tableOfContents: PropTypes.exact(TableOfContents.propTypes),
  title: PropTypes.string,
  contentArea: PropTypes.exact(ContentArea.propTypes)
};

export default LargeDocumentsPage;
