import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Accordion from 'components-old/accordion';
import Icon from 'components-old/icon';
import propTypeTheme from 'utils/prop-type-theme';
import DatesMoreLink from './dates-more-link';
import DatesMoreLinkList from './dates-more-link-list';

const themes = {
  small: '-theme-small',
  medium: '-theme-medium'
};

const DatesMore = ({ accordion, title, dates, theme, showMoreDates }) => {
  return (dates && dates.length) || showMoreDates ? (
    <Accordion {...accordion} buttonTextForLabel={title}>
      {({ Button, Collapse, isOpen, toggle }) => (
        <div className={cn('dates-more', theme, { '-is-open': isOpen })}>
          <Button className="dates-more--button">
            {title && (
              <>
                <h3 className="dates-more--title">{title}</h3>
                <Icon
                  className={cn('dates-more--icon', { '-is-open': isOpen })}
                  name="arrow-down-wide"
                />
              </>
            )}
          </Button>
          {dates && dates.length && (
            <Collapse>
              <DatesMoreLinkList
                dates={dates}
                isOpen={isOpen}
                toggleAccordion={toggle}
              />
            </Collapse>
          )}
        </div>
      )}
    </Accordion>
  ) : null;
};

DatesMore.themes = themes;

DatesMore.propTypes = {
  accordion: PropTypes.exact(Accordion.propTypes),
  title: PropTypes.string,
  dates: PropTypes.arrayOf(PropTypes.exact(DatesMoreLink.propTypes)),
  theme: propTypeTheme(themes),
  showMoreDates: PropTypes.bool
};

DatesMore.propTypesMeta = {
  theme: 'exclude',
  showMoreDates: 'exclude'
};

export default DatesMore;
