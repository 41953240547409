import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import scrollingElement from '@creuna/utils/scrolling-element';

import TabList from './tab-list';
import TabContentWrapper from './tab-content-wrapper';

const tabId = id => `tab-${id}`;
const contentId = id => `content-${id}`;

// NOTE: 'aria-role="tablist"' is intentionally not used for this component.
// Read about why here: https://simplyaccessible.com/article/danger-aria-tabs/

const Tabs = ({ activeTab, items }) => {
  const initialId = activeTab || (items[0] || {}).guid;
  const [currentTab, setCurrentTab] = React.useState(initialId);
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
  const [isMounted, setIsMounted] = React.useState(false);

  const setActiveTab = id => e => {
    // NOTE: The anchor link scroll is prevented because it's intrusive for mouse user. Focus is set manually in React.useEffect below.
    e.preventDefault();
    setCurrentTab(id);
    setCurrentTabIndex(items.findIndex(item => item.guid === id));
  };

  React.useEffect(() => {
    // NOTE: This callback is called for the initial render, resulting in the first open tab receiving focus without user interaction. This is not nice, so this flag is used to prevent focus before interaction.
    if (!isMounted) {
      setIsMounted(true);
      return;
    }
    const url = new URL(window.location);
    url.search = '';
    url.hash = currentTab;
    window.history.pushState({}, '', url);
    // NOTE: Manually setting scroll position after focus in order to prevent scroll consistently across browsers
    const content = document.getElementById(contentId(currentTab));
    const scrollPos = scrollingElement.scrollTop;
    content && content.focus();
    scrollingElement.scrollTop = scrollPos;
  }, [currentTab]);

  React.useEffect(() => {
    //Manually anchor-scroll + select tab
    const hash = window.location.hash.replace('#', '');
    const hashMatchesTab = items.some(({ guid }) => guid === hash);
    if (hashMatchesTab) {
      setCurrentTab(hash);
      setCurrentTabIndex(items.findIndex(item => item.guid === hash));

      const tab = document.getElementById(tabId(hash));

      const scrollToTab = () => {
        setTimeout(() => {
          const behavior = scrollingElement.style.scrollBehavior;
          scrollingElement.style.scrollBehavior = 'auto';
          scrollingElement.scrollTop += tab.getBoundingClientRect().top;
          scrollingElement.style.scrollBehavior = behavior;
        }, [100]); // 100ms was arrived at through testing as there doesn't seem to be any way to trigger scrolling at the exact time nothing else can interfere with it.
      };
      window.addEventListener('load', scrollToTab, { once: true });
    }
  }, []);



  if (items.length <= 1) {
    return (
      <div className="no-tabs">
        <TabContentWrapper {...items[0].content} />
      </div>
    )
  }
  else {
    return (
      <div className="tabs">
        <TabList className='-limited-width'>
          {items.map(({ guid, name, disabled }, index) => {
            const isCurrent = guid === currentTab;
            return (
              <TabList.Item
                key={guid}
                isCurrent={isCurrent}
                length={items.length}
                index={index}
                currentIndex={currentTabIndex}
                disabled={disabled}
              >
                <a
                  className={cn({ '-has-hover-effect': !disabled })}
                  aria-current={isCurrent}
                  href={`#${guid}`}
                  id={tabId(guid)}
                  onClick={setActiveTab(guid)}
                >
                  {name}
                </a>
              </TabList.Item>
            );
          })}
        </TabList>
        <div className="tabs--content">
          {items.map(tab => {
            const isCurrent = tab.guid === currentTab;

            return (
              <section
                aria-hidden={!isCurrent}
                aria-labelledby={tabId(tab.guid)}
                id={contentId(tab.guid)}
                key={tab.name}
                className={cn('tabs--content-tab', { '-inactive': !isCurrent })}
                tabIndex={isCurrent ? -1 : null}
              >
                <TabContentWrapper {...tab.content} />
              </section>
            );
          })}
        </div>
      </div>
    );
  };
}


Tabs.propTypes = {
  activeTab: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.exact({
      guid: PropTypes.string.isRequired,
      content: TabContentWrapper.PropTypes,
      name: PropTypes.string.isRequired
    })
  ),
};

Tabs.defaultProps = {
  items: []
};


export default Tabs;
