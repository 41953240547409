import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Heading from 'components/heading';
import Metadata from 'components/metadata';
import Message from 'components/message';
import Tags from 'components/tags';
import Status from 'components/status/status';
import Icon from 'components/icon';
import useIsScreenSize from 'js/hooks/use-is-screen-size';

const Proposal = ({
  dateLabel,
  date,
  mobileDate,
  year,
  status,
  title,
  metadata,
  text,
  tags,
  isInternational,
  url,
  messageList,
  headingLevel
}) => {
  const isDesktop = useIsScreenSize('md');

  return (
    <div className="proposal">
      <div className="proposal--left">
        <div className={cn('proposal--date', status.theme)}>
          <div className="proposal--date-label">{dateLabel}</div>
          <time 
            datetime={date}
            className="proposal--date-date">
            {isDesktop ? date : mobileDate}
          </time>
          <time  
            datetime={year}
            className="proposal--date-year">{year}</time>
        </div>
        {isInternational && (
          <div>
            <Icon className="proposal--globe-icon" name="globe" />
          </div>
        )}
      </div>
      <div className="proposal--right">
        {status && <Status {...status} />}
        <a className="proposal--link" href={url}>
          <Heading className="proposal--title" level={headingLevel}>
            <span>{title}</span>
          </Heading>
        </a>
        {messageList &&
          messageList.map(message => (
            <div key={message.text.text} className="date-card--message">
              <Message {...message}></Message>
            </div>
          ))}
        {metadata && (
          <Metadata {...metadata} theme={Metadata.themes.vertical} />
        )}
        <p className="proposal--text">{text}</p>
        {tags && <Tags {...tags} />}
      </div>
    </div>
  );
};

Proposal.propTypes = {
  id: PropTypes.string,
  dateLabel: PropTypes.string,
  date: PropTypes.string,
  mobileDate: PropTypes.string,
  year: PropTypes.string,
  metadata: PropTypes.exact(Metadata.propTypes),
  status: PropTypes.exact(Status.propTypes),
  tags: PropTypes.exact(Tags.propTypes),
  text: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string, // How does link work?
  isInternational: PropTypes.bool,
  messageList: PropTypes.arrayOf(PropTypes.exact(Message.propTypes)),
  headingLevel: PropTypes.number
};

Proposal.propTypesMeta = {
  headingLevel: 'exclude'
}

export default Proposal;
