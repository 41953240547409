import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import useOnIntersect from 'js/hooks/use-on-intersect';
import getNewSrc from 'js/responsive-images';

import OPE from 'js/on-page-editing';

const Image = ({
  alt,
  className,
  initialSize,
  onPageEditing,
  responsive,
  src
}) => {
  const image = React.useRef();
  const [currentSrc, setSrc] = React.useState(undefined);

  useOnIntersect(image, () => {
    const parentNode = image.current.parentElement;

    if (responsive) {
      setSrc(getNewSrc(src, parentNode.offsetWidth || initialSize));
    } else {
      setSrc(src);
    }
  });

  return (
    <img
      className={cn('image', className)}
      src={currentSrc}
      alt={alt}
      ref={image}
      {...OPE(onPageEditing.image)}
    />
  );
};

Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  initialSize: PropTypes.number,
  onPageEditing: PropTypes.exact({
    image: PropTypes.string
  }),
  responsive: PropTypes.bool,
  src: PropTypes.string
};

Image.propTypesMeta = {
  className: 'exclude',
  initialSize: 'exclude',
  responsive: 'exclude'
};

Image.defaultProps = {
  initialSize: 300,
  onPageEditing: {},
  responsive: true
};

export default Image;
