//Custom query string generator
//Takes an endpoint, query, and object with list values and makes a query string with duplicate keys.
//params={"subjects": [1,2], "groups": [1]} --> "&subjects=1&subjects=2&groups=1"

export default params => {
  let result = '';

  params &&
    Object.keys(params).map(key => {
      if (Array.isArray(params[key])) {
        params[key].map(param => {
          result = result.concat(`&${key}=${param}`);
        });
      } else {
        result = result.concat(`&${key}=${params[key]}`);
      }
    });

  const withoutInitialAnd = result.substring(1);
  return withoutInitialAnd;
};
