import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components-old/icon';
import LinkWrapper from 'components-old/link-wrapper/link-wrapper';

const supportedIcons = {
  slideshow: { name: 'slideshow', fill: true },
  video: { name: 'video', fill: true },
  camera: { name: 'camera', fill: false }
};

const DateCardMedia = ({ items }) => (
  <div className={cn('date-card-media')}>
    {items.map(({ url, icon, text }) => {
      const { OuterLink } = LinkWrapper(url, url);
      return (
        <OuterLink
          className={cn('date-card-media-item', {
            '-theme-black': icon === 'camera'
          })}
          key={icon}
          title={text}
          href={url}
        >
          <span className="date-card-media-icon">
            <Icon {...supportedIcons[icon]} />
          </span>
          {text && <span className="date-card-media-text">{text}</span>}
        </OuterLink>
      );
    })}
  </div>
);

DateCardMedia.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      text: PropTypes.string,
      icon: PropTypes.oneOf(Object.keys(supportedIcons)).isRequired,
      url: PropTypes.string
    })
  )
};

DateCardMedia.defaultProps = {
  items: []
};

export default DateCardMedia;
