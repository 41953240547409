import { useCallback, useEffect, useRef } from 'react';

// Calls callback() whenever focus changes
// with a bool of wether focus is inside the reference
const useOnFocusInsideChange = (callback, toggleListener) => {
  const ref = useRef();

  const onAnyFocus = useCallback(() => {
    if (!ref.current) return;
    const isFocusInsideLink = ref.current.contains(document.activeElement);
    callback(isFocusInsideLink);
  }, []);

  useEffect(() => {
    if (toggleListener || toggleListener === undefined)
      document.addEventListener('focusin', onAnyFocus);
    else toggleListener && document.removeEventListener('focusin', onAnyFocus);

    return () => document.removeEventListener('focusin', onAnyFocus);
  }, [toggleListener]);

  return ref;
};

export default useOnFocusInsideChange;
