import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';

import Button from 'components-old/button';
import FluidImage from 'components-old/fluid-image';
import Link from 'components-old/link';

const themes = {
  darkBlue: '-theme-dark-blue',
  greyBlue: '-theme-grey-blue',
  purple: '-theme-purple'
};

const FrontpageHeader = ({ image, title, links, onPageEditing, theme }) => {
  return (
    <div className={cn('frontpage-header', theme)}>
      <div className="frontpage-header--prioritised-content">
        {title && <h2 className="frontpage-header--title">{title}</h2>}
        <div className="frontpage-header--links" {...OPE(onPageEditing.links)}>
          {links.map((link, index) => (
            <Link
              className="frontpage-header--link"
              key={`${link.url}-${index}`}
              useButtonStyles
              theme={[
                link.isPrimary
                  ? Button.themes.linkPrimary
                  : Button.themes.linkSecondary
              ]}
              {...link}
            />
          ))}
        </div>
      </div>
      <div className="frontpage-header--image">
        <FluidImage {...image} />
      </div>
    </div>
  );
};

FrontpageHeader.propTypes = {
  image: PropTypes.exact(FluidImage.propTypes),
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  onPageEditing: PropTypes.exact({
    links: PropTypes.string,
    numbers: PropTypes.string,
    text: PropTypes.string
  }),
  title: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes))
};

FrontpageHeader.defaultProps = {
  links: {},
  numbers: [],
  onPageEditing: {},
  theme: '-theme-dark-blue'
};

export default FrontpageHeader;
