import React from 'react';
import PropTypes from 'prop-types';

import ContentArea from 'components-old/content-area';
import Heading from 'components-old/heading';

const ContactBlock = ({ items, onPageEditing, title }) => (
  <div className="contact-block">
    {title && <Heading onPageEditing={onPageEditing.title}>{title}</Heading>}
    <ContentArea {...items} />
  </div>
);

ContactBlock.propTypes = {
  items: PropTypes.exact(ContentArea.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),
  title: PropTypes.string
};

ContactBlock.defaultProps = {
  items: [],
  onPageEditing: {}
};

export default ContactBlock;
