/* eslint-disable react/no-multi-comp */

import React from 'react';
import PropTypes from 'prop-types';

import ContentArea from 'components-old/content-area';
import Link from 'components-old/link';
import RichText from 'components-old/rich-text';
import RichTextBlock from 'components-old/rich-text-block';
import Button from 'components-old/button/button';
import Process from 'components-old/process';
import DownloadList from 'components-old/download-list';

const textList = (texts, headingLevel) =>
  texts.length > 0
    ? texts.map(text => (
        <div className="tab-content-section--text" key={text.title}>
          <RichTextBlock headingLevel={headingLevel} {...text} />
        </div>
      ))
    : null;

const TabContentSection = ({
  content,
  contentDescription,
  downloadList,
  ingress,
  application,
  moreContent,
  moreTexts,
  title,
  texts,
  process,
  htmlId
}) => {
  return (
    <div className="tab-content-section">
      {title && (
        <h2
          id={htmlId}
          data-epi-type="title"
          className="tab-content-section-title"
        >
          {title}
        </h2>
      )}
      {ingress && (
        <RichText className="tab-content-section--ingress" {...ingress} />
      )}
      {process && <Process {...process} />}
      {textList(texts, 2)}
      {contentDescription && (
        <div className="tab-content-section--description">
          <RichTextBlock {...contentDescription} />
        </div>
      )}
      {content && (
        <div data-epi-type="content">
          <ContentArea
            className="tab-content-section--content"
            additionalComponentProps={{
              DescriptionListBlock: { headingLevel: 3 },
              TableBlock: { headingLevel: 3 }
            }}
            {...content}
          />
        </div>
      )}
      {textList(moreTexts)}
      {application && (
        <div className="tab-content-section--text">
          {application.title && <h2>{application.title}</h2>}
          <p>{application.text}</p>
          {application.link && (
            <Link
              className="tab-content-section--link"
              theme={Button.themes.fill}
              {...application.link}
              useButtonStyles
            />
          )}
        </div>
      )}
      {downloadList && (
        <DownloadList
          className="tab-content-section--downloads"
          {...downloadList}
        />
      )}
      {moreContent && (
        <div data-epi-type="content">
          <ContentArea
            className="tab-content-section--content"
            {...moreContent}
          />
        </div>
      )}
    </div>
  );
};

TabContentSection.propTypes = {
  content: PropTypes.exact(ContentArea.propTypes),
  contentDescription: PropTypes.exact(RichTextBlock.propTypes),
  downloadList: PropTypes.exact(DownloadList.propTypes),
  ingress: PropTypes.exact(RichText.propTypes),
  moreContent: PropTypes.exact(ContentArea.propTypes),
  moreTexts: PropTypes.arrayOf(PropTypes.exact(RichTextBlock.propTypes)),
  title: PropTypes.string,
  texts: PropTypes.arrayOf(PropTypes.exact(RichTextBlock.propTypes)),
  process: PropTypes.exact(Process.propTypes),
  htmlId: PropTypes.string,
  application: PropTypes.exact({
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.exact(Link.propTypes)
  })
};

TabContentSection.defaultProps = {
  moreTexts: [],
  texts: []
};

export default TabContentSection;
