import React from 'react';
import PropTypes from 'prop-types';

import OPE from 'js/on-page-editing';

import ContentArea from 'components-old/content-area';
import ContentContainer from 'components-old/content-container';
import Link from 'components-old/link';
import LinkWithText from 'components-old/link-with-text';

const PageLinksBlock = ({
  highlightedLinks,
  linkLists,
  onPageEditing,
  text,
  title
}) => {
  const linkTheme = [Link.themes.orangeIcon, Link.themes.inlineIcon];

  return (
    <div className="page-links-block">
      <ContentContainer className="page-links-block--content">
        <div className="page-links-block--header">
          {title && <h2 {...OPE(onPageEditing.title)}>{title}</h2>}
          <p {...OPE(onPageEditing.text)}>{text}</p>
        </div>
        <ContentArea
          className="page-links-block--links"
          enableElementSizing={false}
          additionalComponentProps={{
            LinkWithText: { linkTheme, theme: LinkWithText.themes.big }
          }}
          theme={ContentArea.themes.threeColumns}
          {...highlightedLinks}
        />
        {linkLists &&
          linkLists.map(linkList => (
            <ContentArea
              additionalComponentProps={{ LinkWithText: { linkTheme } }}
              className="page-links-block--links"
              enableElementSizing={false}
              key={linkList.id}
              theme={ContentArea.themes.fourColumns}
              {...linkList.links}
            />
          ))}
      </ContentContainer>
    </div>
  );
};

PageLinksBlock.propTypes = {
  highlightedLinks: PropTypes.exact(ContentArea.propTypes),
  linkLists: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string,
      links: PropTypes.exact(ContentArea.propTypes)
    })
  ),
  onPageEditing: PropTypes.exact({
    text: PropTypes.string,
    title: PropTypes.string
  }),
  text: PropTypes.string,
  title: PropTypes.string
};

PageLinksBlock.defaultProps = {
  linkLists: [],
  onPageEditing: {}
};

export default PageLinksBlock;
