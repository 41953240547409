import React from 'react';
import PropTypes from 'prop-types';

import ContentArea from 'components-old/content-area';
import ContentAreaItem from 'components-old/content-area-item';
import SearchResult from 'components-old/search-result';

const SearchResultGroup = ({ htmlId, results, title }) => (
  <div className="search-result-group">
    <div className="search-result-group--title" id={htmlId}>
      {title && <h2>{title}</h2>}
    </div>
    <div className="search-result-group--items">
      <ContentArea
        additionalItemProps={{
          SearchResult: {
            theme: ContentAreaItem.themes.noMargin
          },
          EmployeeSearchResult: {
            theme: ContentAreaItem.themes.noMargin
          }
        }}
        additionalComponentProps={{
          SearchResult: {
            headingLevel: 2,
            theme: SearchResult.themes.darkText
          }
        }}
        {...results}
      />
    </div>
  </div>
);

SearchResultGroup.defaultProps = {
  results: []
};

SearchResultGroup.propTypes = {
  htmlId: PropTypes.string,
  results: PropTypes.exact(ContentArea.propTypes),
  title: PropTypes.string
};

export default SearchResultGroup;
