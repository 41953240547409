import React from 'react';
import PropTypes from 'prop-types';

import ArticleHeader from 'components-old/article-header';
import ContentWithSidebar from 'components-old/content-with-sidebar';
import PageNavigation from 'components-old/page-navigation';
import NestedMenu from 'components-old/nested-menu';
import RichText from 'components-old/rich-text';
import FluidImage from 'components-old/fluid-image';
import MetadataList from 'components-old/metadata-list';

const LargeArticlePage = ({
  title,
  image,
  content,
  header,
  pageNavigation,
  nestedMenu,
  metadata,
  isFrontPage
}) => {
  return (
    <>
      {image && (
        <div className="large-article-page--image">
          <FluidImage {...image} />
        </div>
      )}
      <ContentWithSidebar
        theme={ContentWithSidebar.themes.sidebarLeft}
        className="large-article-page--content"
        useContentContainer={false}
      >
        <ContentWithSidebar.Sidebar className="large-article-page--sidebar">
          <NestedMenu {...nestedMenu} />
          {metadata && <MetadataList {...metadata} />}
        </ContentWithSidebar.Sidebar>
        <ContentWithSidebar.Content className="large-article-page--content-main">
          {title && <h1 className="large-article-page--title">{title}</h1>}
          <RichText {...content} />
          {pageNavigation && (
            <PageNavigation
              {...pageNavigation}
              isFirstPage={isFrontPage}
              isLargeArticle
            />
          )}
          {header && <ArticleHeader {...header} isLargeArticle />}
        </ContentWithSidebar.Content>
      </ContentWithSidebar>
    </>
  );
};

LargeArticlePage.propTypes = {
  title: PropTypes.string,
  image: PropTypes.exact(FluidImage.propTypes),
  content: PropTypes.exact(RichText.propTypes).isRequired,
  header: PropTypes.exact(ArticleHeader.propTypes),
  pageNavigation: PropTypes.exact(PageNavigation.propTypes),
  nestedMenu: PropTypes.exact(NestedMenu.propTypes).isRequired,
  metadata: PropTypes.exact(MetadataList.propTypes),
  isFrontPage: PropTypes.bool
};

export default LargeArticlePage;
