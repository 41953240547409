import { useEffect, useState, useCallback } from 'react';

const clamp = value => Math.max(Math.min(1, value), 0);

const getCursorPositions = e => ({
  cursorX: e.changedTouches ? e.changedTouches[0].clientX : e.clientX,
  cursorY: e.changedTouches ? e.changedTouches[0].clientY : e.clientY
});

const useSlider = (trackRef, initialValue, isVertical) => {
  const [value, setValue] = useState(initialValue);
  const [isActive, setIsActive] = useState();

  const onMove = useCallback(e => {
    e.preventDefault();
    const { cursorX, cursorY } = getCursorPositions(e);
    const track = trackRef.current;
    const trackLength = isVertical ? track.offsetHeight : track.offsetWidth;
    const cursorDistancePxs = isVertical
      ? track.getBoundingClientRect().y - cursorY + trackLength
      : cursorX - track.getBoundingClientRect().x;

    const cursorDistanceDecimal = (1 / trackLength) * cursorDistancePxs;

    const cursorDistanceClamped = clamp(cursorDistanceDecimal);

    setValue(cursorDistanceClamped);
  }, []);

  const onKeyDown = useCallback(e => {
    if ([37, 38, 39, 40].indexOf(e.keyCode) > -1) e.preventDefault();
    const inc = () => setValue((prevState = 0) => clamp(prevState + 0.05));
    const dec = () => setValue((prevState = 0) => clamp(prevState - 0.05));
    switch (e.keyCode) {
      case 39:
        return inc();
      case 38:
        return inc();
      case 37:
        return dec();
      case 40:
        return dec();
    }
  }, []);

  const activateKeys = () => {
    window.addEventListener('keydown', onKeyDown);
  };

  const deactivateKeys = () => {
    window.removeEventListener('keydown', onKeyDown);
  };

  const deactivate = useCallback(e => {
    onMove(e); // Ensure movement in case user only clicks slider without moving the cursor
    window.removeEventListener('mousemove', onMove);
    window.removeEventListener('touchmove', onMove);
    window.removeEventListener('mouseup', deactivate);
    window.removeEventListener('touchend', deactivate);
    window.removeEventListener('keydown', onKeyDown);

    setIsActive(false);
  }, []);

  const activate = () => {
    window.addEventListener('mousemove', onMove);
    window.addEventListener('touchmove', onMove, { passive: false }); // Passive prevents scrolling
    window.addEventListener('mouseup', deactivate);
    window.addEventListener('touchend', deactivate);
    window.addEventListener('keydown', onKeyDown);

    setIsActive(true);
  };

  useEffect(
    () => () => {
      window.removeEventListener('mousemove', onMove);
      window.removeEventListener('touchmove', onMove);
      window.removeEventListener('mouseup', deactivate);
      window.removeEventListener('touchend', deactivate);
      window.removeEventListener('keydown', onKeyDown);
    },
    []
  );

  return {
    value,
    activate,
    activateKeys,
    deactivate,
    deactivateKeys,
    isActive
  };
};

export default useSlider;
