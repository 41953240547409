import React from 'react';
import PropTypes from 'prop-types';

import OPE from 'js/on-page-editing';

import Link from 'components-old/link';

const ContactInfo = ({ details, onPageEditing, title }) => (
  <div className="contact-info">
    <h3 {...OPE(onPageEditing.title)}>{title}</h3>
    <ul>
      {details.map(detail => (
        <li key={detail.text}>
          <Link {...detail} />
        </li>
      ))}
    </ul>
  </div>
);

ContactInfo.propTypes = {
  details: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),
  title: PropTypes.string.isRequired
};

ContactInfo.propTypesMeta = {
  theme: 'exclude'
};

ContactInfo.defaultProps = {
  onPageEditing: {},
  details: []
};

export default ContactInfo;
