import React from 'react';

const resizeHook = callback => {
  React.useEffect(() => {
    callback();
    window.addEventListener('resize', callback);

    return () => {
      window.removeEventListener('resize', callback);
    };
  }, []);
};

export default resizeHook;
