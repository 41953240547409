import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'components-old/heading/heading';
import PortfolioTile from 'components-old/portfolio-tiles/portfolio-tile';

const PortfolioHeader = ({ title, theme }) => {
  return (
    <PortfolioTile className="portfolio-header" theme={theme}>
      <Heading level={1}>{title}</Heading>
    </PortfolioTile>
  );
};

PortfolioHeader.propTypes = {
  title: PropTypes.string,
  theme: PortfolioTile.propTypes.theme
};

export default PortfolioHeader;
