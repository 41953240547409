import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Accordion from 'components-old/accordion';
import Byline from 'components-old/byline';
import Link from 'components-old/link';
import OptionsModal from 'components-old/options-modal';

const ArticleHeader = ({
  accordion,
  byline,
  download,
  share,
  isLargeArticle
}) => {
  return (
    <div
      className={cn('article-header', { '-is-large-article': isLargeArticle })}
    >
      {!!byline.items.length && (
        <React.Fragment>
          {/* Mobile version */}
          {accordion ? (
            <Accordion {...accordion}>
              {({ Button, Collapse, isOpen, toggle }) => (
                <div className="article-header--byline -is-mobile">
                  <div className="article-header--byline-header">
                    <p onClick={toggle}>{byline.items[0].text}</p>
                    <Button
                      className={cn('article-header--toggle', {
                        '-is-active': isOpen
                      })}
                      icon="micro-arrow-down"
                    />
                  </div>
                  <Collapse>
                    <div className="article-header--byline-content">
                      <Byline items={byline.items.slice(1)} />
                      {share && (
                        <div className="article-header--links">
                          <OptionsModal {...share}></OptionsModal>
                        </div>
                      )}
                      {download && (
                        <div className="article-header--download-label">
                          <Link {...download} icon="download-small" />
                        </div>
                      )}
                    </div>
                  </Collapse>
                </div>
              )}
            </Accordion>
          ) : (
            <div className="article-header--byline -is-mobile">
              <div className="article-header--byline-content">
                <Byline items={byline.items.slice(1)} />
                {download && (
                  <div className="article-header--download-label">
                    <Link {...download} icon="download-small" />
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Desktop version */}
          <div className="article-header--byline -is-desktop">
            <Byline {...byline} />

            <div className="article-header--links">
              {share && <OptionsModal {...share}></OptionsModal>}
              {download && (
                <div className="article-header--download-link">
                  <Link {...download} icon="download-small" />
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

ArticleHeader.propTypes = {
  accordion: PropTypes.exact(Accordion.propTypes),
  byline: PropTypes.exact(Byline.propTypes),
  download: PropTypes.exact(Link.propTypes),
  share: PropTypes.exact(OptionsModal.propTypes),
  isLargeArticle: PropTypes.bool
};

ArticleHeader.defaultProps = {
  byline: {
    items: []
  }
};

ArticleHeader.propTypesMeta = {
  isLargeArticle: 'exclude'
};

export default ArticleHeader;
