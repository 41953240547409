import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';

import Image from 'components/image';

const PersonBlock = ({
  company,
  image,
  jobTitle,
  name,
  onPageEditing,
  text
}) => (
  <div className={cn('person-block', { '-has-image': image })}>
    {image && (
      <div className="person-block--image">
        <Image {...image} />
      </div>
    )}

    {name && <h3 {...OPE(onPageEditing.name)}>{name}</h3>}
    <p className="person-block--job-title">
      <span {...OPE(onPageEditing.jobTitle)}>{jobTitle}</span>
      <span {...OPE(onPageEditing.company)}>{company}</span>
    </p>
    <p className="person-block--text" {...OPE(onPageEditing.text)}>
      {text}
    </p>
  </div>
);

PersonBlock.propTypes = {
  company: PropTypes.string,
  image: PropTypes.exact(Image.propTypes),
  jobTitle: PropTypes.string,
  name: PropTypes.string,
  onPageEditing: PropTypes.exact({
    company: PropTypes.string,
    jobTitle: PropTypes.string,
    name: PropTypes.string,
    text: PropTypes.string
  }),
  text: PropTypes.string
};

PersonBlock.defaultProps = {
  onPageEditing: {}
};

export default PersonBlock;
