import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components-old/link';

const OfficeBlockInfo = ({ link, moreText, text, title }) => (
  <div className="office-block-info">
    {title && <h3>{title}</h3>}
    {text && <p>{text}</p>}
    {moreText && <p>{moreText}</p>}
    {link && (
      <Link theme={[Link.themes.white, Link.themes.underline]} {...link} />
    )}
  </div>
);

OfficeBlockInfo.propTypes = {
  link: PropTypes.exact(Link.propTypes),
  moreText: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default OfficeBlockInfo;
