import React from 'react';
import PropTypes from 'prop-types';
import ContentArea from 'components/content-area';
import Heading from 'components/heading';

const PartnerLogoBlock = ({ items, onPageEditing, title }) => (
  <div className="partner-logo-block">
    {title && (
      <Heading onPageEditing={onPageEditing.title}>
        {title}
      </Heading>
    )}
    <ContentArea {...items} />
  </div>
);

PartnerLogoBlock.propTypes = {
    items: PropTypes.exact(ContentArea.propTypes),
    onPageEditing: PropTypes.exact({
      title: PropTypes.string
    }),
    title: PropTypes.string
  };
  
  PartnerLogoBlock.defaultProps = {
    items: [],
    onPageEditing: {}
  };
  
  export default PartnerLogoBlock;
  