import React from 'react';
import PropTypes from 'prop-types';

import Accordion from 'components-old/accordion';
import ContentContainer from 'components-old/content-container';
import Icon from 'components-old/icon';
import Link from 'components-old/link';
import Message from 'components-old/message';

const Breadcrumbs = ({
  accordion,
  currentPage,
  items,
  translationLabel,
  root
}) => {
  return (
    <Accordion showButtonText={true} {...accordion}>
      {({ Button, Collapse }) => (
        <nav className="breadcrumbs" data-epi-type="content">
          <ContentContainer className="breadcrumbs--header">
            <Button
              className={`breadcrumbs--toggle ${
                translationLabel ? 'adjust-width' : ''
              }`}
            >
              <Icon name="house" fill={true} />
            </Button>
            {translationLabel && (
              <Message
                text={{ text: translationLabel }}
                className={'is-translation-label'}
              />
            )}
          </ContentContainer>

          <Collapse className="breadcrumbs--collapse">
            <ContentContainer element="ul" theme={ContentContainer.themes.wide}>
              <li>
                <a className="breadcrumbs--root" href={root.url}>
                  <Icon name="house" fill={true} />
                  <span>{root.text}</span>
                </a>
              </li>

              {items.map(item => (
                <li key={item.url || item.text}>
                  <Link {...item} />
                </li>
              ))}

              {currentPage && <li key={currentPage}>{currentPage}</li>}

              {translationLabel && (
                <li className="-right-most" key={translationLabel}>
                  <Message
                    text={{ text: translationLabel }}
                    className={'is-translation-label'}
                  />
                </li>
              )}
            </ContentContainer>
          </Collapse>
        </nav>
      )}
    </Accordion>
  );
};

Breadcrumbs.propTypes = {
  accordion: PropTypes.exact(Accordion.propTypes),
  currentPage: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  translationLabel: PropTypes.string,
  root: PropTypes.exact(Link.propTypes)
};

Breadcrumbs.defaultProps = {
  items: [],
  root: {}
};

export default Breadcrumbs;
