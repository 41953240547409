import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';

import FluidImage from 'components/fluid-image';
import Heading from 'components/heading';

const ImageWithLink = ({
  image,
  onPageEditing,
  title,
  text,
  url,
  shareImageHeight,
  isProminent
}) => (
  <a className="image-with-link" href={url}>
    <div className="image-with-link--image">
      <div
        className={cn('image-with-link--image-inner', {
          '-hover-effect-active': Object.keys(onPageEditing).length === 0,
          '--absolute-height': shareImageHeight
        })}
      >
        <FluidImage {...image} theme={[FluidImage.themes.mosaic, FluidImage.themes.rounded]} />
      </div>
    </div>
    { onPageEditing.url && <div {...OPE(onPageEditing.url)} />}
    {(title || text || onPageEditing.title || onPageEditing.text) && (
      <div {...OPE(onPageEditing.title)} className={`image-with-link--description ${isProminent ? "dark-version" : ""}`}>
        {(title || onPageEditing.title) && <Heading className="image-with-link--title">{title}</Heading>}
        {(text || onPageEditing.text) && <p className="image-with-link--text">{text}</p>}
      </div>
    )}
  </a>
);

ImageWithLink.propTypes = {
  image: PropTypes.exact(FluidImage.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string,
    url: PropTypes.string
  }),
  title: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  shareImageHeight: PropTypes.bool,
  isProminent: PropTypes.bool
};

ImageWithLink.defaultProps = {
  onPageEditing: {},
  isProminent: false
};

export default ImageWithLink;
