import React from 'react';
import PropTypes from 'prop-types';
import SatelliteHeader from 'components-old/satellite-header';
import ContentArea from 'components-old/content-area';
import ContentContainer from 'components-old/content-container';
import RichTextBlock from 'components-old/rich-text-block';
import HtmlString from 'components-old/html-string';

const narrowTextProps = {
  HtmlString: { theme: HtmlString.themes.narrow },
  RichTextBlock: { theme: RichTextBlock.themes.narrow }
};

const SatelliteFrontpage = ({ satelliteHeader, content }) => {
  return (
    <div className="satellite-frontpage">
      <SatelliteHeader {...satelliteHeader} />
      <ContentContainer className="satellite-page--content">
        <ContentArea
          additionalComponentProps={{
            ...narrowTextProps,
            // NOTE: RichText is just a proxy for ContentArea, so themes need to be applied using additionalComponentProps
            RichText: { additionalComponentProps: narrowTextProps }
          }}
          {...content}
        />
      </ContentContainer>
    </div>
  );
};

SatelliteFrontpage.propTypes = {
  satelliteHeader: PropTypes.exact(SatelliteHeader.propTypes),
  content: PropTypes.exact(ContentArea.propTypes)
};

export default SatelliteFrontpage;
