const ChatbotState = {
  Open: 'open',
  Closed: 'closed',
  Minimized: 'minimized'
};

const showChatbot = (iframeContainer, openBtn) => {
  iframeContainer.style.display = 'flex';
  openBtn.style.display = 'none';
};

const hideChatbot = (iframeContainer, openBtn) => {
  iframeContainer.style.display = 'none';
  openBtn.style.display = 'flex';
};

const minimizeChatbot = (iframeContainer, openBtn) => {
  iframeContainer.classList.toggle('minimized');
  openBtn.style.display = 'none';
  iframeContainer.style.display = 'flex';
};

const loadChatbot = async () => {
  const styleOptions = {
    accent: '#00809d',
    botAvatarBackgroundColor: '#FFFFFF',
    botAvatarInitials: '',
    userAvatarInitials: '',
    hideUploadButton: true // Hide the upload button
  };

  const norTokenEndpointURL = new URL(
    'https://5333f9735c914705936c651ffeb94a.0e.environment.api.powerplatform.com/powervirtualagents/botsbyschema/crf5c_nrfCopilotNo/directline/token?api-version=2022-03-01-preview'
  );

  const engTokenEndpointURL = new URL(
    'https://5333f9735c914705936c651ffeb94a.0e.environment.api.powerplatform.com/powervirtualagents/botsbyschema/crf5c_nrfCopilotEn/directline/token?api-version=2022-03-01-preview'
  );

  const locale = document.documentElement.lang || 'en';

  const tokenEndpointURL =
    locale === 'no' ? norTokenEndpointURL : engTokenEndpointURL;

  const apiVersion = tokenEndpointURL.searchParams.get('api-version');

  const [directLineURL, token] = await Promise.all([
    fetch(
      new URL(
        `/powervirtualagents/regionalchannelsettings?api-version=${apiVersion}`,
        tokenEndpointURL
      )
    )
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to retrieve regional channel settings.');
        }

        return response.json();
      })
      .then(({ channelUrlsById: { directline } }) => directline),
    fetch(tokenEndpointURL)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to retrieve Direct Line token.');
        }

        return response.json();
      })
      .then(({ token }) => token)
  ]);

  // eslint-disable-next-line no-undef
  const directLine = WebChat.createDirectLine({
    domain: new URL('v3/directline', directLineURL),
    token
  });

  const subscription = directLine.connectionStatus$.subscribe({
    next(value) {
      if (value === 2) {
        directLine
          .postActivity({
            localTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            locale,
            name: 'startConversation',
            type: 'event'
          })
          .subscribe();

        subscription.unsubscribe();
      }
    }
  });

  // eslint-disable-next-line no-undef
  WebChat.renderWebChat(
    { directLine, locale, styleOptions },
    document.getElementById('webchat')
  );

  const textInput = document.querySelector(
    'input.webchat__send-box-text-box__input'
  );

  const openBtn = document.querySelector('#openBtn');
  const inputPlaceholderText = openBtn.getAttribute('data-input-text');
  openBtn.removeAttribute('data-input-text');

  if (textInput && inputPlaceholderText) {
    textInput.placeholder = inputPlaceholderText;
  }
};

const init = () => {
  // Get the elements
  const openBtn = document.getElementById('openBtn');
  if (!openBtn) {
    return;
  }

  const closeBtn = document.getElementById('closeBtn');
  const minimizeBtn = document.getElementById('minimizeBtn');
  const iframeContainer = document.getElementById('iframeContainer');

  let chatbotInitialized = false;
  const chatbotState = localStorage.getItem('chatbotState');

  // When the open button is clicked
  openBtn.addEventListener('click', function () {
    showChatbot(iframeContainer, openBtn);
    localStorage.setItem('chatbotState', ChatbotState.Open);
  });

  // When the close button is clicked
  closeBtn.addEventListener('click', function () {
    hideChatbot(iframeContainer, openBtn);
    localStorage.setItem('chatbotState', ChatbotState.Closed);
  });

  minimizeBtn.addEventListener('click', function () {
    hideChatbot(iframeContainer, openBtn);
    localStorage.setItem('chatbotState', ChatbotState.Closed);
  });

  if (chatbotInitialized) {
    return;
  }

  if (chatbotState === ChatbotState.Open) {
    showChatbot(iframeContainer, openBtn);
  } else {
    hideChatbot(iframeContainer, openBtn);
  }

  loadChatbot();

  chatbotInitialized = true;
};

init();
