import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Link from 'components-old/link';
import Tooltip from 'components-old/tooltip';
import useOnFocusInsideChange from 'js/hooks/use-on-focus-inside-change';
import useIsScreenSize from 'js/hooks/use-is-screen-size';

const DatesMoreLink = ({ link, tooltip, isOpen }) => {
  const [showTooltip, setShowTooltip] = useState();
  const [showTabTooltip, setShowTabTooltip] = useState();
  const isMediumScreen = useIsScreenSize('md');
  const linkRef = useOnFocusInsideChange(toggleTabTooltip, isOpen);

  const openTooltip = () => {
    setShowTooltip(true);
    setShowTabTooltip(false);
  };

  const closeTooltip = useCallback(() => {
    setShowTabTooltip(false);
  }, []);

  function toggleTabTooltip(open) {
    if (open) {
      document.addEventListener('mousemove', closeTooltip);
      setShowTooltip(false);
      setShowTabTooltip(true);
    } else {
      setShowTabTooltip(false);
    }
  }

  return (
    <li
      className="dates-more-link"
      onMouseEnter={openTooltip}
      onMouseLeave={() => setShowTooltip(false)}
      ref={linkRef}
    >
      <Link {...link} />
      {tooltip && isMediumScreen && (showTabTooltip || showTooltip) && (
        <Tooltip {...tooltip} useTabStyle={showTabTooltip} />
      )}
    </li>
  );
};

DatesMoreLink.propTypes = {
  link: PropTypes.exact(Link.propTypes),
  tooltip: PropTypes.exact(Tooltip.propTypes)
};

export default DatesMoreLink;
