import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import csv from '../../assets-old/images/csv.svg';
import fallback from '../../assets-old/images/doc-fallback.svg';
import excel from '../../assets-old/images/excel.svg';
import image from '../../assets-old/images/image.svg';
import pdf from '../../assets-old/images/pdf.svg';
import ppt from '../../assets-old/images/ppt.svg';
import word from '../../assets-old/images/word.svg';

import csvHover from '../../assets-old/images/csv-hover.svg';
import fallbackHover from '../../assets-old/images/doc-fallback-hover.svg';
import excelHover from '../../assets-old/images/excel-hover.svg';
import imageHover from '../../assets-old/images/image-hover.svg';
import pdfHover from '../../assets-old/images/pdf-hover.svg';
import pptHover from '../../assets-old/images/ppt-hover.svg';
import wordHover from '../../assets-old/images/word-hover.svg';

import csvWhite from '../../assets-old/images/csv-white.svg';
import fallbackWhite from '../../assets-old/images/doc-fallback-white.svg';
import excelWhite from '../../assets-old/images/excel-white.svg';
import imageWhite from '../../assets-old/images/image-white.svg';
import pdfWhite from '../../assets-old/images/pdf-white.svg';
import pptWhite from '../../assets-old/images/ppt-white.svg';
import wordWhite from '../../assets-old/images/word-white.svg';

import Asterix from '../../assets-old/images/asterix.svg';

const sizes = {
  xsmall: '-size-xsmall',
  small: '-size-small',
  large: '-size-large',
  xlarge: '-size-xlarge'
};

const iconThemes = {
  word: { icon: word, hover: wordHover, white: wordWhite },
  pdf: { icon: pdf, hover: pdfHover, white: pdfWhite },
  ppt: { icon: ppt, hover: pptHover, white: pptWhite },
  csv: { icon: csv, hover: csvHover, white: csvWhite },
  excel: { icon: excel, hover: excelHover, white: excelWhite },
  image: { icon: image, hover: imageHover, white: imageWhite },
  fallback: { icon: fallback, hover: fallbackHover, white: fallbackWhite }
};

const types = {
  hover: 'hover',
  icon: 'icon',
  white: 'white'
};

const DocumentIcon = ({ iconTheme, size, type, isRequired }) => {
  const icon = iconThemes[iconTheme][type || 'icon'];
  const backgroundImage = { backgroundImage: `url(${icon})` };
  return (
    <div className="document-icon">
      <div
        className={cn('document-icon__icon', iconTheme, size)}
        style={backgroundImage}
      />
      {isRequired && (
        <div
          className="document-icon--asterix"
          style={{ backgroundImage: `url(${Asterix})` }}
        />
      )}
    </div>
  );
};

DocumentIcon.propTypes = {
  iconTheme: PropTypes.oneOf(Object.keys(iconThemes)),
  size: PropTypes.oneOf(Object.values(sizes)),
  type: PropTypes.oneOf(Object.values(types)),
  isRequired: PropTypes.bool
};

DocumentIcon.defaultProps = {
  iconTheme: 'fallback'
};

DocumentIcon.propTypesMeta = {
  size: 'exclude',
  type: 'exclude'
};

DocumentIcon.sizes = sizes;
DocumentIcon.types = types;

export default DocumentIcon;
