import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import OPE from 'js/on-page-editing';

const EventData = ({ items, onPageEditing, enableTabbing }) => (
  <dl className="event-data" {...OPE(onPageEditing.items)}>
    {items.map(item => (
      <div
        className={cn('event-data--item', {
          '-multiple-paragraphs': item.text[0].length > 1
        })}
        key={item.label}
        tabIndex={enableTabbing ? 0 : -1}
      >
        <dt>{`${item.label}:`}</dt>
        <dd>
          {item.text.map(paragraphs => (
            <p key={paragraphs[0]}>
              {paragraphs.map(line => (
                <span key={line}>{line}</span>
              ))}
            </p>
          ))}
        </dd>
      </div>
    ))}
  </dl>
);

EventData.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      text: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
    })
  ),
  onPageEditing: PropTypes.exact({
    items: PropTypes.string
  })
};

EventData.defaultProps = {
  items: [],
  onPageEditing: {}
};

export default EventData;
