import React from 'react';
import PropTypes from 'prop-types';

const inputUncontrolled = ({ checked, label, name, onChange, value, id }) => {
  return (
    <>
      <input
        type="checkbox"
        id={id}
        name={name}
        onChange={onChange}
        value={value}
        defaultChecked={checked}
      />
    </>
  );
};

inputUncontrolled.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string
};

inputUncontrolled.propTypesMeta = 'exclude';

export default inputUncontrolled;
