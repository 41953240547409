import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Link from 'components/link';

const Pagination = ({ pages, title }) =>
  pages.length > 0 ? (
    <nav className="pagination">
      {title && <h2>{title}</h2>}
      <ul>
        {pages.map(
          page =>
            page.link && (
              <li key={page.link.text}>
                <Link
                  className={cn('pagination--link', {
                    '-is-current': page.isCurrent
                  })}
                  attributes={{
                    'aria-label': page.label,
                    'aria-current': page.isCurrent ? 'page' : null
                  }}
                  {...page.link}
                />
              </li>
            )
        )}
      </ul>
    </nav>
  ) : null;

Pagination.propTypes = {
  pages: PropTypes.arrayOf(
    PropTypes.exact({
      isCurrent: PropTypes.bool,
      label: PropTypes.string.isRequired,
      link: PropTypes.exact(Link.propTypes)
    })
  ),
  title: PropTypes.string
};

Pagination.defaultProps = {
  pages: []
};

export default Pagination;
