/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import Accordion from 'components/accordion';
import Button from 'components/button';
import Link from 'components/link';

const Item = props => (
  // eslint-disable-next-line react/prop-types
  <div className="date-card-tags-item" key={props.text + props.url}>
    <Link theme={Link.themes.tag} {...props} />
  </div>
);

const DateCardTags = ({ accordion, items, numberOfVisibleItems }) => {
  if (!accordion) {
    return <div className="date-card-tags">{items.map(Item)}</div>;
  }

  const visibleItems = items.slice(0, numberOfVisibleItems);
  const hiddenItems = items.slice(numberOfVisibleItems);

  return (
    <div className="date-card-tags">
      {visibleItems.map(Item)}
      {hiddenItems.length > 0 && (
        <Accordion animate={false} showButtonText={true} {...accordion}>
          {({ Button: AccordionButton, Collapse }) => (
            <React.Fragment>
              <Collapse>{hiddenItems.map(Item)}</Collapse>

              <div className="date-card-tags-item">
                <AccordionButton
                  className="date-card-tags-button"
                  theme={Button.themes.link}
                />
              </div>
            </React.Fragment>
          )}
        </Accordion>
      )}
    </div>
  );
};

DateCardTags.propTypes = {
  accordion: PropTypes.exact(Accordion.propTypes),
  items: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  numberOfVisibleItems: PropTypes.number
};

DateCardTags.defaultProps = {
  items: [],
  numberOfVisibleItems: 4
};

export default DateCardTags;
