import React, { useEffect, useState, useRef } from 'react';
import Card from 'components/card';
import DatesMore from 'components/dates-more';
import PropTypes from 'prop-types';
import DataTagList from 'components/data-tag-list';
import Heading from 'components/heading';

const EventListItem = ({ eventCard, moreDates, date }) => {
  const [datesTextWidth, setDatesTextWidth] = useState();
  const dateTextRef = useRef();

  useEffect(() => {
    if (dateTextRef.current) {
      setDatesTextWidth(dateTextRef.current.offsetWidth);
    }
  }, []);

  return (
    <div className="event-list-item">
      <Card {...eventCard} isEvent />
      <div className="event-list-item--info-section">
        <div className="card-info-desktop">
          {eventCard.preTitle && (
            <div className="card--date">
              <span className="card--date-dot" />
                <time 
                  datetime={eventCard.preTitle}
                  className="card--date-text">{eventCard.preTitle}
                </time>
            </div>
          )}

          {eventCard.title && (
            <a className="card--title" href={eventCard.link.url}>
              <Heading level={2}>{eventCard.title}</Heading>
            </a>
          )}

          {eventCard.dataTagList &&
            <DataTagList list={eventCard.dataTagList} />
          }

          {eventCard.text && <p className="card--text">{eventCard.text}</p>}
        </div>
        <div className="date-section">
          {date && (
            <time 
              className="event-card-list--date" 
              ref={dateTextRef}
              datetime={date}
              >
              {date}
            </time>
          )}

          {moreDates && (
            <DatesMore
              className="event-list-item--dates-more"
              {...moreDates}
              style={{ left: `${datesTextWidth + 16}px` }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

EventListItem.propTypes = {
  eventCard: PropTypes.shape(Card.propTypes),
  moreDates: PropTypes.exact(DatesMore.propTypes),
  date: PropTypes.string
};

export default EventListItem;
