import isRunningOnClient from '@creuna/utils/is-running-on-client';

export default (breakpoints = {}) => {
  if (!isRunningOnClient) {
    return;
  }

  const activeBreakpoint = Object.entries(breakpoints)
    .filter(
      ([breakpoint]) => window.matchMedia(`(min-width: ${breakpoint})`).matches
    )
    .pop();

  if (activeBreakpoint) {
    const [, value] = activeBreakpoint;
    return value;
  }
};
