import React from 'react';
import PropTypes from 'prop-types';

const Hyphenate = ({ text }) => {
  const shouldHyphenate = /¤¤/.test(text);
  return text ? (
    shouldHyphenate ? (
      <span
        className="hyphenate"
        dangerouslySetInnerHTML={{ __html: text.replace(/¤¤/g, '&shy;') }}
      />
    ) : (
      text
    )
  ) : null;
};

Hyphenate.propTypes = {
  text: PropTypes.string
};

Hyphenate.propTypesMeta = 'exclude';

export default Hyphenate;
