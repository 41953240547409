import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import LinkList from 'components/link-list';
import Heading from 'components/heading';

const LinkListBlock = ({ className, htmlId, links, title }) => (
  <div className={cn('link-list-block', className)}>
    {title && <Heading id={htmlId}>{title}</Heading>}
    <LinkList withIcon={true} {...links} />
  </div>
);

LinkListBlock.propTypes = {
  className: PropTypes.string,
  htmlId: PropTypes.string,
  links: PropTypes.exact(LinkList.propTypes),
  title: PropTypes.string
};

LinkListBlock.propTypesMeta = {
  className: 'exclude'
};

export default LinkListBlock;
