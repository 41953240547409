import React from 'react';
import PropTypes from 'prop-types';

import propTypeTheme from 'utils/prop-type-theme';

import cn from 'classnames';
import Link from 'components/link';

const themes = {
  vertical: '-theme-vertical',
  verticalVacancies: '-theme-vertical -theme-vertical--vacancies'
};

const Metadata = ({ items, theme }) => (
  <dl className={cn('metadata', theme)}>
    {items.map(item => (
      <React.Fragment key={item.label + item.text}>
        <dt>{`${item.label}:`}</dt>
        <dd className={cn({ 'metadata--data--disabled': item.isDisabled })}>
          {item.url ? <Link url={item.url} text={item.text} /> : item.text}
        </dd>
      </React.Fragment>
    ))}
  </dl>
);

Metadata.defaultProps = {
  items: []
};

Metadata.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      text: PropTypes.string,
      url: PropTypes.string,
      isDisabled: PropTypes.bool
    })
  ),
  theme: propTypeTheme(themes)
};

Metadata.propTypesMeta = {
  theme: 'exclude'
};

Metadata.themes = themes;

export default Metadata;
