import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Step from 'components/nysgjerrigper/step';
import Path from 'components/nysgjerrigper/path';

import resizeHook from 'js/hooks/resize';

const mobilePoints = [
  [28, 10],
  [28, 25],
  [28, 40],
  [28, 55],
  [28, 70],
  [28, 85]
];
const desktopPoints = [
  [9, 28],
  [23, 68],
  [36, 48],
  [50, 20],
  [68, 55],
  [83, 36]
];

const desktopRelativeBeziers = [
  [150, -30, -250, 0],
  [0, -250, 0, -150],
  [0, -300, -50, -75],
  [250, -50, -200, 25],
  [0, -250, -50, -100]
];

const mobileRelativeBeziers = [
  [-100, 0, -78, 0],
  [51, 60, 0, -10],
  [-100, -10, -100, 30],
  [-75, -30, -80, 0],
  [30, 50, 20, -40]
];

const StepsMenu = ({ menu, activateStep, menuAnimationTrigger }) => {
  const [isDesktop, setIsDesktop] = useState(true);
  const [isMounted, setIsMounted] = useState();

  const isVerticallyStyled = menu.some(step => step.isActive) || !isDesktop;

  const onResize = () => {
    setIsDesktop(window.matchMedia('(min-width: 1024px)').matches);
  };

  resizeHook(onResize);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const [points, relativeBeziers] = isVerticallyStyled
    ? [mobilePoints, mobileRelativeBeziers]
    : [desktopPoints, desktopRelativeBeziers];

  const [hoverData, setHoverData] = React.useState({
    mousePos: [],
    hoverAnimation: undefined
  });

  return (
    <div className={cn('steps-menu', { '-vertical': isVerticallyStyled })}>
      {isMounted &&
        menu.map((step, i) => (
          <React.Fragment key={step.text}>
            <Step
              {...step}
              index={i}
              style={{ top: `${points[i][1]}%`, left: `${points[i][0]}%` }}
              setOnHoverData={setHoverData}
              isVerticallyStyled={isVerticallyStyled}
              activateStep={activateStep}
            />
            {i < menu.length - 1 && (
              <Path
                start={points[i]}
                relativeBeziers={relativeBeziers[i]}
                end={points[i + 1]}
                softAnimation={isVerticallyStyled}
                menuAnimationTrigger={menuAnimationTrigger}
                {...hoverData}
              />
            )}
          </React.Fragment>
        ))}
    </div>
  );
};

StepsMenu.propTypes = {
  menu: PropTypes.arrayOf(PropTypes.exact(Step.propTypes)),
  activateStep: PropTypes.func,
  menuAnimationTrigger: PropTypes.bool
};

StepsMenu.propTypesMeta = 'exclude';

export default StepsMenu;
