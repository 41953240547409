import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

import {
  getKeysFromLocalStorageThatMatchesPrefix,
  deleteFromLocalStorage
} from 'js/utils/local-storage';
import Message from 'components/message';
import ContentContainer from 'components/content-container';
import MessageCarousel from './message-carousel';
import HtmlString from 'components/html-string';
import Heading from 'components/heading';
import IconWarning from 'components/icon-warning';
import MessageGlobal from 'components/message-global';

const MessageGlobalList = ({
  messageGlobals = [],
  initiallyOpen,
  prefix,
  printDescription,
  defaultPrintMessage
}) => {
  const [globalMessageContainer, setGlobalMessageContainer] = useState(null);

  useEffect(() => {
    let keysFromLocalStorage = getKeysFromLocalStorageThatMatchesPrefix(prefix);
    deleteFromLocalStorage(keysFromLocalStorage, 7, 'DAYS');

    const div = document.createElement('div');

    const body = document.body;
    body.prepend(div);
    setGlobalMessageContainer(div);
  }, []);

  return (
    <div className="message-global-list">
      {globalMessageContainer &&
        createPortal(
          <MessageCarousel
            messageGlobals={messageGlobals}
            initiallyOpen={initiallyOpen}
          />,
          globalMessageContainer
        )}
      <div className="message-global-list--print">
        <ContentContainer>
          <div className="message-global-list--print-line" />
          {printDescription && (
            <p className="message-global-list--print-description">
              {printDescription}
            </p>
          )}
          {messageGlobals.length ? (
            messageGlobals.map(({ title, theme, message, id }) => (
              <div className="message-global-list--print-message" key={id}>
                <IconWarning
                  className="message-global--icon-warning"
                  theme={theme}
                />
                <div>
                  <Heading className="message-global--title" level={3}>
                    {title}
                  </Heading>
                  {message && <HtmlString {...message} />}
                </div>
              </div>
            ))
          ) : (
            <div
              className="message-global-list--print-message"
              key={defaultPrintMessage}
            >
              <Message
                text={{ text: defaultPrintMessage }}
                theme={Message.themes.gray}
              />
            </div>
          )}
        </ContentContainer>
      </div>
    </div>
  );
};

MessageGlobalList.propTypes = {
  messageGlobals: PropTypes.arrayOf(PropTypes.exact(MessageGlobal.propTypes)),
  initiallyOpen: PropTypes.bool,
  prefix: PropTypes.string,
  printDescription: PropTypes.string,
  defaultPrintMessage: PropTypes.string
};

export default MessageGlobalList;
