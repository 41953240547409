import React from 'react';
import PropTypes from 'prop-types';

import ArticleHeader from 'components-old/article-header';
import ContentWithSidebar from 'components-old/content-with-sidebar';
import PageNavigation from 'components-old/page-navigation';
import PageFooter from 'components-old/page-footer';
import TableOfContents from 'components-old/table-of-contents';
import RichText from 'components-old/rich-text';
import ContentArea from 'components-old/content-area';

const LargeDocumentsPage = ({
  content,
  footer,
  header,
  ingress,
  pageNavigation,
  tableOfContents,
  title,
  contentArea
}) => (
  <ContentWithSidebar
    theme={ContentWithSidebar.themes.sidebarLeft}
    className="large-documents-page"
  >
    <ContentWithSidebar.Sidebar>
      <TableOfContents {...tableOfContents} />
    </ContentWithSidebar.Sidebar>
    <ContentWithSidebar.Content className="large-documents-page--content">
      {title && <h1>{title}</h1>}
      <div className="large-documents-page--ingress">
        <p>{ingress}</p>
      </div>
      <ArticleHeader {...header} />
      <RichText {...content} />
      <ContentArea {...contentArea} />
      {pageNavigation && <PageNavigation {...pageNavigation} />}
      <div className="large-documents-page--footer">
        <PageFooter {...footer} />
      </div>
    </ContentWithSidebar.Content>
  </ContentWithSidebar>
);

LargeDocumentsPage.propTypes = {
  content: PropTypes.exact(RichText.propTypes),
  footer: PropTypes.exact(PageFooter.propTypes),
  header: PropTypes.exact(ArticleHeader.propTypes),
  ingress: PropTypes.string,
  pageNavigation: PropTypes.exact(PageNavigation.propTypes),
  tableOfContents: PropTypes.exact(TableOfContents.propTypes),
  title: PropTypes.string,
  contentArea: PropTypes.exact(ContentArea.propTypes)
};

export default LargeDocumentsPage;
