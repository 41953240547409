import React from 'react';
import PropTypes from 'prop-types';
import OPE from 'js/on-page-editing';
import FactBox from 'components/fact-box';

const AccordionListBlock = ({ list, title, onPageEditing }) => {
  return (
    <>
      {title && (
        <h2
          className="accordion-list-block__title"
          {...OPE(onPageEditing.title)}
        >
          {title}
        </h2>
      )}
      {list && (
        <ul className="accordion-list-block__list">
          {list.map((accordionBlock, idx) => (
            <li key={accordionBlock.accordion.guid}>
              <FactBox
                {...accordionBlock}
                transparentRows
                topBorder={idx === 0 && !title}
              />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

AccordionListBlock.propTypes = {
  list: PropTypes.arrayOf(PropTypes.exact(FactBox.propTypes)),
  title: PropTypes.string,
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  })
};

AccordionListBlock.defaultProps = {
  onPageEditing: {}
};

export default AccordionListBlock;
