import React from 'react';
import PropTypes from 'prop-types';
import ContentContainer from 'components/content-container';
import EmptyList from 'components/empty-list';
import FilterLayout from 'components/filter-layout';
import Form from 'components/form';
import Search from 'components/search';
import Pagination from 'components/pagination';
import SearchResult from 'components/search-result';
import Spinner from 'components/spinner';
import useFetchFilteredResults from 'js/hooks/use-fetch-filtered-results';

const SearchPage = ({
  emptyList,
  filterLayout,
  form,
  pagination,
  results,
  resultDescription,
  search,
  title,
  fetchFilteredResultsEndpoint
}) => {
  const [
    isLoading,
    {
      results: resultsState,
      pagination: paginationState,
      resultDescription: resultDescriptionState,
      emptyList: emptyListState,
      search: searchState,
      filterLayout: filterLayoutState
    },
    fetchResults
  ] = useFetchFilteredResults(
    {
      results,
      pagination,
      resultDescription,
      emptyList,
      search,
      filterLayout
    },
    fetchFilteredResultsEndpoint,
    form.endpoint,
    search.input
  );

  const topLine = (
    <div className="search-page--results">{resultDescriptionState}</div>
  );

  return (
    <Form className="search-page" showSubmitButton={false} {...form}>
      <ContentContainer>
        {title && <h1 className="search-page--title">{title}</h1>}
        <Search {...searchState} />
      </ContentContainer>
      <FilterLayout
        {...filterLayoutState}
        topContent={topLine}
        fetchFilteredResults={fetchResults}
        isLoading={isLoading}
      >
        <React.Fragment>
          {resultsState && resultsState.length > 0 ? (
            resultsState.map((result, index) => {
              return (
                <SearchResult key={result.title + String(index)} {...result} />
              );
            })
          ) : (
            <EmptyList {...emptyListState} />
          )}
          <Spinner isActive={isLoading} />
        </React.Fragment>
        <Pagination {...paginationState} />
      </FilterLayout>
    </Form>
  );
};

SearchPage.propTypes = {
  emptyList: PropTypes.exact(EmptyList.propTypes),
  filterLayout: PropTypes.exact(FilterLayout.propTypes),
  form: PropTypes.exact(Form.propTypes),
  pagination: PropTypes.exact(Pagination.propTypes),
  results: PropTypes.arrayOf(PropTypes.exact(SearchResult.propTypes)),
  resultDescription: PropTypes.string,
  search: PropTypes.exact(Search.propTypes),
  title: PropTypes.string,
  fetchFilteredResultsEndpoint: PropTypes.string
};

SearchPage.defaultProps = {
  results: []
};

export default SearchPage;
