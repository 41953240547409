import React, { useState } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';

import OptionsModal from 'components-old/options-modal';
import Accordion from 'components-old/accordion';
import IconWarning from 'components-old/icon-warning';
import ContentArea from 'components-old/content-area';
import Icon from 'components-old/icon';

const AccordionWithContentArea = ({
  content,
  accordion,
  onPageEditing,
  title,
  iconWarning,
  share,
  htmlId,
  initiallyOpen,
  addAnchorHash,
  removeAnchorHash
}) => {
  const isEditMode = Object.keys(onPageEditing).length > 0;

  const shouldExpand = () => {
    const isTargetedByAnchor = htmlId && `#${htmlId}` === window.location.hash;
    if (isTargetedByAnchor) {
      // Manually scroll if anchor-linked as expanding the accordion disrupts browsers anchor-link-scrolling.
      setTimeout(
        () => window.scrollTo(0, document.getElementById(htmlId).offsetTop),
        500
      );
    }
    const noAnchorAndOpen = !window.location.hash && initiallyOpen;
    if (htmlId) {
      if (isTargetedByAnchor) addAnchorHash(`#${htmlId}`);
      else if (noAnchorAndOpen) addAnchorHash(`#${htmlId}`);
    }
    return isTargetedByAnchor || noAnchorAndOpen;
  };

  return content ? (
    <Accordion
      {...accordion}
      expandOnMount={shouldExpand}
      showButtonText={false}
      buttonTextForLabel={title}
    >
      {({ Button, Collapse, isOpen, toggle }) => {
        const onClick = () => {
          toggle();
          if (!htmlId) return;
          const isOpening = !isOpen;
          isOpening
            ? addAnchorHash(`#${htmlId}`)
            : removeAnchorHash(`#${htmlId}`);
        };
        return (
          <div
            className="accordion-with-content-area--anchor-target"
            id={htmlId}
          >
            <div className="accordion-with-content-area">
              <Button
                className={cn('accordion-with-content-area--header', {
                  '-is-open': isOpen
                })}
                onClick={isEditMode ? () => {} : onClick}
              >
                {title && (
                  <div className="accordion-with-content-area__title-container">
                    {iconWarning && (
                      <IconWarning
                        className="accordion-with-content-area__title-icon"
                        {...iconWarning}
                        isColored
                      />
                    )}
                    <h3
                      className="accordion-with-content-area__title"
                      {...OPE(onPageEditing.title)}
                    >
                      {title}
                    </h3>
                  </div>
                )}

                <div className="accordion-with-content-area--button">
                  <Icon
                    className={cn('accordion-with-content-area--icon', {
                      '-is-open': isOpen
                    })}
                    name="small-arrow-down"
                  />
                </div>
              </Button>

              <Collapse>
                <div className="accordion-with-content-area--content">
                  <ContentArea
                    additionalComponentProps={{
                      TextWithSidebar: {
                        headingLevel: 4,
                        isProposalPage: true,
                        share: share
                      }
                    }}
                    className="accordion-with-content-area--content-area"
                    onPageEditing={onPageEditing}
                    id={title}
                    {...content}
                  />
                </div>
              </Collapse>
            </div>
          </div>
        );
      }}
    </Accordion>
  ) : null;
};

AccordionWithContentArea.propTypes = {
  content: PropTypes.exact(ContentArea.propTypes),
  accordion: PropTypes.exact(Accordion.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),
  title: PropTypes.string,
  iconWarning: PropTypes.exact(IconWarning.propTypes),
  share: PropTypes.exact(OptionsModal.propTypes),
  htmlId: PropTypes.string,
  initiallyOpen: PropTypes.bool,
  addAnchorHash: PropTypes.func,
  removeAnchorHash: PropTypes.func
};

AccordionWithContentArea.defaultProps = {
  onPageEditing: {},
  addAnchorHash: () => {},
  removeAnchorHash: () => {}
};

export default AccordionWithContentArea;
