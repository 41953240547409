import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import HtmlString from 'components/html-string';
import IconWarning from 'components/icon-warning';

const Message = forwardRef(
  ({ text, theme, className, disableHTMLStringTabbing }, ref) => (
    <div className={cn('message', theme, className)}>
      <div className="message--content">
        <div
          className={cn({
            'message--icon--hide': theme === IconWarning.themes.gray
          })}
        >
          <IconWarning
            theme={theme}
            size={
              className == 'is-translation-label'
                ? IconWarning.sizes.small
                : IconWarning.sizes.wide
            }
          />
        </div>
        <div
          className={
            className == 'is-translation-label'
              ? cn('message--small-text')
              : cn('message--text')
          }
          ref={ref}
        >
          <HtmlString {...text} disableTabbing={disableHTMLStringTabbing} />
        </div>
      </div>
    </div>
  )
);

Message.propTypes = {
  className: PropTypes.string,
  text: PropTypes.exact(HtmlString.propTypes),
  theme: IconWarning.propTypes.theme,
  disableHTMLStringTabbing: PropTypes.bool
};

Message.propTypesMeta = {
  className: 'exclude',
  disableHTMLStringTabbing: 'exclude'
};

Message.themes = IconWarning.themes;

export default Message;
