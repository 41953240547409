import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components-old/icon';

const themes = {
  education: 'category-apple',
  technologies: 'category-building',
  democracy: 'category-democracy',
  industry: 'category-factory',
  food: 'category-grain',
  welfare: 'category-handshake',
  health: 'category-heart',
  climate: 'category-iceberg',
  petroleum: 'category-oil-drum',
  humanities: 'category-people',
  science: 'category-science',
  nature: 'category-tree',
  energy: 'category-windmill',
  global: 'category-globe',
  ocean: 'category-ocean',
  sami: 'category-sami'
};

const PortfolioTile = ({ theme, className, children }) => (
  <div className={cn('portfolio-tile', className, theme)}>
    {children}
    <Icon name={theme} />
  </div>
);

PortfolioTile.propTypes = {
  theme: PropTypes.oneOf(Object.values(themes)),
  children: PropTypes.node,
  className: PropTypes.string
};

PortfolioTile.propTypesMeta = {
  className: 'exclude'
};

export default PortfolioTile;
