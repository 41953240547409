import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image/image';
import Link from 'components/link/link';

const StatsLink = ({ image, title, link }) => (
  <div className="stats-link">
    <div className="stats-link--top">
      <Image className="stats-link--image" {...image} />
      {title && <h2 className="stats-link--title">{title}</h2>}
    </div>
    {link && <Link className="stats-link--link" {...link} />}
  </div>
);

StatsLink.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  title: PropTypes.string,
  link: PropTypes.exact(Link.propTypes)
};
export default StatsLink;
