import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const Context = React.createContext({ submitForm: () => {} });

const Form = ({
  children,
  className,
  endpoint,
  method,
  showSubmitButton,
  submitButtonText,
  onSubmit
}) => {
  const form = React.useRef();

  const submitForm = () => {
    form.current.submit();
  };

  return (
    <form
      action={endpoint}
      className={cn('form', className)}
      method={method}
      ref={form}
      onSubmit={onSubmit}
    >
      <Context.Provider value={{ submitForm }}>{children}</Context.Provider>

      {showSubmitButton && <button type="submit">{submitButtonText}</button>}
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  method: PropTypes.string,
  showSubmitButton: PropTypes.bool,
  submitButtonText: PropTypes.string,
  onSubmit: PropTypes.func
};

Form.propTypesMeta = {
  className: 'exclude',
  showSubmitButton: 'exclude'
};

Form.defaultProps = {
  method: 'GET',
  showSubmitButton: true
};

Form.Context = Context;

export default Form;
