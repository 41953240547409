import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import OPE from 'js/on-page-editing';

import Button from 'components-old/button';
import ContentArea from 'components-old/content-area';
import ContentContainer from 'components-old/content-container';
import StickyMenuOnTabs from 'components-old/sticky-menu-on-tabs';
import ContentWithSidebar from 'components-old/content-with-sidebar';
import EventData from './event-data';
import EventPageLink from './event-page-link';
import Link from 'components-old/link';
import RichText from 'components-old/rich-text';
import OptionsModal from 'components-old/options-modal';
import DateCardMedia from 'components-old/date-card/date-card-media';
import EventImage from 'components-old/event-image';
import Label from 'components-old/label';
import DatesMore from 'components-old/dates-more';
import DateField from 'components-old/date-field';

const EventPage = ({
  contactInfo,
  metadata,
  labels,
  links,
  onPageEditing,
  registrationLink,
  eventImage,
  richText,
  schedule,
  speakers,
  title,
  share,
  date,
  media,
  menu,
  moreDates
}) => {
  const { image = {} } = eventImage;
  return (
    <div className="event-page">
      <ContentContainer
        theme={ContentContainer.themes.wide}
        className="event-page--header"
      >
        <div className="event-page--header-left">
          <div className="event-page--image-container">
            <EventImage {...eventImage} />
            {date && <DateField date={date} transparent={image.src} />}
            {moreDates && <DatesMore {...moreDates} />}
          </div>
        </div>
        <div className="event-page--header-text">
          {title && (
            <h1
              data-epi-type="title"
              className="event-page--header-title"
              {...OPE(onPageEditing.title)}
            >
              {title}
            </h1>
          )}
          <div data-epi-type="content">
            <EventData {...metadata} />
            <DateCardMedia {...media} />
            <div className="event-page--registration">
              <Link
                theme={[Button.themes.fill, Button.themes.big]}
                useButtonStyles={true}
                {...registrationLink}
              />
            </div>
          </div>
        </div>
      </ContentContainer>
      <ContentContainer
        theme={ContentContainer.themes.wide}
        className="event-page--content-container"
      >
        <ContentWithSidebar
          theme={ContentWithSidebar.themes.sidebarLeft}
          className="event-page--content-with-sidebar"
          useContentContainer={false}
        >
          <ContentWithSidebar.Sidebar className="event-page--sidebar">
            <StickyMenuOnTabs {...menu} className="event-page--menu" />
          </ContentWithSidebar.Sidebar>
          <ContentWithSidebar.Content className="event-page--content">
            <div className="event-page--links">
              {links.map(link => (
                <EventPageLink key={link.url} {...link} />
              ))}
              {share && (
                <OptionsModal
                  theme={OptionsModal.themes.isProposalOrEvent}
                  {...share}
                />
              )}
            </div>
            {richText && (
              <div className="event-page--text" data-epi-type="content">
                {labels.about && <Label {...labels.about} />}
                <RichText {...richText} />
              </div>
            )}
            {speakers && (
              <div className="event-page--speakers" data-epi-type="content">
                {labels.speakers && <Label {...labels.speakers} />}
                <ContentArea {...speakers} />
              </div>
            )}
            {schedule && (
              <div className="event-page--schedule" data-epi-type="content">
                {labels.schedule && <Label {...labels.schedule} />}
                <ContentArea
                  className="event-page--schedule-content"
                  {...schedule}
                />
              </div>
            )}
            {contactInfo && (
              <div className="event-page--contact" id={labels.contact.htmlId}>
                <ContentArea
                  enableElementSizing={false}
                  theme={ContentArea.themes.twoColumns}
                  {...contactInfo}
                />
              </div>
            )}
          </ContentWithSidebar.Content>
        </ContentWithSidebar>
      </ContentContainer>
    </div>
  );
};

EventPage.propTypes = {
  contactInfo: PropTypes.exact(ContentArea.propTypes),
  labels: PropTypes.exact({
    about: PropTypes.exact(Label.propTypes),
    contact: PropTypes.exact(Label.propTypes),
    speakers: PropTypes.exact(Label.propTypes),
    schedule: PropTypes.exact(Label.propTypes)
  }),
  links: PropTypes.arrayOf(PropTypes.exact(EventPageLink.propTypes)),
  metadata: PropTypes.exact(EventData.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),
  speakers: PropTypes.exact(ContentArea.propTypes),
  richText: PropTypes.exact(RichText.propTypes),
  registrationLink: PropTypes.exact(Link.propTypes),
  schedule: PropTypes.exact(ContentArea.propTypes),
  title: PropTypes.string,
  share: PropTypes.exact(OptionsModal.propTypes),
  eventImage: PropTypes.exact(EventImage.propTypes),
  media: PropTypes.exact(DateCardMedia.propTypes),
  menu: PropTypes.exact(StickyMenuOnTabs.propTypes),
  moreDates: PropTypes.exact(DatesMore.propTypes),
  date: PropTypes.string
};

EventPage.defaultProps = {
  labels: {},
  links: [],
  onPageEditing: {},
  dateContainer: {},
  eventImage: {}
};

export default EventPage;
