import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Link from 'components-old/link';

const element = (
  isLink,
  url,
  trackUrl,
  noHover,
  className,
  children,
  ref,
  attributes
) =>
  React.createElement(
    isLink ? Link : 'div',
    isLink
      ? { url, trackUrl, noHoverEffect: noHover, className, ref, attributes }
      : { className, ref },
    children
  );

const LinkWrapper = (shouldWrapInLink, url, trackUrl) => {
  const OuterLink = ({
    children,
    className,
    refer,
    noHoverEffect = true,
    attributes
  }) =>
    element(
      shouldWrapInLink,
      url,
      trackUrl,
      noHoverEffect,
      cn({ 'link-wrapper__outer-link': shouldWrapInLink }, className),
      children,
      refer,
      attributes
    );

  OuterLink.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    refer: PropTypes.func,
    noHoverEffect: PropTypes.bool
  };

  const InnerLink = ({ children, className }) =>
    element(
      !shouldWrapInLink,
      url,
      trackUrl,
      false,
      cn({ 'link-wrapper__inner-link': shouldWrapInLink }, className),
      children
    );

  InnerLink.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
  };

  return {
    OuterLink,
    InnerLink
  };
};

LinkWrapper.propTypesMeta = 'exclude';

export default LinkWrapper;
