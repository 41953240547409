import React from 'react';
import PropTypes from 'prop-types';
import OPE from 'js/on-page-editing';

import cn from 'classnames';

import YoutubeVideo from 'components-old/youtube-video';
import EmbedBlock from 'components-old/embed-block';
import Image from 'components-old/image';
import Link from 'components-old/link';

const MediaWithCaption = ({
  caption,
  onPageEditing,
  title,
  youTubeVideo,
  embed,
  image,
  url
}) => {
  const titleElement = url ? (
    <Link
      iconBeforeChildren="small-arrow-right"
      iconClassName="media-with-caption--title-icon"
      url={url}
    >
      {title}
    </Link>
  ) : (
    title
  );
  return (
    <div className="media-with-caption">
      {title && (
        <h2 className={cn({ '-has-link': url })} {...OPE(onPageEditing.title)}>
          {titleElement}
        </h2>
      )}
      <div className="media-with-caption__content">
        {youTubeVideo && <YoutubeVideo {...youTubeVideo} />}
        {embed && <EmbedBlock {...embed} />}
        {image && <Image {...image} />}
      </div>
      {caption && (
        <div
          className="media-with-caption__caption"
          {...OPE(onPageEditing.caption)}
        >
          {caption}
        </div>
      )}
    </div>
  );
};

MediaWithCaption.propTypes = {
  caption: PropTypes.string,
  onPageEditing: PropTypes.exact({
    caption: PropTypes.string,
    title: PropTypes.string
  }),
  title: PropTypes.string,
  youTubeVideo: PropTypes.exact(YoutubeVideo.propTypes),
  embed: PropTypes.exact(EmbedBlock.propTypes),
  image: PropTypes.exact(Image.propTypes),
  url: PropTypes.string
};

MediaWithCaption.defaultProps = {
  onPageEditing: {}
};

export default MediaWithCaption;
