import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components-old/button';
import DateCard from 'components-old/date-card';
import EmptyList from 'components-old/empty-list';
import Link from 'components-old/link';

const Vacancies = ({ emptyList, items, link, title, text }) => (
  <div className="vacancies">
    {title && <h2 className="vacancies--title">{title}</h2>}
    {items.length > 0 ? (
      items.map(event => (
        <DateCard key={event.id} headingLevel={3} {...event} />
      ))
    ) : (
      <EmptyList {...emptyList} />
    )}
    <div className="vacancies--cta">
      <p>{text}</p>
      <Link theme={[Button.themes.fill]} useButtonStyles={true} {...link} />
    </div>
  </div>
);

Vacancies.propTypes = {
  emptyList: PropTypes.exact(EmptyList.propTypes),
  items: PropTypes.arrayOf(PropTypes.exact(DateCard.propTypes)),
  link: PropTypes.exact(Link.propTypes),
  title: PropTypes.string,
  text: PropTypes.string
};

Vacancies.defaultProps = {
  items: []
};

export default Vacancies;
