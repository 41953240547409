const colors = {
  orange: '#ffb74c',
  red: '#ff6d55',
  teal: '#6ed5e4',
  purple: '#26266d',
  green: '#00b982',
  pink: '#b35da9'
};

const stepsLength = Object.keys(colors).length;

const backgrounds = index =>
  [
    { background: colors.orange },
    { background: colors.red },
    { background: colors.teal },
    { color: 'white', background: colors.purple },
    { background: colors.green },
    { background: colors.pink }
  ][index % stepsLength];

const getColor = i => Object.values(colors)[i % stepsLength];

export { colors, backgrounds, getColor };
