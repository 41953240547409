import PropTypes from 'prop-types';
import React from 'react';
import Link from 'components/link';
import Tooltip from 'components/tooltip';

import EventData from 'components/event-page/event-data';
import useTooltip from 'js/hooks/use-tooltip';

const DatesMoreLink = ({ link, tooltip, isOpen }) => {
  const {
    ref,
    handleMouseEnter,
    handleMouseLeave,
    showTabTooltip,
    showTooltip
  } = useTooltip(isOpen);

  return (
    <li
      className="dates-more-link"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={ref}
    >
      <Link
        className="dates-more-link--link"
        {...link}
        theme={Link.themes.actionLink}
      />
      {tooltip && tooltip.eventData && (showTabTooltip || showTooltip) && (
        <Tooltip useTabStyle={showTabTooltip}>
          <EventData {...tooltip.eventData} enableTabbing />
        </Tooltip>
      )}
    </li>
  );
};

DatesMoreLink.propTypes = {
  link: PropTypes.exact(Link.propTypes),
  tooltip: PropTypes.exact({ eventData: PropTypes.exact(EventData.propTypes) }),
  isOpen: PropTypes.string
};
DatesMoreLink.propTypesMeta = {
  isOpen: 'exclude'
};
export default DatesMoreLink;
