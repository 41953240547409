import React from "react";
import TibiTabContent from "./tibi-tab-content";
import TabContent from "./tab-content";
import PropTypes from 'prop-types';

//renders different variations based on type of content

const TabContentWrapper = (content) => {
  if (content) {
    const tibiContent = content.tibi
    const evurderingContent = content.evurdering
    if (tibiContent) {
      return (
        <TibiTabContent {...tibiContent} />
      )
    } else {
      return (
        <TabContent {...evurderingContent} />
      )
    }
  }
}

TabContentWrapper.propTypes = {
  tibi: TibiTabContent.PropTypes,
  evurdering: TabContent.PropTypes,
};

export default TabContentWrapper