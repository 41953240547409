import React from 'react';
import PropTypes from 'prop-types';

const ImageTag = ({ label }) => {
  return <div className="image-tag">{label}</div>;
};

ImageTag.propTypesMeta = 'exclude';
ImageTag.propTypes = {
  label: PropTypes.string
};

export default ImageTag;
