import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import onResize from 'js/hooks/resize';
import { convertToPxs, applyPositions, pushBeziers } from 'utils/path';

const Path = ({
  start,
  relativeBeziers,
  end,
  className,
  mousePos,
  hoverAnimationTrigger,
  softAnimation,
  menuAnimationTrigger
}) => {
  const svgRef = useRef();
  const [points, setPoints] = useState({});
  const [moveToPoints, setMoveToPoints] = useState();
  const [resized, update] = useState();

  const animateRef = useRef();
  const [animationState, setAnimationState] = useState({
    menu: {
      animate: false,
      animateIn: false
    },
    hover: {
      animate: false,
      animateIn: false
    }
  });
  const [isAnimatable, setIsAnimatable] = useState();
  const [hasMounted, setHasMounted] = useState();

  onResize(update);

  const getPoints = () => {
    const { clientWidth, clientHeight } = svgRef.current;
    const startPx = convertToPxs(clientWidth, clientHeight, start);
    const endPx = convertToPxs(clientWidth, clientHeight, end);
    const absoluteBeziers = applyPositions(startPx, endPx, relativeBeziers);
    return {
      start: startPx,
      end: endPx,
      curve: absoluteBeziers
    };
  };

  const getLocalMousePos = mousePos => {
    const spaceAboveCanvas = svgRef.current.getBoundingClientRect().y;
    return [mousePos[0], mousePos[1] - spaceAboveCanvas];
  };

  const localMousePos =
    mousePos && svgRef.current ? getLocalMousePos(mousePos) : [0, 0];

  const fromPath = () => `M${points.start} C${points.curve} ${points.end}`;

  const toPath = () => {
    const coords = moveToPoints ? moveToPoints : points;
    return `M${coords.start} C${pushBeziers(
      coords.curve,
      localMousePos,
      softAnimation
    )} ${coords.end}`;
  };

  useEffect(() => {
    const newPoints = getPoints();
    setPoints(newPoints);
    setIsAnimatable(false);
    setHasMounted(true);
  }, [resized]);

  useEffect(() => {
    if (!hasMounted) return;
    setIsAnimatable(true);
    setAnimationState({
      menu: { animate: false, animateIn: menuAnimationTrigger },
      hover: { animate: true, animateIn: hoverAnimationTrigger }
    });
  }, [hoverAnimationTrigger]);

  useEffect(() => {
    if (!hasMounted) return;
    setIsAnimatable(true);
    setAnimationState({
      menu: { animate: true, animateIn: menuAnimationTrigger },
      hover: { animate: false, animateIn: hoverAnimationTrigger }
    });
  }, [menuAnimationTrigger]);

  useEffect(() => {
    if (!isAnimatable) return;
    if (animationState.menu.animate) {
      const newPoints = getPoints();
      setMoveToPoints(newPoints);

      const event = () => {
        setPoints(newPoints);
        setMoveToPoints(null);
        setIsAnimatable(false);
      };
      animateRef.current.addEventListener('endEvent', event);
    }
    animateRef.current.beginElement();
  }, [animationState.hover.animateIn, animationState.menu.animateIn]);
  return (
    <svg
      ref={svgRef}
      className={cn('path', className)}
      width="100%"
      height="100%"
      fill="none"
    >
      {points.start && (
        <path
          d={fromPath()}
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="4 10"
        >
          {isAnimatable && (
            <animate
              attributeName="d"
              from={
                animationState.hover.animateIn || moveToPoints
                  ? fromPath()
                  : toPath()
              }
              to={
                animationState.hover.animateIn || moveToPoints
                  ? toPath()
                  : fromPath()
              }
              dur="0.3s"
              begin="indefinite"
              fill="freeze"
              ref={animateRef}
              calcMode="spline"
              keySplines="0.37 0 0.63 1"
              keyTimes="0;1"
            />
          )}
        </path>
      )}
    </svg>
  );
};

Path.propTypes = {
  start: PropTypes.arrayOf(PropTypes.number),
  relativeBeziers: PropTypes.arrayOf(PropTypes.number),
  end: PropTypes.arrayOf(PropTypes.number),
  className: PropTypes.string,
  mousePos: PropTypes.arrayOf(PropTypes.number),
  hoverAnimationTrigger: PropTypes.bool,
  softAnimation: PropTypes.bool,
  menuAnimationTrigger: PropTypes.bool
};

Path.defaultProps = {
  relativeBeziers: [0, 0, 0, 0]
};

Path.propTypesMeta = 'exclude';

export default Path;
