import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components-old/link';
import Search from 'components-old/search';
import Icon from 'components-old/icon';
import Form from 'components-old/form';

const ErrorPage = ({ errorCode, link, title, message, search, text, form }) => (
  <div className="error-page">
    {errorCode ? (
      <Icon name="error-page" className="error-page--icon" />
    ) : (
      <Icon name="downtime" className="error-page--icon" fill />
    )}
    <div>
      {errorCode && <h1 className="error-page--code">{errorCode}</h1>}
      {title && <div className="error-page--title">{title}</div>}
      {message && <div className="error-page--message">{message}</div>}
      {text && <p className="error-page--text">{text}</p>}
      {search && (
        <Form showSubmitButton={false} {...form}>
          <Search theme={Search.themes.errorPage} {...search} />
        </Form>
      )}
      {link && (
        <Link
          theme={Link.themes.black}
          className="error-page--link"
          {...link}
        />
      )}
    </div>
  </div>
);

ErrorPage.propTypes = {
  errorCode: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  title: PropTypes.string,
  message: PropTypes.string,
  search: PropTypes.exact(Search.propTypes),
  text: PropTypes.string,
  form: PropTypes.exact(Form.propTypes)
};

export default ErrorPage;
