import React from 'react';
import PropTypes from 'prop-types';

import RelatedContent from 'components/related-content';
import SearchResult from 'components/search-result';

const RelatedPublications = ({
  relatedContent,
  publications,
  usedInSidebar
}) => (
  <RelatedContent {...relatedContent}>
    {publications.map(publication => (
      <SearchResult
        headingLevel={3}
        key={publication.title}
        theme={
          usedInSidebar
            ? SearchResult.themes.sidebar
            : SearchResult.themes.noBorder
        }
        {...publication}
        isLink
      />
    ))}
  </RelatedContent>
);

RelatedPublications.propTypes = {
  relatedContent: PropTypes.exact(RelatedContent.propTypes),
  publications: PropTypes.arrayOf(PropTypes.exact(SearchResult.propTypes)),
  usedInSidebar: PropTypes.bool
};

RelatedPublications.defaultProps = {
  publications: [],
  usedInSidebar: false
};

export default RelatedPublications;
