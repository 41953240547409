/* eslint-disable react/no-multi-comp */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/icon';
import Button from 'components/button';
import VideoBlock from 'components/nysgjerrigper/video-block';

import useIsScreenSize from 'js/hooks/use-is-screen-size';
import StepsBlock from 'components/nysgjerrigper/steps-block';
import QuotesBlock from 'components/nysgjerrigper/quotes-block';

import { backgrounds } from 'js/utils/np-colors';
import doAndWait from 'js/utils/do-and-wait';

const components = {
  videoBlock: VideoBlock,
  stepsBlock: StepsBlock,
  quotesBlock: QuotesBlock
};

const StepsContent = ({ close, onClose, activeStepIndex, steps = [] }) => {
  const [isActive, setIsActive] = useState(activeStepIndex > -1);
  const [scrollPos, setScrollPos] = useState([0, 0, 0, 0, 0, 0]);
  const [activeStepIndexState, setActiveStepIndexState] = useState(
    activeStepIndex > -1 ? activeStepIndex : 0
  );
  const [isMounted, setIsMounted] = useState();
  const stepsContentRef = useRef();
  const isDesktop = useIsScreenSize('md');

  useEffect(() => {
    setIsMounted(true);
    const unselected = activeStepIndex === -1;
    const previousUnselected = !isActive;
    if (unselected) {
      setIsActive(false);
    } else if (previousUnselected) {
      setActiveStepIndexState(activeStepIndex);
      setIsActive(true);
    } else {
      const switchOverlay = async () => {
        await doAndWait(() => {
          setIsActive(false);
        }, 500);
        setActiveStepIndexState(activeStepIndex);
        setIsActive(true);
      };
      switchOverlay();
    }
  }, [activeStepIndex]);

  useEffect(() => {
    isActive
      ? stepsContentRef.current.scrollTo(0, scrollPos[activeStepIndex])
      : setScrollPos(state => [
          ...state.slice(0, activeStepIndexState),
          stepsContentRef.current.scrollTop,
          ...state.slice(activeStepIndexState + 1)
        ]);
  }, [isActive]);

  const getAdditionalProps = (name, blockIndex) => {
    switch (name) {
      case 'quotesBlock':
        return {
          blockIndex,
          stepIndex: activeStepIndexState,
          stepsContentNode: stepsContentRef.current
        };
      case 'stepsBlock':
        return {
          stepIndex: activeStepIndexState
        };
    }
  };

  const getBackgroundColor = (index, blockName) => {
    switch (blockName) {
      case 'stepsBlock':
        return { background: 'white' };
      case 'quotesBlock':
        return { background: 'white' };
      default:
        return backgrounds(index);
    }
  };

  const activeStepBlocks = isActive && steps[activeStepIndexState];

  return (
    <div
      className={cn('steps-content', { '-is-active': isActive })}
      ref={stepsContentRef}
      style={
        // transform is applied after mount to prevent overflow bug
        isMounted && {
          transform: 'translateX(100%)'
        }
      }
    >
      {isDesktop ? (
        <Button
          className="steps-content--close -desktop"
          onClick={onClose}
          icon="np-close"
          iconFill
        >
          {close.textDesktop}
        </Button>
      ) : (
        <Button
          className="steps-content--close -mobile"
          attributes={{ style: backgrounds(activeStepIndexState) }}
          onClick={onClose}
        >
          <Icon name="link-arrow" /> {close.textMobile}
        </Button>
      )}

      {activeStepBlocks &&
        Object.keys(activeStepBlocks).map((blockName, blockIndex) => {
          const Component = components[blockName];
          const props = activeStepBlocks[blockName];
          const additionalProps = getAdditionalProps(blockName, blockIndex);
          if (!Component) return;
          return (
            <div
              className="steps-content--component-wrapper"
              key={blockName + blockIndex}
              style={getBackgroundColor(
                activeStepIndexState + blockIndex,
                blockName
              )}
            >
              {<Component {...props} {...additionalProps} />}
            </div>
          );
        })}
    </div>
  );
};

StepsContent.propTypes = {
  close: PropTypes.shape({
    textMobile: PropTypes.string,
    textDesktop: PropTypes.string
  }),
  isActive: PropTypes.bool,
  onClose: PropTypes.func,
  activeStepIndex: PropTypes.number,
  steps: PropTypes.arrayOf(
    PropTypes.exact({
      videoBlock: PropTypes.exact(VideoBlock.propTypes),
      stepsBlock: PropTypes.exact(StepsBlock.propTypes),
      quotesBlock: PropTypes.exact(QuotesBlock.propTypes)
    })
  )
};

StepsContent.propTypesMeta = {
  isActive: 'exclude',
  onClose: 'exclude'
};

export default StepsContent;
