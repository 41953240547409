import React from 'react';
import PropTypes from 'prop-types';

import ContentArea from 'components-old/content-area';
import ContentAreaItem from 'components-old/content-area-item';
import ContentContainer from 'components-old/content-container';
import FrontpageHeader from 'components-old/frontpage-header';
import HtmlString from 'components-old/html-string';
import RichTextBlock from 'components-old/rich-text-block';
import LinkList from 'components-old/link-list';

const narrowTextProps = {
  HtmlString: { theme: HtmlString.themes.narrow },
  RichTextBlock: { theme: RichTextBlock.themes.narrow }
};

const Frontpage = ({ content, header, linkList }) => {
  return (
    <div className="frontpage">
      <FrontpageHeader {...header} />
      {linkList && (
        <LinkList
          className="frontpage--link-list"
          {...linkList}
          theme={LinkList.themes.frontpageLinks}
          withIcon
        />
      )}
      <ContentContainer className="frontpage--content">
        <ContentArea
          additionalComponentProps={{
            ...narrowTextProps,
            // NOTE: RichText is just a proxy for ContentArea, so themes need to be applied using additionalComponentProps
            RichText: { additionalComponentProps: narrowTextProps }
          }}
          additionalItemProps={{
            PageLinksBlock: { size: ContentAreaItem.sizes.fullScreen }
          }}
          {...content}
        />
      </ContentContainer>
    </div>
  );
};

Frontpage.propTypes = {
  content: PropTypes.exact(ContentArea.propTypes),
  header: PropTypes.exact(FrontpageHeader.propTypes),
  linkList: PropTypes.exact(LinkList.propTypes)
};

export default Frontpage;
