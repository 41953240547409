import { useEffect } from 'react';
import 'intersection-observer';

const config = {
  rootMargin: '0px 0px 256px 0px',
  threshold: 0
};
const useOnIntersect = (node, callback) => {
  useEffect(() => {
    let observer = new IntersectionObserver((entries, self) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          callback();
          self.unobserve(entry.target);
        }
      });
    }, config);

    observer.observe(node.current);
  }, []);
};

export default useOnIntersect;
