import React from 'react';
import PropTypes from 'prop-types';

import ContentArea from 'components-old/content-area';
import ContentContainer from 'components-old/content-container';
import HtmlString from 'components-old/html-string';
import PageHeader from 'components-old/page-header';
import RichTextBlock from 'components-old/rich-text-block';

const narrowTextProps = {
  HtmlString: { theme: HtmlString.themes.narrow },
  RichTextBlock: { theme: RichTextBlock.themes.narrow }
};

const ContentAreaPage = ({ content, pageHeader }) => (
  <div className="content-area-page">
    <PageHeader {...pageHeader} />
    <ContentContainer className="content-area-page--content">
      <ContentArea
        additionalComponentProps={{
          ...narrowTextProps,
          // NOTE: RichText is just a proxy for ContentArea, so themes need to be applied using additionalComponentProps
          RichText: { additionalComponentProps: narrowTextProps }
        }}
        {...content}
      />
    </ContentContainer>
  </div>
);

ContentAreaPage.propTypes = {
  content: PropTypes.exact(ContentArea.propTypes),
  pageHeader: PropTypes.exact(PageHeader.propTypes)
};

export default ContentAreaPage;
