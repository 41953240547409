import React from 'react';
import PropTypes from 'prop-types';

import ContentArea from 'components/content-area';
import ContentContainer from 'components/content-container';
import HtmlString from 'components/html-string';
import PageHeader from 'components/page-header';
import RichTextBlock from 'components/rich-text-block';
import PortfolioHeader from 'components/portfolio-header';

const narrowTextProps = {
  HtmlString: { theme: HtmlString.themes.narrow },
};

const ContentAreaPage = ({ content, pageHeader, portfolioHeader }) => (
  <div className="content-area-page">
    {portfolioHeader && <PortfolioHeader {...portfolioHeader} isPageHeader />}
    {pageHeader && <PageHeader {...pageHeader} />}
    <ContentContainer className="content-area-page--content">
      <ContentArea
        additionalComponentProps={{
          ...narrowTextProps,
          // NOTE: RichText is just a proxy for ContentArea, so themes need to be applied using additionalComponentProps
          RichText: { additionalComponentProps: narrowTextProps }
        }}
        {...content}
      />
    </ContentContainer>
  </div>
);

ContentAreaPage.propTypes = {
  content: PropTypes.exact(ContentArea.propTypes),
  pageHeader: PropTypes.exact(PageHeader.propTypes),
  portfolioHeader: PropTypes.exact(PortfolioHeader.propTypes)
};

export default ContentAreaPage;
