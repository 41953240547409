import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';
import Search from 'components/search';
import Icon from 'components/icon';
import Form from 'components/form';

const ErrorPage = ({ errorCode, link, title, message, search, text, form }) => {
  const errorPageRef = useRef();
  const errorPageHeight = (function (errorPageRef) {
    const [errorPageHeight, setErrorPageHeight] = useState();

    useEffect(() => {
      const errorPageHeight = errorPageRef.current.offsetHeight;
      const documentHeight = document.body.scrollHeight;
      const nonErrorPageHeight = documentHeight - errorPageHeight;
      const height = window.innerHeight - nonErrorPageHeight;
      setErrorPageHeight(height);
    }, []);

    return errorPageHeight;
  })(errorPageRef);

  return (
    <div
      className="error-page"
      ref={errorPageRef}
      style={{ height: errorPageHeight }}
    >
      <div className="error-page--content">
        <Icon className="error-page--icon" name="dubian-path" />
        <div className="error-page--content-inner">
          {errorCode && <h1 className="error-page--code">{errorCode}</h1>}
          {title && <div className="error-page--title">{title}</div>}
          {message && <div className="error-page--message">{message}</div>}
          {text && <p className="error-page--text">{text}</p>}
          {search && (
            <Form showSubmitButton={false} {...form}>
              <Search {...search} />
            </Form>
          )}
          {link && (
            <Link
              theme={Link.themes.black}
              className="error-page--link"
              {...link}
            />
          )}
        </div>
      </div>
    </div>
  );
};
ErrorPage.propTypes = {
  errorCode: PropTypes.string,
  link: PropTypes.exact(Link.propTypes),
  title: PropTypes.string,
  message: PropTypes.string,
  search: PropTypes.exact(Search.propTypes),
  text: PropTypes.string,
  form: PropTypes.exact(Form.propTypes)
};

export default ErrorPage;
