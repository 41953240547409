import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import Collapse from 'components/collapse';

// This component passes a Button, a Collapse, the open state and a toggle function through render props. The Button already has a click handler and toggles between labels (depending on expanded/collapsed state), but supports additional props. Collapsible content should be wrapped in the passed Collapse component.

/*
<Accordion expandLabel="Show more" collapseLabel="Show less">
  {({ Button, Collapse, isOpen, toggle }) => (
    <Button className={isOpen ? "active" : "inactive"} />

    <Collapse>
      This is the collapsible content
    </Collapse>

    <div className="other-toggle" onClick={toggle} />
  )}
*/

class Accordion extends React.Component {
  static propTypes = {
    animate: PropTypes.bool,
    children: PropTypes.func,
    collapseLabel: PropTypes.string.isRequired,
    expandLabel: PropTypes.string.isRequired,
    expandOnMount: PropTypes.func,
    guid: PropTypes.string.isRequired,
    initiallyOpen: PropTypes.bool,
    showButtonText: PropTypes.bool,
    buttonTextForLabel: PropTypes.string
  };

  static propTypesMeta = {
    animate: 'exclude',
    showButtonText: 'exclude',
    buttonTextForLabel: 'exclude'
  };

  static defaultProps = {
    animate: true,
    children: () => {},
    expandOnMount: () => {}
  };

  state = {
    isOpen: this.props.initiallyOpen
  };

  toggle = () => {
    this.setState(state => ({ isOpen: !state.isOpen }));
  };

  onClick = e => {
    // Stop propagation so that the 'toggle' method isn't called twice when used on a parent element of the button
    e.stopPropagation();
    this.toggle();
  };

  componentDidMount() {
    this.setState(state => ({
      isOpen: this.props.expandOnMount(window.innerWidth) || state.isOpen
    }));
  }

  Button = props => {
    const label = this.state.isOpen
      ? this.props.collapseLabel
      : this.props.expandLabel;

    const buttonText = this.props.buttonTextForLabel
      ? `${label} ${this.props.buttonTextForLabel}`
      : label;

    return (
      <Button
        attributes={{
          'aria-controls': this.props.guid,
          'aria-expanded': this.state.isOpen
        }}
        onClick={this.onClick}
        text={this.props.showButtonText ? buttonText : undefined}
        title={buttonText}
        {...props}
      />
    );
  };

  Collapse = ({ className, children, closedHeight }) =>
    this.props.animate ? (
      <Collapse
        className={className}
        componentProps={{ id: this.props.guid }}
        isOpen={this.state.isOpen}
        unmountClosed={false}
        closedHeight={closedHeight}
      >
        {children}
      </Collapse>
    ) : (
      <div className={className} id={this.props.guid}>
        {this.state.isOpen && children}
      </div>
    );

  render() {
    return this.props.children({
      Button: this.Button,
      Collapse: this.Collapse,
      isOpen: this.state.isOpen,
      toggle: this.toggle
    });
  }
}

export default Accordion;
