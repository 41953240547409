import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';

import FluidImage from 'components-old/fluid-image';
import Heading from 'components-old/heading';

const ImageWithLink = ({
  image,
  onPageEditing,
  text,
  url,
  shareImageHeight
}) => (
  <a className="image-with-link" href={url}>
    <div className="image-with-link--image">
      <div
        className={cn('image-with-link--image-inner', {
          '-hover-effect-active': Object.keys(onPageEditing).length === 0,
          '--absolute-height': shareImageHeight
        })}
      >
        <FluidImage {...image} />
      </div>
    </div>
    <div {...OPE(onPageEditing.url)} />
    <div {...OPE(onPageEditing.text)}>
      {text && <Heading className="image-with-link--text">{text}</Heading>}
    </div>
  </a>
);

ImageWithLink.propTypes = {
  image: PropTypes.exact(FluidImage.propTypes),
  onPageEditing: PropTypes.exact({
    text: PropTypes.string,
    url: PropTypes.string
  }),
  text: PropTypes.string,
  url: PropTypes.string,
  shareImageHeight: PropTypes.bool
};

ImageWithLink.defaultProps = {
  onPageEditing: {}
};

export default ImageWithLink;
