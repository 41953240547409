import React from 'react';
import PropTypes from 'prop-types';
import Byline from 'components-old/byline';
import Link from 'components-old/link';
import OptionsModal from 'components-old/options-modal';

import cn from 'classnames';

const PageFooter = ({ byline, download, share }) => (
  <div className="page-footer">
    <div className="page-footer--byline-wrapper">
      {!!byline.items.length && <Byline {...byline} />}

      <div className="page-footer--links">
        {download && (
          <div className="page-footer--download-link">
            <Link {...download} icon="download-small" />
          </div>
        )}
        {share && (
          <div
            className={cn('page-footer--share', download && '-with-seperator')}
          >
            <OptionsModal
              theme={OptionsModal.themes.isPageFooter}
              {...share}
            ></OptionsModal>
          </div>
        )}
      </div>
    </div>
  </div>
);

PageFooter.propTypes = {
  byline: PropTypes.exact(Byline.propTypes),
  download: PropTypes.exact(Link.propTypes),
  share: PropTypes.exact(OptionsModal.propTypes)
};

PageFooter.defaultProps = {
  byline: {
    items: []
  }
};

export default PageFooter;
