import React, { useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Message from 'components-old/message';
import Button from 'components-old/button/button';

import useOutsideClick from 'js/hooks/use-outside-click';

const MessageGlobalModal = ({ message = {}, hideModal }) => {
  const modalRef = useRef();

  useOutsideClick(modalRef, hideModal);
  return (
    <div className={cn('message-global-modal', message.theme)} ref={modalRef}>
      <Button
        className="message-global-modal--button"
        onClick={hideModal}
        icon="x"
      />
      <Message className="message-global-modal--message" {...message} />
    </div>
  );
};

MessageGlobalModal.propTypes = {
  message: PropTypes.exact(Message.propTypes),
  hideModal: PropTypes.func
};

MessageGlobalModal.themes = Message.themes;

MessageGlobalModal.propTypesMeta = 'exclude';

export default MessageGlobalModal;
