import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';

import OptionsModal from 'components/options-modal';
import Accordion from 'components/accordion';
import IconWarning from 'components/icon-warning';
import ContentArea from 'components/content-area';
import Icon from 'components/icon';

const AccordionWithContentArea = ({
  content,
  accordion,
  onPageEditing,
  title,
  iconWarning,
  share,
  htmlId,
  initiallyOpen,
  addAnchorHash,
  removeAnchorHash
}) => {
  const [hasOpened, setHasOpened] = useState();
  const isEditMode = Object.keys(onPageEditing).length > 0;
  const contentRef = useRef();

  const disableTabbing = () => {
    const interactiveElements = [
      ...contentRef.current.getElementsByTagName('a'),
      ...contentRef.current.getElementsByTagName('button')
    ];
    interactiveElements.forEach(link => {
      link.setAttribute('tabindex', '-1');
    });
  };

  const enableTabbing = () => {
    const interactiveElements = [
      ...contentRef.current.getElementsByTagName('a'),
      ...contentRef.current.getElementsByTagName('button')
    ];
    interactiveElements.forEach(link => {
      link.setAttribute('tabindex', '0');
    });
  };

  const shouldExpand = () => {
    const isTargetedByAnchor = htmlId && `#${htmlId}` === window.location.hash;
    if (isTargetedByAnchor) {
      // Manually scroll if anchor-linked as expanding the accordion disrupts browsers anchor-link-scrolling.
      setTimeout(
        () => window.scrollTo(0, document.getElementById(htmlId).offsetTop),
        500
      );
    }
    const noAnchorAndOpen = !window.location.hash && initiallyOpen;
    const shouldExpand = isTargetedByAnchor || noAnchorAndOpen;
    if (shouldExpand) {
      if (htmlId) addAnchorHash(`#${htmlId}`);
      setHasOpened(true);
    }
    return shouldExpand;
  };

  useEffect(() => {
    if (shouldExpand()) {
      enableTabbing();
    } else {
      disableTabbing();
    }
  }, []);

  return content ? (
    <Accordion
      {...accordion}
      expandOnMount={shouldExpand}
      showButtonText={false}
      buttonTextForLabel={title}
    >
      {({ Button, Collapse, isOpen, toggle }) => {
        const onClick = () => {
          toggle();
          const isOpening = !isOpen;
          if (isOpening) {
            if (htmlId) addAnchorHash(`#${htmlId}`);
            enableTabbing();
            setTimeout(() => {
              setHasOpened(true);
            }, 300);
          } else {
            if (htmlId) removeAnchorHash(`#${htmlId}`);
            disableTabbing();
            setHasOpened(false);
          }
        };
        return (
          <div
            className="accordion-with-content-area--anchor-target"
            id={htmlId}
          >
            <div className="accordion-with-content-area">
              <Button
                className={cn('accordion-with-content-area--header', {
                  '-is-open': isOpen
                })}
                onClick={isEditMode ? () => {} : onClick}
              >
                {title && (
                  <div className="accordion-with-content-area__title-container">
                    {iconWarning && (
                      <IconWarning
                        className="accordion-with-content-area__title-icon"
                        {...iconWarning}
                      />
                    )}
                    <h3
                      className="accordion-with-content-area__title"
                      {...OPE(onPageEditing.title)}
                    >
                      {title}
                    </h3>
                  </div>
                )}

                <Icon
                  className={cn('accordion-with-content-area--icon', {
                    '-is-open': isOpen
                  })}
                  name="small-arrow-down"
                  fill={true}
                />
              </Button>

              <Collapse
                className={cn('accordion-with-content-area--collapse', {
                  '-closed': !isOpen,
                  '-opened': hasOpened
                })}
                closedHeight={80}
              >
                <div
                  className="accordion-with-content-area--content"
                  ref={contentRef}
                >
                  <ContentArea
                    additionalComponentProps={{
                      TextWithSidebar: {
                        headingLevel: 4,
                        isProposalPage: true,
                        share: share,
                        isOpen: !isOpen ? false : isOpen
                      }
                    }}
                    className="accordion-with-content-area--content-area"
                    onPageEditing={onPageEditing}
                    id={title}
                    {...content}
                  />
                </div>
              </Collapse>
            </div>
          </div>
        );
      }}
    </Accordion>
  ) : null;
};

AccordionWithContentArea.propTypes = {
  content: PropTypes.exact(ContentArea.propTypes),
  accordion: PropTypes.exact(Accordion.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),
  title: PropTypes.string,
  iconWarning: PropTypes.exact(IconWarning.propTypes),
  share: PropTypes.exact(OptionsModal.propTypes),
  htmlId: PropTypes.string,
  initiallyOpen: PropTypes.bool,
  addAnchorHash: PropTypes.func,
  removeAnchorHash: PropTypes.func
};

AccordionWithContentArea.defaultProps = {
  onPageEditing: {},
  addAnchorHash: () => {},
  removeAnchorHash: () => {}
};

export default AccordionWithContentArea;
