import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Heading from 'components/heading';
import ContentArea from 'components/content-area';
import Icon from 'components/icon/icon';
import SectionTitle from 'components/section-title';

const columns = (blocks, singleColumn) => {
  if (singleColumn) return ContentArea.themes.singleColumn;
  if (blocks.length >= 3) return ContentArea.themes.threeColumns;
  if (blocks.length === 2) return ContentArea.themes.twoColumns;
};

const props = (blocks, singleColumn) => {
  switch (blocks[0].componentName) {
    case 'LinkLists':
      return [
        {
          additionalComponentProps: {
            LinkLists: {
              singleColumn
            }
          },
          decreaseTopMargin: true
        },
        { name: 'Link_2022' }
      ];
    case 'LinkWithText':
      return [
        {
          theme: [columns(blocks, singleColumn), ContentArea.themes.portfolio],
          decreaseTopMargin: true
        },
        { name: "BookOpen_2022" }
      ];
    case 'ContactList':
      return [{}, { name: "Envelope_2022" }];
    default:
      return [{}, {}];
  }
};

const PortfolioContent = ({
  isInsidePriorityBlock,
  title,
  text,
  content = {},
  singleColumn,
  children,
  emptyListText,
  sectionTitleLinks
}) => {
  const [extraProps, icon] =
    content.blocks && content.blocks.length
      ? props(content.blocks, singleColumn)
      : [{}, {}];

  return (
    <div
      className={cn('portfolio-content', {
        '-inside-priority': isInsidePriorityBlock
      })}
    >
      {isInsidePriorityBlock && (
        <Icon className="portfolio-content--icon" {...icon} />
      )}
      <div className="portfolio-content--content">
        <div
          className={cn(
            'portfolio-content--heading',
            !singleColumn && sectionTitleLinks && '-with-section-title'
          )}
        >
          {title && (
            <Heading level={3} className="portfolio-content--title">{title}</Heading>
          )}
          <div className="section--title-links">
            {!singleColumn &&
              sectionTitleLinks &&
              sectionTitleLinks.map((x, index) => (
                <SectionTitle key={'sectTitle #' + index} sectionLink={x} />
              ))}
            {text && <p className="portfolio-content--text">{text}</p>}
          </div>
        </div>
        {emptyListText && <p>{emptyListText}</p>}
        {content && content.blocks && (
          <ContentArea
            className={cn('portfolio-content--blocks', {
              '-top-content-is-text': extraProps.decreaseTopMargin
            })}
            enableElementSizing={true}
            {...extraProps}
            {...content}
          />
        )}
        {children && children}
        {sectionTitleLinks &&
          singleColumn &&
          sectionTitleLinks.map((x, index) => (
            <SectionTitle key={'sectTitle #' + index} sectionLink={x} />
          ))}
      </div>
    </div>
  );
};

PortfolioContent.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  text: PropTypes.string,
  content: PropTypes.exact(ContentArea.propTypes),
  isInsidePriorityBlock: PropTypes.bool,
  singleColumn: PropTypes.bool,
  emptyListText: PropTypes.string,
  sectionTitleLinks: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string
    })
  )
};

PortfolioContent.propTypesMeta = {
  children: 'exclude'
};
export default PortfolioContent;
