import React from 'react';
import PropTypes from 'prop-types';

const TagList = ({ tags }) => {
  return (
    <ul className="tag-list">
      {tags.map((tag, index) => (
        <li key={tag.text + String(index)} className="tag-list__tag">
          {tag.text}
        </li>
      ))}
    </ul>
  );
};

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.exact({ text: PropTypes.string }))
};

TagList.defaultProps = {
  tags: []
};

export default TagList;
