import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components-old/link';
import Heading from 'components-old/heading';
import LinkList from 'components-old/link-list';

const NestedLink = ({ item, iconData, linkTheme }) =>
  item.items ? (
    <li>
      {item.title && (
        <Heading className="nested-link--title">{item.title}</Heading>
      )}
      <LinkList
        items={item.items}
        linkTheme={[Link.themes.portfolio]}
        theme={LinkList.themes.portfolio}
        withIcon
      />
    </li>
  ) : (
    <li>
      <Link theme={linkTheme} {...iconData} {...item} />
    </li>
  );

NestedLink.propTypes = {
  item: PropTypes.shape({
    icon: PropTypes.string,
    id: PropTypes.string,
    onPageEditing: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string,
    items: PropTypes.arrayOf(PropTypes.shape(Link.propTypes))
  }),
  linkTheme: Link.propTypes.theme,
  iconData: PropTypes.object
};

LinkList.propTypesMeta = {
  linkTheme: 'exclude',
  iconData: 'exclude'
};

export default NestedLink;
