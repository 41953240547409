import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import OPE from 'js/on-page-editing';

import RichText from 'components/rich-text';
import Heading from 'components/heading';

const Schedule = ({ programList = [], onPageEditing, lastTitle }) => (
  <div className="schedule" {...OPE(onPageEditing.programList)}>
    {programList.map(({ time, title, text, isBreak }, i) => {
      const isLast = i === programList.length - 1;
      return (
        <div
          className={cn('schedule--item', { 'schedule--item--break': isBreak })}
          key={time}
        >
          <div className="schedule--item-time">{time}</div>
          <div className="schedule--item-content">
            <Heading className="schedule--item-title">
              {title || (isLast && lastTitle)}
            </Heading>
            <RichText {...text} />
          </div>
        </div>
      );
    })}
  </div>
);

Schedule.propTypes = {
  programList: PropTypes.arrayOf(
    PropTypes.shape({
      time: PropTypes.string,
      title: PropTypes.string,
      text: PropTypes.exact(RichText.propTypes),
      isBreak: PropTypes.bool
    })
  ),
  lastTitle: PropTypes.string,
  onPageEditing: PropTypes.exact({
    programList: PropTypes.string
  })
};

Schedule.defaultProps = {
  onPageEditing: {}
};
export default Schedule;
