import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/icon';
import cn from 'classnames';

const themes = {
  red: '-theme-red',
  blue: '-theme-blue',
  gray: '-theme-gray'
};

const sizes = {
  wide: '-size-wide',
  small: '-size-small'
};

const IconWarning = ({ className, theme, size }) => {
  const iconName = () => {
    let name = () => {
      switch (theme) {
        case themes.blue:
          return 'i';
        case themes.red:
          return 'warning-triangle';
        default:
          return 'warning-circle';
      }
    };
    return `${name()}`;
  };

  return (
    <Icon
      className={cn('icon-warning', size, theme, className)}
      name={iconName()}
    />
  );
};
IconWarning.propTypes = {
  theme: PropTypes.oneOf(Object.values(themes)),
  size: PropTypes.oneOf(Object.values(sizes))
};

IconWarning.propTypesMeta = {
  size: 'exclude'
};

IconWarning.themes = themes;
IconWarning.sizes = sizes;

export default IconWarning;
