import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Link from 'components-old/link';

const TagLinkList = ({ tags, leftAligned }) => (
  <div
    className={cn('tag-link-list', {
      'tag-link-list--left-align': leftAligned
    })}
  >
    {tags.map(({ link, inactive }, number) => (
      <Link
        className={cn('tag-link-list--tag', {
          '-inactive': inactive
        })}
        key={number}
        theme={[Link.themes.tag, Link.themes.orangeTag]}
        {...link}
      />
    ))}
  </div>
);

TagLinkList.propTypes = {
  leftAligned: PropTypes.bool,
  tags: PropTypes.arrayOf(
    PropTypes.exact({
      link: PropTypes.exact(Link.propTypes),
      inactive: PropTypes.bool
    })
  )
};

TagLinkList.defaultProps = {
  tags: [],
  leftAligned: false
};

export default TagLinkList;
