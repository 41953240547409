import isRunningOnClient from '@creuna/utils/is-running-on-client';
import CryptoJs from 'crypto-js';

const transformQueryHashSalt = '{aa622b05-55d8-4af3-b035-e78ca344537d}';

const transformBase = 'transform=DownFit&width=';
const breakpointResolution = 100;

function getNewSrc(originalSrc, imageNodeWidth, currentImageWidth = 0) {
  if (!originalSrc) return;
  const physicalPixelWidth = isRunningOnClient
    ? imageNodeWidth * window.devicePixelRatio
    : imageNodeWidth;

  // Return image resized up to closest 100px relative to imageNodeWidth
  const adjustedWidth =
    Math.ceil(physicalPixelWidth / breakpointResolution) * breakpointResolution;

  var transformQuery =
    transformBase + Math.max(adjustedWidth, currentImageWidth);

  const transformQueryHash = CryptoJs.MD5(
    transformQuery.replace('%2C', '%2c') + transformQueryHashSalt
  );

  return originalSrc + '?' + transformQuery + '&hash=' + transformQueryHash;
}

export default getNewSrc;
