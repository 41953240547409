import React from 'react';

import GroupedSearchPage from 'components-old/grouped-search-page';

const PublicationsPage = props => (
  <GroupedSearchPage theme={GroupedSearchPage.themes.lowercase} {...props} />
);

PublicationsPage.propTypes = GroupedSearchPage.propTypes;

export default PublicationsPage;
