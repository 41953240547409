import React from 'react';
import PropTypes from 'prop-types';

const Validation = ({ message, fields }) => (
  <div className="validation">
    {message}
    <ul>
      {fields.map(field => (
        <li key={field}>{field}</li>
      ))}
    </ul>
  </div>
);

Validation.propTypes = {
  message: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.string)
};

Validation.defaultProps = {
  fields: []
};

export default Validation;
