import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const InputControlled = ({ checked, name, onChange, value, id, dataId }) => {
  const [isChecked, setIsChecked] = useState(!!checked);

  const updateChecked = e => {
    setIsChecked(state => !state);
    onChange(e);
  };

  useEffect(() => {
    if (checked === undefined) return;
    setIsChecked(checked);
  }, [dataId]);

  return (
    <>
      <input
        type="checkbox"
        id={id}
        name={name}
        onChange={updateChecked}
        value={value}
        checked={isChecked}
      />
    </>
  );
};

InputControlled.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string,
  dataId: PropTypes.number
};

InputControlled.propTypesMeta = 'exclude';

export default InputControlled;
