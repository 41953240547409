import React from 'react';
import PropTypes from 'prop-types';

import ContentWithSidebar from 'components-old/content-with-sidebar';
import EmptyList from 'components-old/empty-list';
import FilterLayout from 'components-old/filter-layout';
import Form from 'components-old/form';
import Pagination from 'components-old/pagination';
import ArticleBlock from 'components-old/article-block';
import Spinner from 'components-old/spinner';
import useFetchFilteredResults from 'js/hooks/use-fetch-filtered-results';

const CategoryListPage = ({
  emptyList,
  filterLayout,
  form,
  groups,
  title,
  pagination,
  fetchFilteredResultsEndpoint
}) => {
  const [
    isLoading,
    {
      emptyList: emptyListState,
      filterLayout: filterLayoutState,
      pagination: paginationState,
      groups: groupsState
    },
    fetchResults
  ] = useFetchFilteredResults(
    { emptyList, filterLayout, pagination, groups },
    fetchFilteredResultsEndpoint,
    form.endpoint
  );

  return (
    <Form className="category-list-page" showSubmitButton={false} {...form}>
      <ContentWithSidebar>{title && <h1>{title}</h1>}</ContentWithSidebar>
      <FilterLayout
        {...filterLayoutState}
        fetchFilteredResults={fetchResults}
        isLoading={isLoading}
      >
        <React.Fragment>
          {groupsState && groupsState.length > 0 ? (
            groupsState.map((group, index) => (
              <ArticleBlock
                theme={[
                  ArticleBlock.themes.withBorder,
                  ArticleBlock.themes.squareImage
                ]}
                {...group}
                key={`${group.title.text}-${String(index)}`}
              />
            ))
          ) : (
            <EmptyList {...emptyListState} />
          )}
          <Spinner isActive={isLoading} />
        </React.Fragment>
        <Pagination {...paginationState} />
      </FilterLayout>
    </Form>
  );
};

CategoryListPage.propTypes = {
  emptyList: PropTypes.exact(EmptyList.propTypes),
  filterLayout: PropTypes.exact(FilterLayout.propTypes),
  form: PropTypes.exact(Form.propTypes),
  groups: PropTypes.arrayOf(PropTypes.exact(ArticleBlock.propTypes)),
  title: PropTypes.string,
  pagination: PropTypes.exact(Pagination.propTypes),
  fetchFilteredResultsEndpoint: PropTypes.string
};

CategoryListPage.defaultProps = {
  groups: []
};

export default CategoryListPage;
