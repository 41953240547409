import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';

import Image from 'components-old/image';

// NOTE: FluidImage was removed from this block on request from the customer, which is a deviation from the graphic design. In case of questions, yelling etc, refer to this issue: https://jira.ad.forskningsradet.no/browse/NYWEB-638
const ImageBlock = ({ image, onPageEditing, title }) => {
  const hasTitle = Boolean(title || onPageEditing.title);

  return (
    <div className={cn('image-block', { '-has-title': hasTitle })}>
      {hasTitle && <h2 {...OPE(onPageEditing.title)}>{title}</h2>}
      <Image {...image} />
    </div>
  );
};

ImageBlock.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),
  title: PropTypes.string
};

ImageBlock.defaultProps = {
  onPageEditing: {}
};

export default ImageBlock;
