import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'components/accordion/accordion';
import Icon from 'components/icon/icon';
import Link from 'components/link';

const TabsMobile = ({ title, tabs, accordion }) => {
  if (!(tabs || tabs.length || tabs[0])) return null;
  const activeItem = tabs.find(tab => tab.isActive);

  return (
    <Accordion {...accordion} animate={false}>
      {({ Collapse, isOpen, toggle }) => (
        <div className="tabs-mobile">
          {!isOpen && (
            <label className="tabs-mobile--label" htmlFor="selector-button">
              {title}
            </label>
          )}
          <button
            id="selector-button"
            className="tabs-mobile--dropdown-button"
            onClick={toggle}
          >
            {activeItem ? activeItem.link.text : tabs[0].link.text}
            <Icon name="arrow-down" />
          </button>
          <Collapse className="tabs-mobile--collapse">
            <div className="tabs-mobile--dropdown">
              <h4 className="tabs-mobile--dropdown-title">{title}</h4>
              <ul className="tabs-mobile--dropdown-list">
                {tabs.map(({ id, isActive, link }) => (
                  <li className="tabs-mobile--dropdown-item" key={id}>
                    <Link
                      theme={!isActive ? Link.themes.black : Link.themes.blue}
                      {...link}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </Collapse>
          {isOpen && (
            <button className="tabs-mobile--overlay" onClick={toggle} />
          )}
        </div>
      )}
    </Accordion>
  );
};

TabsMobile.propTypes = {
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
      link: PropTypes.exact(Link.propTypes).isRequired
    })
  ),
  accordion: PropTypes.exact(Accordion.propTypes)
};

TabsMobile.propTypesMeta = {
  tabs: 'exclude'
};

export default TabsMobile;
