import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ContentArea from 'components-old/content-area';
import ContentWithSidebar from 'components-old/content-with-sidebar';
import Heading from 'components-old/heading';
import RichText from 'components-old/rich-text';
import OptionsModal from 'components-old/options-modal';

const TextWithSidebar = ({
  headingLevel,
  sidebar,
  text,
  title,
  htmlId,
  share,
  isProposalPage = false
}) => (
  <ContentWithSidebar
    className={cn('text-with-sidebar', {
      '-is-proposal-page': isProposalPage,
      '-without-sidebar': !sidebar
    })}
    useContentContainer={false}
  >
    <ContentWithSidebar.Content>
      {share && (
        <div className="text-with-sidebar--links">
          <OptionsModal
            theme={OptionsModal.themes.isTextWithSidebar}
            {...share}
          ></OptionsModal>
        </div>
      )}
      {title && (
        <Heading
          id={htmlId}
          className="text-with-sidebar--title"
          level={headingLevel}
        >
          {title}
        </Heading>
      )}
      <RichText {...text} />
    </ContentWithSidebar.Content>
    {sidebar && (
      <ContentWithSidebar.Sidebar>
        <ContentArea {...sidebar} />
      </ContentWithSidebar.Sidebar>
    )}
  </ContentWithSidebar>
);

TextWithSidebar.propTypes = {
  headingLevel: PropTypes.number,
  sidebar: PropTypes.exact(ContentArea.propTypes),
  text: PropTypes.exact(RichText.propTypes),
  title: PropTypes.string,
  htmlId: PropTypes.string,
  isProposalPage: PropTypes.bool,
  share: PropTypes.exact(OptionsModal.propTypes)
};

TextWithSidebar.propTypesMeta = {
  headingLevel: 'exclude',
  isProposalPage: 'exclude',
  share: 'exclude'
};

TextWithSidebar.defaultProps = {
  headingLevel: 2
};

export default TextWithSidebar;
