import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Accordion from 'components/accordion';
import Icon from 'components/icon';

const AccordionContainer = ({ accordion, buttonText, children }) => {
  return (
    <div className="accordion-container">
      <Accordion {...accordion}>
        {({ Button, Collapse, isOpen }) => (
          <>
            {buttonText && (
              <Button className="accordion-container--button" text={null}>
                <h2 className="accordion-container--button-text">
                  {buttonText}
                </h2>

                <Icon
                  className={cn('accordion-container--icon -white', {
                    '-is-open': isOpen
                  })}
                  name="small-arrow-down"
                  fill={true}
                />
              </Button>
            )}
            <Collapse>
              <div className="accordion-container--content">{children}</div>
            </Collapse>
          </>
        )}
      </Accordion>
    </div>
  );
};

AccordionContainer.propTypes = {
  accordion: PropTypes.exact(Accordion.propTypes),
  buttonText: PropTypes.string,
  children: PropTypes.node
};

AccordionContainer.propTypesMeta = 'exclude';

export default AccordionContainer;
