import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Link from 'components-old/link';
import LinkList from 'components-old/link-list';
import NestedLink from 'components-old/nested-link';

const split = links => {
  const half =
    links.reduce((acc, item) => {
      if (!item.items) return acc + 1;
      return acc + item.items.length;
    }, 0) / 2;

  const columns = links.reduce(
    (acc, item, i) => {
      if (i === 0) acc.count = 0;
      const savedCount = acc.count;

      if (!item.items) acc.count += 1;
      else acc.count += item.items.length;

      const previousCountIsCloser =
        Math.abs(half - savedCount) < Math.abs(half - acc.count);
      if (previousCountIsCloser) acc[1].push(item);
      else acc[0].push(item);
      return acc;
    },
    [[], []]
  );

  const onlyFilledColumns = columns.filter(column => column.length);

  return onlyFilledColumns;
};

const LinkLists = ({ nestedLinks = [], singleColumn }) => {
  const columns = split(nestedLinks);

  return (
    <div className={cn('link-lists', { '-single-column': singleColumn })}>
      {columns.map((column, i) => (
        <LinkList
          key={column.title || i}
          className="link-lists--column"
          nestedLinks={column}
          linkTheme={[Link.themes.portfolio]}
          theme={LinkList.themes.portfolio}
          withIcon
        />
      ))}
    </div>
  );
};

LinkLists.propTypes = {
  nestedLinks: PropTypes.arrayOf(NestedLink.propTypes.item),
  singleColumn: PropTypes.bool
};

LinkLists.propTypesMeta = {
  singleColumn: 'exclude'
};

export default LinkLists;
