import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';

const CtaLink = ({ image, text, url }) => (
  <a href={url} className="cta-link">
    <Image {...image} />
    <span>{text}</span>
  </a>
);

CtaLink.propTypes = {
  image: PropTypes.exact(Image.propTypes),
  text: PropTypes.string,
  url: PropTypes.string
};

export default CtaLink;
