import React from 'react';

import ContentArea from 'components/content-area';

// theme for block defined here to avoid circular imports.
const richTextBlockProps = {
  RichTextBlock: { theme: '-theme-no-padding' }
};

const RichText = props => (
  <ContentArea enableElementSizing={false} additionalComponentProps={{
    ...richTextBlockProps
  }} {...props} />
);

RichText.propTypes = ContentArea.propTypes;

export default RichText;
