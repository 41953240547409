import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import FluidImage from 'components/fluid-image';
import Link from 'components/link';
import Map from 'components/map';
import Tags from 'components/tags';
import OPE from 'js/on-page-editing';
import hyphenate from 'js/utils/hyphenate';

import cn from 'classnames';

const PageHeader = ({
  image,
  ingress,
  map,
  tags,
  title,
  portfolioLinks,
  onPageEditing
}) => {
  return (
    <div
      className={cn('page-header', {
        'page-header--title-only': !image && !map
      })}
    >
      {title && !image && !map && (
        <h1
          className="page-header--title"
          {...OPE(onPageEditing.title)}
          {...hyphenate(title)}
        />
      )}
      {(image ||
        map ||
        ingress ||
        (portfolioLinks && portfolioLinks.length > 0)) && (
          <div className="page-header--content">
            {title && (image || map) && (
              <h1
                className="page-header--title"
                {...OPE(onPageEditing.title)}
                {...hyphenate(title)}
              />
            )}
            {ingress && (
              <p className="page-header--ingress" {...OPE(onPageEditing.ingress)}>
                {ingress}
              </p>
            )}
            {portfolioLinks && (
              <div className="page-header--links" {...OPE(onPageEditing.links)}>
                {portfolioLinks.map((link, index) => (
                  <Link
                    className="page-header--link"
                    key={`${link.url}-${index}`}
                    useButtonStyles
                    theme={[
                      index === 0
                        ? Button.themes.primary
                        : Button.themes.secondary,
                      Button.themes.large
                    ]}
                    {...link}
                  />
                ))}
              </div>
            )}
            {tags && <Tags {...tags} />}
          </div>
        )}
      {(image || map) && (
        <div className="page-header--image-or-map">
          {!map && <FluidImage {...image} theme={FluidImage.themes.anchored} />}
          {!image && <Map {...map} />}
        </div>
      )}
    </div>
  );
};

PageHeader.propTypes = {
  image: PropTypes.exact(FluidImage.propTypes),
  ingress: PropTypes.string,
  portfolioLinks: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  map: PropTypes.exact(Map.propTypes),
  title: PropTypes.string,
  tags: PropTypes.exact(Tags.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string,
    ingress: PropTypes.string,
    links: PropTypes.string
  })
};

PageHeader.defaultProps = {
  onPageEditing: {}
};

export default PageHeader;
