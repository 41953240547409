const convertToPxs = (width, height, coords) => {
  const toPx = (dimension, percent) => Math.round(dimension * (percent / 100));

  return [toPx(width, coords[0]), toPx(height, coords[1])];
};

const applyPositions = (start, end, absoluteCurve) => {
  const addPos = (pointCoord, bezierCoord) =>
    pointCoord.map((pos, i) => pos + bezierCoord[i]);

  return [
    ...addPos(start, absoluteCurve.slice(0, 2)),
    ...addPos(end, absoluteCurve.slice(2))
  ];
};

const pushBeziers = (beziers, mousePos, softAnimation) => {
  const mapRange = n => {
    //map 0-radius+ to max-1
    let radius = 400;
    let max = 1.6;
    if (softAnimation) {
      radius = 200;
      max = 1.4;
    }

    n = Math.min(n, radius);
    const ratio = (1 - max) / (radius - 0);
    const y = (n - 0) * ratio + max;
    return y;
  };

  const calcDistance = ([x, y]) => Math.sqrt(x ** 2 + y ** 2);

  const diffs = beziers.map((pos, i) => pos - mousePos[i % mousePos.length]);

  const distances = [
    calcDistance(diffs.slice(0, 2)),
    calcDistance(diffs.slice(2))
  ];

  const scalars = distances.map(distance => mapRange(distance));

  const scaledBeziers = diffs.map((diff, i) => {
    const distanceIndex = i <= 1 ? 0 : 1;
    return diff * scalars[distanceIndex] + mousePos[i % mousePos.length];
  });

  return scaledBeziers;
};

export { convertToPxs, applyPositions, pushBeziers };
