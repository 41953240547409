import React from 'react';

import TimelineHorizontal from 'components-old/timeline-horizontal';

const TimelineHorizontalBlock = props => (
  <TimelineHorizontal
    numberOfSlidesToShow={{
      '0px': 1,
      '510px': 2,
      '768px': 3
    }}
    theme={TimelineHorizontal.themes.block}
    {...props}
  />
);

TimelineHorizontalBlock.propTypes = TimelineHorizontal.propTypes;

export default TimelineHorizontalBlock;
