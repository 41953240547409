import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import OPE from 'js/on-page-editing';

import RichText from 'components-old/rich-text';
import AccordionWithContentArea from 'components-old/accordion-with-content-area';

const AccordionWithContentAreaList = ({
  content,
  description,
  htmlId,
  onPageEditing,
  richText,
  text,
  themeTags,
  title
}) => {
  const [anchorHashList, setAnchorHashList] = useState([]);

  const addAnchorHash = anchorHash =>
    setAnchorHashList((state = []) => [anchorHash, ...state]);

  const removeAnchorHash = anchorHash =>
    setAnchorHashList(state => state.filter(hash => hash !== anchorHash));

  const effectDependencies = !anchorHashList || anchorHashList.length;
  useEffect(() => {
    if (!anchorHashList.length) {
      return;
    }

    const hash = anchorHashList[0] || ' ';
    history.replaceState(undefined, undefined, hash);
  }, [effectDependencies]);

  return (
    <div className="accordion-with-content-area-list">
      <div className="accordion-with-content-area-list-header">
        {title && (
          <h2 id={htmlId} {...OPE(onPageEditing.title)}>
            {title}
          </h2>
        )}
        {description && <p>{description}</p>}
        {themeTags && !!themeTags.length && (
          <div className="accordion-with-content-area-list--tag-list">
            {themeTags.map((tag, index) => (
              <span
                className="accordion-with-content-area-list--tag-list-item"
                key={tag + index}
              >
                {tag}
              </span>
            ))}
          </div>
        )}
        {text && !themeTags && <p>{text}</p>}

        {richText && <RichText {...richText} />}
      </div>
      {content &&
        content.map(acc => (
          <AccordionWithContentArea
            key={acc.title}
            {...acc}
            onPageEditing={onPageEditing}
            addAnchorHash={addAnchorHash}
            removeAnchorHash={removeAnchorHash}
          />
        ))}
    </div>
  );
};

AccordionWithContentAreaList.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.exact(AccordionWithContentArea.propTypes)
  ),
  description: PropTypes.string,
  htmlId: PropTypes.string,
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),
  richText: PropTypes.exact(RichText.propTypes),
  title: PropTypes.string,
  themeTags: PropTypes.arrayOf(PropTypes.string),
  text: PropTypes.string
};

AccordionWithContentAreaList.defaultProps = {
  onPageEditing: {}
};

export default AccordionWithContentAreaList;
