import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useResize from 'js/hooks/resize';

const Ref = ({ children, className, refValue, refKey }) => {
  const compRef = useRef();

  const forceUpdate = useState()[1];
  useResize(forceUpdate);

  const effectDependency = compRef.current && compRef.current[refKey];

  useEffect(() => {
    refValue(compRef.current[refKey]);
  }, [effectDependency]);

  return (
    <div className={className} ref={compRef}>
      {children}
    </div>
  );
};

Ref.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  sendRef: PropTypes.func
};

Ref.propTypesMeta = 'exclude';

export default Ref;
