import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import logo from '../../static-site/assets/logo-white.svg';

const SimpleHeader = ({ url }) => {
  useEffect(() => {
    //Make space in body to prevent overlap
    const body = document.body;
    if (body) {
      body.classList.add('-with-simple-header');
    }

    return () => body && body.classList.remove('-with-simple-header');
  }, []);

  return (
    <a href={url} className="simple-header">
      <img className="simple-header--img" alt="Logo" src={logo} />
    </a>
  );
};

SimpleHeader.propTypes = {
  url: PropTypes.string
};

export default SimpleHeader;
