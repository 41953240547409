import React from 'react';
import PropTypes from 'prop-types';

import ContentArea from 'components/content-area';
import ContentAreaItem from 'components/content-area-item';
import Link from 'components/link';
import TabList from 'components/tabs/tab-list';
import TabsMobile from 'components/tabs-mobile/tabs-mobile';
import useIsScreenSize from 'js/hooks/use-is-screen-size';

const OfficeListBlock = ({ links, selector, offices }) => {
  const isMobile = useIsScreenSize('mdMax');

  return (
    <div className="office-list-block">
      {isMobile ? (
        <TabsMobile {...selector} tabs={links} />
      ) : (
        <TabList className="event-list-page--tab-list non-breakable-tab-columns">
          {links.map(({ isActive, link, id }, index) => (
            <TabList.Item
              key={id}
              isCurrent={isActive}
              length={links.length}
              index={index}
              currentIndex={links.findIndex(({ isActive }) => isActive)}
            >
              <Link theme={isActive ? Link.themes.black : []} {...link} />
            </TabList.Item>
          ))}
        </TabList>
      )}
      <div className="office-list-block--offices">
        <ContentArea
          additionalItemProps={{
            OfficeBlock: { theme: ContentAreaItem.themes.largeMargin }
          }}
          {...offices}
        />
      </div>
    </div>
  );
};

OfficeListBlock.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      isActive: PropTypes.bool,
      link: PropTypes.exact(Link.propTypes).isRequired
    })
  ),
  selector: PropTypes.exact(TabsMobile.propTypes),
  offices: PropTypes.exact(ContentArea.propTypes)
};

OfficeListBlock.defaultProps = {
  links: []
};

export default OfficeListBlock;
