import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import useSlider from 'js/hooks/use-slider';
import resizeHook from 'js/hooks/resize';

const PlayerTimeline = ({ progress, setTime, className }) => {
  const forceUpdate = useState()[1];
  resizeHook(forceUpdate);

  const timelineRef = useRef();
  const { value, activate, activateKeys, deactivateKeys, isActive } = useSlider(
    timelineRef
  );

  const progressInPxs =
    timelineRef.current && progress * timelineRef.current.offsetWidth;

  useEffect(() => {
    value && setTime(value);
  }, [value]);

  return (
    <div
      className={cn('player-timeline', className)}
      onFocus={activateKeys}
      onBlur={deactivateKeys}
      onTouchStart={activate}
      onMouseDown={activate}
      tabIndex={0}
    >
      <div className="player-timeline--track" ref={timelineRef}>
        <span
          className="player-timeline--marker"
          style={{
            transform: `translateX(${progressInPxs}px)`,
            transition: !isActive && 'transform 0.2s linear'
          }}
        ></span>
      </div>
    </div>
  );
};

PlayerTimeline.propTypes = {
  progress: PropTypes.number,
  setTime: PropTypes.func
};

PlayerTimeline.propTypesMeta = 'exclude';

export default PlayerTimeline;
