import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/button';
import ContentContainer from 'components/content-container';
import FluidImage from 'components/fluid-image';
import Link from 'components/link';
import Map from 'components/map';
import cn from 'classnames';

import OPE from 'js/on-page-editing';

const textColors = {
  light: '-light-text'
};

const SatelliteHeader = ({
  image,
  ingress,
  links,
  map,
  onPageEditing,
  title,
  text,
  textColor
}) => {
  return (
    <div className="satellite-header">
      <div
        className={cn('satellite-header__top', {
          'satellite-header__top--large': !!image || !!links.length
        })}
      >
        {map && <Map {...map} />}
        {image && <FluidImage className="satellite-header__image" {...image} />}
        <ContentContainer className="satellite-header__content">
          {text && (
            <h2
              className={cn('satellite-header__lead', textColor)}
              {...OPE(onPageEditing.text)}
            >
              {text}
            </h2>
          )}
          {!!links.length && (
            <div
              className="satellite-header__links"
              {...OPE(onPageEditing.links)}
            >
              {links.map(link => (
                <Link
                  className="satellite-header__link"
                  key={link.url}
                  useButtonStyles={true}
                  theme={[Button.themes.primary]}
                  {...link}
                />
              ))}
            </div>
          )}
        </ContentContainer>
      </div>
      <div className="satellite-header__bottom">
        {title && (
          <h1 className="satellite-header__title" {...OPE(onPageEditing.title)}>
            {title}
          </h1>
        )}
        {ingress && (
          <p
            className="satellite-header__ingress"
            {...OPE(onPageEditing.ingress)}
          >
            {ingress}
          </p>
        )}
      </div>
    </div>
  );
};

SatelliteHeader.propTypes = {
  image: PropTypes.exact(FluidImage.propTypes),
  ingress: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  map: PropTypes.exact(Map.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string,
    ingress: PropTypes.string,
    text: PropTypes.string,
    links: PropTypes.string
  }),
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  textColor: PropTypes.oneOf(Object.values(textColors))
};

SatelliteHeader.defaultProps = {
  onPageEditing: {},
  links: []
};

export default SatelliteHeader;
