import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import OPE from 'js/on-page-editing';

import getVideoId from 'get-video-id';
import YouTube from 'react-youtube';

const YoutubeVideo = ({ onPageEditing, urlOrEmbed, play }) => {
  const playerRef = useRef();
  const { id, service } = getVideoId(urlOrEmbed);

  useEffect(() => {
    const player = playerRef.current;
    if (player && play) {
      player.playVideo();
      const iframe = player.f;
      const requestFullScreen =
        iframe.requestFullScreen ||
        iframe.webkitRequestFullScreen ||
        iframe.mozRequestFullScreen ||
        iframe.msRequestFullscreen;
      if (requestFullScreen) {
        requestFullScreen.bind(iframe)();
      }
    }
  }, [play]);

  // According to the spec, only youtube should be supported:
  // https://confluence.ad.forskningsradet.no/display/NYWEB/Embed-blokker
  if (service !== 'youtube' || !id) {
    return <div className="youtube-video" {...OPE(onPageEditing)} />;
  }

  const opts = {
    width: '100%',
    playerVars: {
      autoplay: 0,
      modestbranding: 1,
      rel: 0
    }
  };

  return (
    <div className="youtube-video" {...OPE(onPageEditing)}>
      <YouTube
        className="youtube-video--iframe-container"
        opts={opts}
        videoId={id}
        onReady={e => (playerRef.current = e.target)}
        title="Youtube video"
      />
    </div>
  );
};

YoutubeVideo.propTypes = {
  onPageEditing: PropTypes.string,
  urlOrEmbed: PropTypes.string,
  play: PropTypes.bool
};

YoutubeVideo.defaultProps = {
  urlOrEmbed: '' // get-video-id crashes when argument is undefined
};

YoutubeVideo.propTypesMeta = {
  play: 'exclude'
};

export default YoutubeVideo;
