import React from 'react';
import PropTypes from 'prop-types';
import OPE from 'js/on-page-editing';
import YoutubeVideo from 'components/youtube-video';
import EmbedBlock from 'components/embed-block';
import FluidImage from 'components/fluid-image';
import Link from 'components/link';
import Image from 'components/image';

import cn from 'classnames';

const MediaBlock = ({
  title,
  onPageEditing,
  youTubeVideo,
  embed,
  image,
  link,
  icon,
  caption,
  url
}) => {
  const hasTitle = Boolean(title || onPageEditing.title);

  return (
    <div className={cn('media-block', { '-has-title': hasTitle })}>
      {title && (
        <h2 {...OPE(onPageEditing.title)}>
          {link ? (
            <Link
              {...{
                url,
                text: title,
                theme: [Link.themes.actionLink, Link.themes.large]
              }}
            />
          ) : (
            title
          )}
        </h2>
      )}
      <div className="media-block__content">
        {youTubeVideo && (
          <div>
            {icon && <Image className="media-block__youtube-icon" {...icon} />}
            <YoutubeVideo {...youTubeVideo} />
          </div>
        )}
        {embed && (
          <div className="media-block__embed">
            <EmbedBlock {...embed}></EmbedBlock>
          </div>
        )}
        {image && (
          <div className="media-block__image">
            <FluidImage {...image} theme={FluidImage.themes.anchored}></FluidImage>
          </div>
        )}
        {caption && (
          <div
            className="media-with-caption__caption"
            {...OPE(onPageEditing.caption)}
          >
            {caption}
          </div>
        )}
        {link && (
          <Link
            className="media-block__link"
            {...{
              url: link.url,
              text: link.text,
              theme: [Link.themes.actionLink]
            }}
          ></Link>
        )}
      </div>
    </div>
  );
};

MediaBlock.propTypes = {
  title: PropTypes.string,
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),
  youTubeVideo: PropTypes.exact(YoutubeVideo.propTypes),
  embed: PropTypes.exact(EmbedBlock.propTypes),
  image: PropTypes.exact(FluidImage.propTypes),
  link: PropTypes.exact(Link.propTypes),
  icon: PropTypes.exact(Image.propTypes),
  caption: PropTypes.string,
  url: PropTypes.string
};

MediaBlock.defaultProps = {
  onPageEditing: {}
};

export default MediaBlock;
