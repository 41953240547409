import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-tiny-transition';
import cn from 'classnames';

import useIsScreenSize from 'js/hooks/use-is-screen-size';

import Button from 'components-old/button';
import ContentWithSidebar from 'components-old/content-with-sidebar';
import Filters from 'components-old/filters';
import ContentArea from 'components-old/content-area';
import useBlockScrolling from 'js/hooks/use-block-scrolling';

const FilterLayout = ({
  children,
  contentArea,
  filters,
  topContent,
  includeBottomBorder,
  className,
  fetchFilteredResults,
  isLeft,
  isLoading
}) => {
  const [mobileFiltersVisible, setMobileFiltersVisible] = React.useState(false);
  const isDesktop = useIsScreenSize('lg');

  useBlockScrolling(mobileFiltersVisible);

  return (
    <div className={cn('filter-layout', className)}>
      <ContentWithSidebar
        theme={
          isLeft && isDesktop
            ? ContentWithSidebar.themes.sidebarLeft
            : undefined
        }
      >
        <ContentWithSidebar.Content
          className={cn('filter-layout--header', {
            'bottom-border': includeBottomBorder
          })}
        >
          <div className="filter-layout--top-line">{topContent}</div>

          {filters && (
            <Button
              className="filter-layout--toggle"
              onClick={() => setMobileFiltersVisible(!mobileFiltersVisible)}
              icon={mobileFiltersVisible ? 'close-small' : 'filter-small'}
              iconFill
              text={filters.mobileTitle}
            />
          )}
        </ContentWithSidebar.Content>

        <ContentWithSidebar.Sidebar />
        <Transition>
          {filters && mobileFiltersVisible && !isDesktop && (
            <div className="filter-layout--filters -is-mobile">
              <Filters
                {...filters}
                fetchFilteredResults={fetchFilteredResults}
                isDesktop={isDesktop}
                close={() => setMobileFiltersVisible(false)}
                isLoading={isLoading}
              />
            </div>
          )}
        </Transition>
      </ContentWithSidebar>

      <ContentWithSidebar
        theme={
          isLeft && isDesktop
            ? ContentWithSidebar.themes.sidebarLeft
            : undefined
        }
      >
        <ContentWithSidebar.Content className="filter-layout--content">
          {children}
        </ContentWithSidebar.Content>

        <ContentWithSidebar.Sidebar>
          {filters && isDesktop && (
            <div className="filter-layout--filters -is-desktop">
              <Filters
                {...filters}
                fetchFilteredResults={fetchFilteredResults}
              />
            </div>
          )}
          {contentArea && <ContentArea {...contentArea} />}
        </ContentWithSidebar.Sidebar>
      </ContentWithSidebar>
    </div>
  );
};

FilterLayout.propTypes = {
  children: PropTypes.node,
  contentArea: PropTypes.exact(ContentArea.propTypes),
  filters: PropTypes.exact(Filters.propTypes),
  topContent: PropTypes.node,
  includeBottomBorder: PropTypes.bool,
  fetchFilteredResults: PropTypes.func,
  className: PropTypes.string,
  isLeft: PropTypes.bool,
  isLoading: PropTypes.bool
};

FilterLayout.defaultProps = {
  labels: {},
  includeBottomBorder: true
};

FilterLayout.propTypesMeta = {
  includeBottomBorder: 'exclude',
  className: 'exclude',
  isLoading: 'exclude'
};

export default FilterLayout;
