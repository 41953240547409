import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components-old/link';

const StickyMenuOnTabsItem = ({ link }) => {
  return (
    <li>
      <Link {...link} />
    </li>
  );
};

StickyMenuOnTabsItem.propTypes = {
  link: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string
  })
};

export default StickyMenuOnTabsItem;
