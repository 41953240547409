import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import OPE from 'js/on-page-editing';

const Screen9 = ({
  embed,
  title,
  onPageEditing
}) => {

  return (
    <div className="screen9-video-container">

    <iframe
      allow="autoplay; fullscreen"
      allowFullScreen
      referrerPolicy="no-referrer-when-downgrade"
      src={embed}
      className="screen9-video-iframe"
      title={title}
    />
    </div>
  );
};


Screen9.propTypes = {
  embed: PropTypes.string
};

export default Screen9;
