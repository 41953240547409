import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from 'components/icon';

import Link from 'components/link';

const PageNavigation = ({ previous, next, isLargeArticle, isFirstPage }) => {
  const styleLinkProps = isLargeArticle
    ? {
      previous: { theme: [Link.themes.previous, Link.themes.navigationLinkNP] },
      next: { theme: [Link.themes.next, Link.themes.navigationLinkNP] }
    }
    : {
      previous: { theme: [Link.themes.previous, Link.themes.navigationLink, Link.themes.actionLink] },
      next: { theme: [Link.themes.next, Link.themes.navigationLink, Link.themes.actionLink] }
    };

  return (
    <div
      className={cn('page-navigation', {
        '-large-article-theme': isLargeArticle
      })}
    >
      {previous && (
        <Link
          children={isLargeArticle && <Icon name={'arrow-short'} />}
          {...previous}
          {...styleLinkProps.previous}

        />
      )}
      {next && (
        <Link
          children={isLargeArticle && <Icon name={'arrow-short'} />}
          className={cn({ '-is-first-page': isFirstPage })}
          {...next}
          {...styleLinkProps.next}
        />
      )}
    </div>
  );
};

PageNavigation.propTypes = {
  previous: PropTypes.shape(Link.propTypes),
  next: PropTypes.shape(Link.propTypes)
};

export default PageNavigation;
