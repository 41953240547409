import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import hyphenate from 'js/utils/hyphenate';
import OPE from 'js/on-page-editing';
// NOTE: levelOffset is intended to be used by the backend only, as a way to describe the semantic relationships between blocks without having to know what the actual heading levels are.

const Heading = forwardRef(
  ({ className, children, id, level, levelOffset, onPageEditing }, ref) => {
    const HeadingTag = `h${level + levelOffset}`;

    return (
      <HeadingTag
        className={cn('heading', className)}
        id={id}
        {...hyphenate(children)}
        {...OPE(onPageEditing)}
        ref={ref}
      />
    );
  }
);

Heading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.string,
  level: PropTypes.number,
  levelOffset: PropTypes.number,
  onPageEditing: PropTypes.string
};

Heading.defaultProps = {
  level: 2,
  levelOffset: 0
};

Heading.propTypesMeta = 'exclude';

export default Heading;
