import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components-old/button';
import Link from 'components-old/link';

const TabMenu = ({ mainContentLink, openMenu, openMenuLabel }) => (
  <nav className="tab-menu">
    <ul>
      <li>
        <Link theme={Link.themes.white} {...mainContentLink} />
      </li>
      <li>
        <Button onClick={openMenu}>{openMenuLabel}</Button>
      </li>
    </ul>
  </nav>
);

TabMenu.propTypes = {
  mainContentLink: PropTypes.exact(Link.propTypes),
  openMenu: PropTypes.func,
  openMenuLabel: PropTypes.string
};

export default TabMenu;
