import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import FluidImage from 'components/fluid-image';
import Link from 'components/link';
import Heading from 'components/heading/heading';
import useContentHeight from 'js/hooks/use-content-height';

const themes = {
  dark: '-theme-dark',
  np: '-theme-np'
};

const CampaignBlock = ({
  cta,
  editorTheme,
  image,
  onPageEditing,
  title,
  description,
  listLength = 1,
  isProminent
  //isReversed
}) => {
  const [height, contentRef] = useContentHeight();

  const Container = isProminent ? 'div' : React.Fragment;
  return (
    <Container className="campaign-block-container" style={isProminent ? { height } : undefined}>
      <div
        className={cn('campaign-block', editorTheme, {
          '-small': listLength > 1,
          '-extra-small': listLength > 2,
          '-theme-prominent': isProminent
          //'-is-reversed': (isReversed && listLength < 2) || isProminent
        })}
        ref={contentRef}
      >
        {image && (
          <div className="campaign-block--image">
            <FluidImage {...image} theme={FluidImage.themes.anchored} />
          </div>
        )}
        <div className="campaign-block--text">
          {title && (
            <Heading
              className="campaign-block--title"
              onPageEditing={onPageEditing.title}
            >
              {title}
            </Heading>
          )}
          {description && (
            <p className="campaign-block--description">{description}</p>
          )}
          <Link
            className="campaign-block--link"
            {...cta}
            theme={Link.themes.actionLink}
          />
        </div>
      </div>
    </Container>
  );
};

CampaignBlock.propTypes = {
  cta: PropTypes.exact(Link.propTypes),
  editorTheme: PropTypes.oneOf(Object.values(themes)),
  image: PropTypes.exact(FluidImage.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  listLength: PropTypes.number,
  isProminent: PropTypes.bool
  //isReversed: PropTypes.bool
};

CampaignBlock.defaultProps = {
  onPageEditing: {}
};

CampaignBlock.propTypesMeta = {
  listLength: 'exclude'
};

export default CampaignBlock;
