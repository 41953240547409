import scrollingElement from '@creuna/utils/scrolling-element';

if (typeof document !== 'undefined') {
  if (typeof CSS === 'undefined' || !CSS.supports('scroll-behavior: smooth')) {
    // NOTE: The polyfill makes Chrome flicker on every page load, so it's loaded only when needed.
    require('scroll-behavior-polyfill');
  }

  // NOTE: Delay this because if the style is set on first render, Safari does all kinds of crazy scrolling stuff
  setTimeout(() => {
    // NOTE: The polyfill needs an inline style in order to work
    scrollingElement.style.scrollBehavior = 'smooth';
  }, 500);
}
