import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Accordion from 'components/accordion/accordion';
import Heading from 'components/heading/heading';
import Icon from 'components/icon/icon';

const TableMobileItem = ({ list, accordion }) => {
  const title = list[1].value;
  const subtitle = `${list[0].label} ${list[0].value}`;
  return (
    <div className="table-mobile-item">
      <Accordion {...accordion} buttonTextForLabel={`${title}`}>
        {({ Collapse, toggle, isOpen }) => (
          <>
            <button className="table-mobile-item--header" onClick={toggle}>
              <Heading className="table-mobile-item--title">
                {title}
                <Icon
                  className={cn('table-mobile-item--icon', {
                    '-opened': isOpen
                  })}
                  name="small-arrow-down"
                  fill={true}
                />
              </Heading>
              <div>{subtitle}</div>
            </button>
            <Collapse>
              <div className="table-mobile-item--list">
                {list.slice(2).map(({ displayValue, label, value }) => (
                  <React.Fragment key={value}>
                    <div className="table-mobile-item--list-label">{label}</div>
                    <div className="table-mobile-item--list-value">
                      {displayValue || value}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            </Collapse>
          </>
        )}
      </Accordion>
    </div>
  );
};

TableMobileItem.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      value: PropTypes.string,
      displayValue: PropTypes.string
    })
  ),
  accordion: PropTypes.exact(Accordion.propTypes)
};

export default TableMobileItem;
