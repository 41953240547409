import React from 'react';
import PropTypes from 'prop-types';
import Card from 'components/card';
import AutoGrid from 'components/auto-grid';

const ArticleCardList = ({ list }) => {


  return (
    <div className="article-card-list">
      <AutoGrid minWidth={330}>
        {AutoGridItem =>
          list &&
          list.map(articleCard => (
            <AutoGridItem key={articleCard.title}>
              <Card {...articleCard} />
            </AutoGridItem>
          ))
        }
      </AutoGrid>
    </div>
  );
};

ArticleCardList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.exact(Card.propTypes))
};

export default ArticleCardList;
