import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import ContentContainer from 'components-old/content-container';
import SimpleHeader from 'components-old/header/simple-header';

const NewsletterAdministrationLayout = ({ children, className, header }) => (
  <div className={cn('newsletter-administration-layout', className)}>
    <SimpleHeader theme={SimpleHeader.themes.light} {...header} />
    <ContentContainer theme={ContentContainer.themes.narrow}>
      {children}
    </ContentContainer>
  </div>
);

NewsletterAdministrationLayout.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  header: PropTypes.exact(SimpleHeader.propTypes)
};

NewsletterAdministrationLayout.propTypesMeta = {
  className: 'exclude'
};

export default NewsletterAdministrationLayout;
