const getTimestampDifference = (current, previous, type = 'DAYS') => {
  let difference = 0;

  const divideBy = divider => Math.floor((current - previous) / divider);

  switch (type) {
    case 'DAYS':
      difference = divideBy(1000 * 60 * 60 * 24);
      break;
    case 'HOURS':
      difference = divideBy(1000 * 60 * 60);
      break;
    case 'MINUTES':
      difference = divideBy(1000 * 60);
      break;
    case 'SECONDS':
      difference = divideBy(1000);
      break;
    default:
      // default to milliseconds
      difference = divideBy(1);
  }

  return difference;
};

const serializeWithTimestamp = data =>
  JSON.stringify({
    timestamp: Date.now(),
    data: data
  });

const deserializeWithTimestamp = valueInLocalStorage =>
  JSON.parse(valueInLocalStorage).data;

const getKeysFromLocalStorageThatMatchesPrefix = prefix => {
  let keys = [];

  if (prefix === null) return keys;

  for (var i = 0; i < window.localStorage.length; i++) {
    if (window.localStorage.key(i).startsWith(prefix)) {
      keys.push(window.localStorage.key(i));
    }
  }
  return keys;
};

/* 
	TYPE = DAYS, HOURS, MINUTES, SECONDS
*/
const deleteFromLocalStorage = (keys, frequency = 7, type = 'DAYS') =>
  keys.forEach(key => {
    const valueInLocalStorage = window.localStorage.getItem(key);
    if (valueInLocalStorage) {
      try {
        let timestamp = JSON.parse(valueInLocalStorage).timestamp;
        let diffInMinutes = getTimestampDifference(Date.now(), timestamp, type);
        if (diffInMinutes >= frequency) {
          window.localStorage.removeItem(key);
        }
      } catch (error) {
        window.localStorage.removeItem(key);
      }
    }
  });

export {
  getKeysFromLocalStorageThatMatchesPrefix,
  deleteFromLocalStorage,
  serializeWithTimestamp,
  deserializeWithTimestamp
};
