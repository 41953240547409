import React from 'react';
import PropTypes, { string } from 'prop-types';
import TextInput from 'components/form-elements/text-input';
import cn from "classnames"

const GenericTextInputGroup = ({
    title,
    options = []

}) => {

    return (
        <div className={cn('generic-input-and-header')}>
            {title && (
                <h2>{title}</h2>
            )}
            {options.map((textInput, i) => (
                <TextInput
                    key={textInput.name}
                    theme={TextInput.themes.orangeFocus}
                    name={textInput.name}
                    value={textInput.value}
                    label={textInput.label}
                    type="text"
                />
            ))}
        </div>

    );
}

GenericTextInputGroup.propTypes = {
    title: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string
    }))
}


export default GenericTextInputGroup;
