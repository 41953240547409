import React from 'react';
import PropTypes from 'prop-types';

import OfficeBlockInfo from './office-block-info';

const OfficeBlock = ({ info, title }) => (
  <div className="office-block">
    {title && <h2>{title}</h2>}
    <div className="office-block--info">
      {info.map(infoItem => (
        <div className="office-block--info-item" key={infoItem.title}>
          <OfficeBlockInfo {...infoItem} />
        </div>
      ))}
    </div>
  </div>
);

OfficeBlock.propTypes = {
  info: PropTypes.arrayOf(PropTypes.exact(OfficeBlockInfo.propTypes)),
  title: PropTypes.string
};

OfficeBlock.defaultProps = {
  info: []
};

export default OfficeBlock;
