import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Proposal from 'components/proposal-list-page/proposal';
const ProposalCardList = ({ proposals }) => {
  return (
    <div className="proposal-card-list">
      {proposals.map(proposal => (
        <Proposal headingLevel={2} key={proposal.id} {...proposal} />
      ))}
    </div>
  );
};

ProposalCardList.propTypes = {
  proposals: PropTypes.arrayOf(PropTypes.exact(Proposal.propTypes))
};

export default ProposalCardList;
