const getOnPageEditingProps = (propertyName, id) =>
  !propertyName
    ? {}
    : {
        'data-epi-block-id': id,
        'data-epi-content-name': propertyName,
        'data-epi-use-mvc': 'True',
        'data-content-group': true
      };

export default getOnPageEditingProps;
