import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import ContentContainer from 'components/content-container';
import Link from 'components/link';
import Message from 'components/message';
import ProposalData from './proposal-data';
import ProposalContact from './proposal-contact';
import RichText from 'components/rich-text';
import TimelineHorizontal from 'components/timeline-horizontal';
import Icon from 'components/icon';
import OptionsModal from 'components/options-modal';
import DateCardStatus from 'components/date-card/date-card-status';
import ArticleHeader from 'components/article-header';
import TimelineVertical from 'components/timeline-vertical';
import Tabs from 'components/tabs';
import Status from 'components/status';

const ProposalPage = ({
  applyButton,
  contact,
  contactLabel,
  download,
  metadataLeft,
  metadataRight,
  message,
  descriptionTitle,
  descriptionText,
  descriptionIngress,
  tabs,
  title,
  timelineHorizontal,
  share,
  isInternational,
  statusList,
  header,
  timelineVertical
}) => (
  <div className="proposal-page">
    <ContentContainer>
      {statusList && (
        <div className="proposal-page__status-container">
          {statusList.map(status => (
            // <DateCardStatus {...status} key={status.text} />
            <Status {...status} key={status.text} />
          ))}
        </div>
      )}
      {title && (
        <h1 className="proposal-page--heading" data-epi-type="title">
          {isInternational && (
            <Icon className="proposal-page--icon" name="globe"></Icon>
          )}
          {title}
        </h1>
      )}
      {header && <ArticleHeader {...header} />}
    </ContentContainer>
    <div className="proposal-page--header">
      <ContentContainer>
        <div className="proposal-page--metadata" data-epi-type="content">
          <div className="proposal-page--metadata-column">
            <ProposalData {...metadataLeft} />
          </div>
          <div className="proposal-page--metadata-column">
            <ProposalData {...metadataRight} />
            {contact && (
              <div className="proposal-page--contact">
                <span>{contactLabel}</span>
                <ProposalContact {...contact} />
              </div>
            )}
          </div>
        </div>
        <div className="proposal-page--header-actions">
          <Link
            className="proposal-page--apply-button"
            theme={[Button.themes.primary]}
            useButtonStyles
            {...applyButton}
          />
          {download && (
            <OptionsModal
              className="proposal-page--download"
              theme={OptionsModal.themes.isProposalOrEvent}
              {...download}
            />
          )}
        </div>
      </ContentContainer>
    </div>
    {message && (
      <ContentContainer className="proposal-page--message-container">
        <Message {...message} />
      </ContentContainer>
    )}
    {timelineHorizontal && <TimelineHorizontal {...timelineHorizontal} />}
    {timelineVertical && (
      <TimelineVertical isBlock={false} {...timelineVertical} />
    )}
    <ContentContainer className="proposal-page--description">
      {descriptionTitle && <h2 data-epi-type="title">{descriptionTitle}</h2>}
      <div data-epi-type="content">
        {descriptionIngress && (
          <div className="proposal-page--description-ingress">
            {descriptionIngress}
          </div>
        )}

        <RichText {...descriptionText} />
      </div>
    </ContentContainer>
    {tabs && <Tabs {...tabs} />}
  </div>
);

ProposalPage.propTypes = {
  applyButton: PropTypes.exact(Link.propTypes),
  contact: PropTypes.exact(ProposalContact.propTypes),
  contactLabel: PropTypes.string,
  download: PropTypes.exact(OptionsModal.propTypes),
  header: PropTypes.exact(ArticleHeader.propTypes),
  metadataLeft: PropTypes.exact(ProposalData.propTypes),
  metadataRight: PropTypes.exact(ProposalData.propTypes),
  message: PropTypes.exact(Message.propTypes),
  descriptionTitle: PropTypes.string,
  descriptionText: PropTypes.exact(RichText.propTypes),
  descriptionIngress: PropTypes.string,
  tabs: PropTypes.exact(Tabs.propTypes),
  timelineHorizontal: PropTypes.exact(TimelineHorizontal.propTypes),
  title: PropTypes.string,
  isInternational: PropTypes.bool,
  share: PropTypes.exact(OptionsModal.propTypes),
  statusList: PropTypes.arrayOf(PropTypes.exact(DateCardStatus.propTypes)),
  timelineVertical: PropTypes.exact(TimelineVertical.propTypes)
};

ProposalPage.defaultProps = {
  downloadLinks: []
};

export default ProposalPage;
