import React from 'react';
import PropTypes from 'prop-types';

import ContactListItem from 'components/contact-list/contact-list-item';

const ContactList = ({ contactGroups = [] }) => {
  return (
    <div className="contact-list">
      {contactGroups.map(contactGroup => (
        <ContactListItem key={contactGroup.title} {...contactGroup} />
      ))}
    </div>
  );
};

ContactList.propTypes = {
  contactGroups: PropTypes.arrayOf(PropTypes.exact(ContactListItem.propTypes))
};

export default ContactList;
