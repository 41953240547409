import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import api from 'js/api-helper';

import Button from 'components/button';
import Link from 'components/link';
import TextInput from 'components/form-elements/text-input';
import SearchSuggestions from 'components/search-suggestions';
import propTypeTheme from 'utils/prop-type-theme';

const Search = ({
  externalResultsLabel,
  externalResultsEndpoint,
  input = {},
  resultsDescription,
  submit,
  searchSuggestions,
  theme
}) => {
  const inputElement = React.useRef();
  const [externalResultsCount, setExternalResultsCount] = React.useState(0);
  const [externalResultsUrl, setExternalResultsUrl] = React.useState('');
  const [inputValue, setInputValue] = React.useState(input.value || '');

  const onInputChange = event => {
    setInputValue(event.target.value);
  };

  const removeText = () => {
    inputElement.current.focus();
    setInputValue('');
  };

  React.useEffect(() => {
    if (externalResultsEndpoint) {
      api.get(externalResultsEndpoint).then(({ Hits, url }) => {
        setExternalResultsCount(Hits);
        setExternalResultsUrl(url);
      });
    }
  }, [externalResultsEndpoint]);

  React.useEffect(() => {
    // On every new input response from backend:
    if (typeof input.value !== 'string') return;
    setInputValue(input.value);
  }, [input.dataId]);

  return (
    <div className={cn('search', theme)}>
      <div className="search--input-wrapper">
        <div className="search--field">
          <TextInput
            className="search--input"
            type="search"
            icon="search"
            onChange={onInputChange}
            ref={inputElement}
            theme={[TextInput.themes.rounded, TextInput.themes.hiddenLabel]}
            controlled
            {...input}
            value={inputValue}
          />
          {!!inputValue.length && (
            <Button className="search--clear" icon="x" onClick={removeText} />
          )}
        </div>

        <Button
          className="search--submit"
          type="submit"
          theme={[Button.themes.primary, Button.themes.big]}
          {...submit}
        />
      </div>

      <div className="search--results">
        {resultsDescription}
        {externalResultsUrl && (
          <Link
            icon="external-link-arrow"
            iconClassName="search--results-icon"
            url={externalResultsUrl}
            text={`${externalResultsCount} ${externalResultsLabel}`}
          />
        )}
        {searchSuggestions && <SearchSuggestions {...searchSuggestions} />}
      </div>
    </div>
  );
};

Search.propTypes = {
  externalResultsLabel: PropTypes.string,
  externalResultsEndpoint: PropTypes.string,
  input: PropTypes.shape(TextInput.propTypes),
  resultsDescription: PropTypes.string,
  submit: PropTypes.exact(Button.propTypes),
  searchSuggestions: PropTypes.exact(SearchSuggestions.propTypes)
};

export default Search;
