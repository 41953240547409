import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

  const Tooltip = ({ children, useTabStyle }) => {
  const [mousePos, setMousePos] = useState();

  const onMove = useCallback(e => {
    setMousePos({ x: e.clientX, y: e.clientY });
  }, []);

  useEffect(() => {
    if (useTabStyle) return;
    window.addEventListener('mousemove', onMove);
    return () => window.removeEventListener('mousemove', onMove);
  }, []);

  return mousePos || useTabStyle ? (
    <div
      className={cn('tooltip', { '-tab-triggered': useTabStyle })}
      style={
        mousePos && {
          transform: `translate(${mousePos.x + 5}px, ${mousePos.y}px)`
        }
      }
    >
      {children}
    </div>
  ) : null;
};

Tooltip.propTypes = { children: PropTypes.node, useTabStyle: PropTypes.bool };
Tooltip.propTypesMeta = 'exclude';

export default Tooltip;
