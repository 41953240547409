import React from 'react';
import PropTypes from 'prop-types';

const Published = ({ type, date }) => {
  return (
    <div className="published">
      <b>{`${type}:`}</b>
      {date}
    </div>
  );
};

Published.propTypes = {
  type: PropTypes.string,
  date: PropTypes.string
};

export default Published;
