/* eslint-disable no-unused-vars */
/* eslint-disable react/no-multi-comp */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useResize from 'js/hooks/resize';

const ChildHoF = setHeight => {
  const Child = ({ children, className }) => {
    const forceUpdate = useState()[1];

    useResize(forceUpdate);

    return (
      <div
        className={className}
        ref={node =>
          node && node.clientHeight && setHeight(`${node.clientHeight}px`)
        }
      >
        {children}
      </div>
    );
  };

  Child.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
  };
  Child.propTypesMeta = 'exclude';

  return Child;
};

const HeightOfChild = ({ children, className }) => {
  const [height, setHeight] = useState();
  return (
    <div className={className} style={{ height }}>
      {children(ChildHoF(setHeight))}
    </div>
  );
};

HeightOfChild.propTypes = {
  children: PropTypes.func,
  className: PropTypes.string
};

HeightOfChild.propTypesMeta = 'exclude';

export default HeightOfChild;
