import React from 'react';
import PropTypes from 'prop-types';

import icons from '../../assets-old/inline-svg-icons/icons';
import isDeveloperEnvironment from 'js/utils/is-developer-environment';

import Icon from 'components-old/icon';
import Link from 'components-old/link';

const types = {
  video: 'video',
  calendar: 'calendar'
};

// Custom validation here because the propTypes-C#-plugin does not support references to external files in PropTypes.oneOf, meaning that the 'types' object must use hard coded magic strings instead of references to existing icons.
// These checks check whether 'icons' have a definition for the magic strings defined in 'types'
if (isDeveloperEnvironment()) {
  Object.values(types).forEach(type => {
    // eslint-disable-next-line no-console
    if (!icons[type]) console.error(`No icon for type "${type}"`);
  });
}

const EventPageLink = ({ icon, text, url }) => (
  <div className="event-page-link">
    <div className="event-page-link--icon">
      <Icon fill={true} name={icon} />
    </div>
    <Link url={url} text={text} />
  </div>
);

EventPageLink.propTypes = {
  icon: PropTypes.oneOf(Object.values(types)).isRequired,
  text: PropTypes.string,
  url: PropTypes.string
};

export default EventPageLink;
