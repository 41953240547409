import React from 'react';
import PropTypes from 'prop-types';

import OPE from 'js/on-page-editing';

import Link from 'components/link';

const NumberBlock = ({ description, number, onPageEditing, text, url, link }) => (
  <div className="number-block">
    <div {...OPE(onPageEditing.url)} />
    {link &&
      <span className='has-action-link' {...OPE(onPageEditing.text)}>
        <Link theme={Link.themes.actionLink} url={link.url}>{link.text && <h2>{link.text}</h2>}</Link>
      </span>
    }
    <span {...OPE(onPageEditing.text)}>
      <Link url={url}>{text && <h2>{text}</h2>}</Link>
    </span>
    <div className="number-block--number-wrapper">
      <span className="number-block--number" {...OPE(onPageEditing.number)}>
        {number}
      </span>
      <span
        className="number-block--description"
        {...OPE(onPageEditing.description)}
      >
        {description}
      </span>
    </div>
  </div>
);

NumberBlock.propTypes = {
  description: PropTypes.string,
  number: PropTypes.string,
  onPageEditing: PropTypes.exact({
    description: PropTypes.string,
    number: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string
  }),
  // The 'text' and 'url' props are separated here (instead of combined in a 'link' object) because the 'url' property is a property of the entire block, not just the 'text' property
  text: PropTypes.string,
  url: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string
  })
};

NumberBlock.defaultProps = {
  onPageEditing: {}
};

export default NumberBlock;
