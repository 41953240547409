import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import OPE from 'js/on-page-editing';

import Button from 'components/button';
import FluidImage from 'components/fluid-image';
import Link from 'components/link';

const themes = {
  yellow: '-theme-yellow',
  red: '-theme-red',
  esaki: '-theme-esaki',
  marconi: '-theme-marconi',
  alfven: '-theme-alfven',
};

const FrontpageHeader = ({
  image,
  title,
  description,
  links,
  onPageEditing,
  editorTheme
}) => {
  return (
    <div className={cn('frontpage-header', editorTheme)}>
      <div className="frontpage-header--prioritised-content">
        {title && <h1 className="frontpage-header--title">{title}</h1>}
        {description && (
          <p className="frontpage-header--description">{description}</p>
        )}
        <div className="frontpage-header--links" {...OPE(onPageEditing.links)}>
          {links.map((link, index) => (
            <Link
              className="frontpage-header--link"
              key={`${link.url}-${index}`}
              useButtonStyles
              theme={[
                index === 0 ? Button.themes.signal : Button.themes.secondary,
                Button.themes.large
              ]}
              {...link}
            />
          ))}
        </div>
      </div>
      <div className="frontpage-header--image">
        <FluidImage {...image} theme={FluidImage.themes.anchored} />
      </div>
    </div>
  );
};

FrontpageHeader.propTypes = {
  image: PropTypes.exact(FluidImage.propTypes),
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  onPageEditing: PropTypes.exact({
    links: PropTypes.string,
    numbers: PropTypes.string,
    text: PropTypes.string
  }),
  title: PropTypes.string,
  description: PropTypes.string,
  editorTheme: PropTypes.oneOf(Object.values(themes))
};

FrontpageHeader.defaultProps = {
  links: {},
  onPageEditing: {}
};

export default FrontpageHeader;
