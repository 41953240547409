import React from 'react';
import PropTypes from 'prop-types';

import Button from 'components-old/button';
import ContentContainer from 'components-old/content-container';
import HtmlString from 'components-old/html-string';
import Link from 'components-old/link';
import RichText from 'components-old/rich-text';
import SocialMediaLinkList from 'components-old/social-media-link-list';

const Footer = ({
  contactInfo,
  infoLinks,
  linkLists,
  newsLetter,
  socialMedia
}) => (
  <footer className="footer">
    <ContentContainer
      className="footer--content"
      theme={ContentContainer.themes.wide}
    >
      <div className="footer--contact-info">
        {contactInfo && (
          <RichText
            additionalComponentProps={{
              HtmlString: { theme: HtmlString.themes.white }
            }}
            {...contactInfo}
          />
        )}
        {newsLetter && (
          <Link
            className="footer--newsletter-link"
            theme={[Button.themes.orangeOutline, Button.themes.white]}
            useButtonStyles
            {...newsLetter}
          />
        )}
      </div>
      {linkLists &&
        !!linkLists.length &&
        linkLists.map(({ title, links, id }) => (
          <div className="footer--link-list" key={id}>
            {title && <h2>{title}</h2>}
            {links && !!links.length && (
              <ul>
                {links.map(link => (
                  <li key={link.text}>
                    <Link theme={[Link.themes.white]} {...link} />
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      <div className="footer--info-links">
        <SocialMediaLinkList
          className="footer--social-media-link-list"
          theme={SocialMediaLinkList.themes.white}
          {...socialMedia}
        />
        {infoLinks && !!infoLinks.length && (
          <ul>
            {infoLinks.map(link => (
              <li key={link.text}>
                <Link theme={Link.themes.white} {...link} />
              </li>
            ))}
          </ul>
        )}
      </div>
    </ContentContainer>
  </footer>
);

Footer.propTypes = {
  contactInfo: PropTypes.exact(RichText.propTypes),
  infoLinks: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  linkLists: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.number,
      title: PropTypes.string,
      links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes))
    })
  ),
  newsLetter: PropTypes.exact(Link.propTypes),
  socialMedia: PropTypes.exact(SocialMediaLinkList.propTypes)
};
export default Footer;
