import React from 'react';
import PropTypes from 'prop-types';

import Link from 'components-old/link';
import Heading from 'components-old/heading';

const ContactListItem = ({ title, contactLists = [], onPageEditing = {} }) => {
  return (
    <div className="contact-list-item">
      {title && (
        <Heading
          className="contact-list-item--title"
          onPageEditing={onPageEditing.title}
        >
          {title}
        </Heading>
      )}
      {contactLists.map(({ name, email, phone }) => (
        <div className="contact-list-item--info" key={name.text}>
          <div>
            {name && <span>{name.text}</span>}
            {name.position && <div>{name.position}</div>}
          </div>
          <div>{email && <Link {...email} theme={Link.themes.small} />}</div>
          <div>{phone && <Link {...phone} theme={Link.themes.small} />}</div>
        </div>
      ))}
    </div>
  );
};

ContactListItem.propTypes = {
  title: PropTypes.string.isRequired,
  contactLists: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.exact({
        text: PropTypes.string,
        position: PropTypes.string
      }),
      email: PropTypes.exact(Link.propTypes),
      phone: PropTypes.exact(Link.propTypes)
    })
  ),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string
  })
};

export default ContactListItem;
