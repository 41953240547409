import React from 'react';
import PropTypes from 'prop-types';

const Hyphenate = ({ text }) => {
  if (!text) return null;

  const shouldHyphenate = /¤¤/.test(text);
  return shouldHyphenate ? (
    <span
      className="hyphenate"
      dangerouslySetInnerHTML={{ __html: text.replace(/¤¤/g, '&shy;') }}
    />
  ) : (
    <span className="hyphenate">{text}</span>
  );
};

Hyphenate.propTypes = {
  text: PropTypes.string
};

Hyphenate.propTypesMeta = 'exclude';

export default Hyphenate;
