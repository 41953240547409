/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import EventImage from 'components-old/event-image';
import propTypeTheme from 'js/utils/prop-type-theme';
import HeightOfChild from 'components-old/height-of-child';
import Icon from 'components-old/icon';
import DatesMore from 'components-old/dates-more';
import DateField from 'components-old/date-field';
import Link from 'components-old/link';
import useIsScreenSize from 'js/hooks/use-is-screen-size';

const themes = {
  big: '-theme-big'
};

const hasSameValue = (dates, key) =>
  dates.length === 2 && dates[0][key] === dates[1][key];

const DateCardDates = ({
  datesTitle,
  dates,
  datesDescription,
  isPastDate,
  isInternational,
  isPlanned,
  labels,
  eventImage,
  className,
  theme,
  setHeight,
  moreDates,
  date,
  url,
  isEventList,
  heightOfName
}) => {
  const setParentHeight = height => {
    if (!height) return;
    setHeight(`${height}px`);
  };
  const singleYear = hasSameValue(dates, 'year');
  const singleMonth = hasSameValue(dates, 'month') && singleYear;

  const isBigTheme = theme && theme.indexOf('-theme-big') !== -1;

  const LinkOrFragment = url ? Link : React.Fragment;
  const LinkOrFragmentProps = url
    ? { url, attributes: { tabIndex: '-1' } }
    : {};

  const isDesktop = useIsScreenSize('md');
  const style =
    heightOfName && isDesktop ? { minHeight: `${270 - heightOfName}px` } : {};

  return (
    <div
      className={cn('date-card-dates', className, theme, {
        '-single-period': singleMonth || singleYear,
        '-light-text': eventImage,
        '-is-past-date': isPastDate,
        '-is-planned': isPlanned,
        '-has-more-dates': date && moreDates
      })}
      ref={node => node && setHeight && setParentHeight(node.clientHeight)}
      style={style}
    >
      <LinkOrFragment {...LinkOrFragmentProps}>
        {eventImage && (
          <EventImage
            {...eventImage}
            darkFilter={eventImage.image && !!eventImage.image.src}
          />
        )}

        {date && (
          <div className="date-card-dates--date">
            <DateField
              date={date}
              transparent={
                isPastDate ||
                (eventImage && eventImage.image && !!eventImage.image.src)
              }
              theme={[DateField.themes[isBigTheme ? 'medium' : 'small']]}
            />
            {isPastDate && (
              <div className="date-card-dates--past-date">
                {labels.pastDate}
              </div>
            )}
          </div>
        )}
      </LinkOrFragment>
      {moreDates && (
        <DatesMore
          {...moreDates}
          theme={DatesMore.themes[isBigTheme ? 'medium' : 'small']}
        />
      )}
      {datesTitle && (
        <div className="date-card-dates--description-top">{datesTitle}</div>
      )}
      {!!dates.length && (
        <div className="date-card-dates--dates">
          {dates.map((date, index) => (
            <div className="date-card-dates--date" key={date.day + date.month}>
              <div className="date-card-dates--day">{date.day}</div>
              {((singleMonth && !!index) || !singleMonth) && (
                <HeightOfChild>
                  {Child => (
                    <Child
                      className={cn('date-card-dates--month', {
                        '-single-period': singleMonth
                      })}
                    >
                      {date.month}
                    </Child>
                  )}
                </HeightOfChild>
              )}
              {((singleYear && !!index) || !singleYear) && (
                <HeightOfChild includeWidth>
                  {Child => (
                    <Child
                      className={cn('date-card-dates--year', {
                        '-single-period': singleYear
                      })}
                    >
                      {date.year}
                    </Child>
                  )}
                </HeightOfChild>
              )}
            </div>
          ))}
        </div>
      )}
      {datesDescription && (
        <div className="date-card-dates--description">{datesDescription}</div>
      )}
      {isInternational && (
        <div className="date-card-dates--international">
          <Icon
            className="date-card-dates--international-icon"
            name="globe-old"
          ></Icon>
        </div>
      )}
      {!isPlanned && !isInternational && !date && (
        <div className="date-card-dates--bar" />
      )}
    </div>
  );
};

DateCardDates.propTypes = {
  dates: PropTypes.arrayOf(
    PropTypes.exact({
      day: PropTypes.string,
      month: PropTypes.string,
      year: PropTypes.string
    })
  ),
  datesDescription: PropTypes.string,
  datesTitle: PropTypes.string,
  isPastDate: PropTypes.bool,
  isInternational: PropTypes.bool,
  isPlanned: PropTypes.bool,
  className: PropTypes.string,
  eventImage: PropTypes.exact(EventImage.propTypes),
  theme: propTypeTheme(themes),
  setHeight: PropTypes.func,
  labels: PropTypes.exact({
    pastDate: PropTypes.string
  }),
  moreDates: PropTypes.exact(DatesMore.propTypes),
  date: PropTypes.string,
  url: PropTypes.string,
  nameHeight: PropTypes.number
};

DateCardDates.defaultProps = {
  dates: [],
  labels: {}
};

DateCardDates.propTypesMeta = {
  setHeight: 'exclude',
  theme: 'exclude',
  url: 'exclude',
  nameHeight: 'exclude'
};
DateCardDates.themes = themes;

export default DateCardDates;
