import React from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import cn from 'classnames';

import Button from 'components/button';
import CheckboxGroup from 'components/checkbox-group';
import ContentArea from 'components/content-area';
import Form from 'components/form';
import PageFooter from 'components/page-footer';
import RichText from 'components/rich-text';
import InputEmail from 'components/input-email';
import Message from 'components/message';
import ContentContainer from 'components/content-container';
import ContentWithSidebar from 'components/content-with-sidebar';
import GenericTextInputGroup from 'components/text-input-group/text-input-with-header';

const NewsletterRegistrationPage = React.forwardRef(
  (
    {
      checkboxGroups = [],
      genericTextInputGroup = {},
      footer,
      form,
      inputFields,
      inputTitle,
      richText,
      submitButton,
      htmlString,
      title,
      sidebar,
      onSubmit,
      showFormError = true,
      setIsChecked,
      showEmailError,
      setIsValidEmail
    },
    ref
  ) => {
    const [message, setMessage] = useState({});

    return (
      <Form
        className="newsletter-registration-page"
        showSubmitButton={false}
        onSubmit={onSubmit}
        {...form}
      >
        {htmlString && <div dangerouslySetInnerHTML={{ __html: htmlString }} />}
        <ContentContainer>{title && <h1>{title}</h1>}</ContentContainer>

        <ContentWithSidebar>
          <ContentWithSidebar.Content>
            <RichText {...richText} />
            <div className="newsletter-registration-page--message" ref={ref}>
              {showFormError && message && <Message {...message} />}
            </div>
            {checkboxGroups.map(checkboxGroup => (
              <CheckboxGroup
                key={checkboxGroup.title}
                setIsChecked={checkboxGroup.required ? setIsChecked : undefined}
                setMessage={setMessage}
                {...checkboxGroup}
              />
            ))}
            {genericTextInputGroup && (
              <div className="newsletter-registration-page--group">
                <GenericTextInputGroup
                  key={genericTextInputGroup.title}
                  title={genericTextInputGroup.title}
                  options={genericTextInputGroup.options}
                />
              </div>
            )}
            {inputTitle && (
              <h2 className="newsletter-registration-page--email-title">
                {inputTitle}
              </h2>
            )}
            {inputFields &&
              inputFields.map(inputField => (
                <InputEmail
                  key={inputField.name}
                  isValid={!showEmailError}
                  setIsValid={setIsValidEmail}
                  {...inputField}
                />
              ))}
            <Button
              className="newsletter-registration-page--submit"
              theme={[Button.themes.big, Button.themes.primary]}
              type="submit"
              {...submitButton}
            />
            <div className="newsletter-registration-page--footer">
              <PageFooter {...footer} />
            </div>
          </ContentWithSidebar.Content>
          <ContentWithSidebar.Sidebar>
            <ContentArea enableElementSizing={false} {...sidebar} />
          </ContentWithSidebar.Sidebar>
        </ContentWithSidebar>
      </Form>
    );
  }
);
NewsletterRegistrationPage.propTypes = {
  checkboxGroups: PropTypes.arrayOf(PropTypes.exact(CheckboxGroup.propTypes)),
  genericTextInputGroup: PropTypes.exact(GenericTextInputGroup.propTypes),
  footer: PropTypes.exact(PageFooter.propTypes),
  form: PropTypes.exact(Form.propTypes),
  inputTitle: PropTypes.string,
  inputFields: PropTypes.arrayOf(PropTypes.exact(InputEmail.propTypes)),
  richText: PropTypes.exact(RichText.propTypes),
  sidebar: PropTypes.exact(ContentArea.propTypes),
  submitButton: PropTypes.exact(Button.propTypes),
  title: PropTypes.string,
  htmlString: PropTypes.string,
  onSubmit: PropTypes.func,
  showFormError: PropTypes.bool,
  setIsChecked: PropTypes.func,
  showEmailError: PropTypes.bool,
  setIsValidEmail: PropTypes.func
};

NewsletterRegistrationPage.propTypesMeta = {
  showFormError: 'exclude',
  showEmailError: 'exclude'
};

export default NewsletterRegistrationPage;
