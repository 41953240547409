import React from 'react';
import PropTypes from 'prop-types';

import OPE from 'js/on-page-editing';

import ArticleHeader from 'components-old/article-header';
import ContentArea from 'components-old/content-area';
import ContentWithSidebar from 'components-old/content-with-sidebar';
import RichText from 'components-old/rich-text';
import PageFooter from 'components-old/page-footer';
import Heading from 'components-old/heading';
import ImageWithCaption from 'components-old/image-with-caption';

const ArticlePage = ({
  content,
  footer,
  header,
  ingress,
  onPageEditing,
  sidebar,
  title,
  image
}) => (
  <React.Fragment>
    <ContentWithSidebar>
      <ContentWithSidebar.Content>
        {title && (
          <Heading
            level={1}
            data-epi-type="title"
            {...OPE(onPageEditing.title)}
          >
            {title}
          </Heading>
        )}
      </ContentWithSidebar.Content>
      <ContentWithSidebar.Sidebar />
    </ContentWithSidebar>

    <ContentWithSidebar>
      <ContentWithSidebar.Content>
        <div data-epi-type="content">
          {(ingress || onPageEditing.ingress) && (
            <div
              className="article-page--ingress"
              {...OPE(onPageEditing.ingress)}
            >
              <p>{ingress}</p>
            </div>
          )}
          <ArticleHeader {...header} />
          {image && <ImageWithCaption {...image} />}
          <RichText {...content} />
        </div>
      </ContentWithSidebar.Content>

      <ContentWithSidebar.Sidebar className="article-page--sidebar">
        <ContentArea enableElementSizing={false} {...sidebar} />
      </ContentWithSidebar.Sidebar>
    </ContentWithSidebar>

    <ContentWithSidebar>
      <ContentWithSidebar.Content>
        <div className="article-page--footer" data-epi-type="content">
          <PageFooter {...footer} />
        </div>
      </ContentWithSidebar.Content>
    </ContentWithSidebar>
  </React.Fragment>
);

ArticlePage.propTypes = {
  content: PropTypes.exact(RichText.propTypes),
  footer: PropTypes.exact(PageFooter.propTypes),
  header: PropTypes.exact(ArticleHeader.propTypes),
  ingress: PropTypes.string,
  onPageEditing: PropTypes.exact({
    ingress: PropTypes.string,
    title: PropTypes.string
  }),
  sidebar: PropTypes.exact(ContentArea.propTypes),
  title: PropTypes.string,
  image: PropTypes.exact(ImageWithCaption.propTypes)
};

ArticlePage.defaultProps = {
  onPageEditing: {}
};

export default ArticlePage;
