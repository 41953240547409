import React /*, { useState } */ from 'react';
import PropTypes from 'prop-types';

import YoutubeVideo from 'components-old/youtube-video/youtube-video';
//import Button from 'components-old/button/button';

const VideoBlock = ({ children, video, step, title, text /*, playText*/ }) => {
  //const [play, setPlay] = useState();

  return (
    <div className="video-block">
      {children}
      <div className="video-block--video">
        <YoutubeVideo /*play={play}*/ {...video} />
      </div>
      <div className="video-block--content">
        <h2 className="video-block--title">
          <span className="video-block--title-upper">{step}</span>
          {title}
        </h2>
        <p className="video-block--text">{text}</p>
        {/*<Button
          className="video-block--button"
          theme={[Button.themes.fill, Button.themes.big]}
          text={playText}
          onClick={() => setPlay(true)}
        /> */}
      </div>
    </div>
  );
};

VideoBlock.propTypes = {
  children: PropTypes.node,
  video: PropTypes.exact(YoutubeVideo.propTypes),
  step: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  playText: PropTypes.string
};

VideoBlock.propTypesMeta = {
  children: 'exclude'
};

export default VideoBlock;
