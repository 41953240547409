import React from 'react';
import PropTypes from 'prop-types';

import CampaignBlock from 'components-old/campaign-block';

const CampaignBlockList = ({ list }) => (
  <div className="campaign-block-list">
    {list &&
      list.map((campaignBlock, index) => (
        <CampaignBlock
          key={`${campaignBlock.title}-${index}`}
          {...campaignBlock}
          isColumn={list.length > 1}
        />
      ))}
  </div>
);

CampaignBlockList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.exact(CampaignBlock.propTypes))
};

export default CampaignBlockList;
