import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import Button from 'components-old/button';
import ContentContainer from 'components-old/content-container';
import FluidImage from 'components-old/fluid-image';
import Link from 'components-old/link';
import Map from 'components-old/map';
import TagLinkList from 'components-old/tag-link-list';
import Heading from 'components-old/heading';
import OPE from 'js/on-page-editing';
import PortfolioHeader from 'components-old/portfolio-header';

const PageHeader = ({
  image,
  ingress,
  links,
  map,
  onPageEditing,
  tags,
  title,
  portfolioLinks,
  portfolioHeader
}) => (
  <div className="page-header">
    {(image || map || links) && (
      <div className={cn('page-header--top', { '-has-image': image || map })}>
        {map && <Map {...map} />}
        {image && <FluidImage {...image} />}
        {links && (
          <ContentContainer className="page-header--links-container">
            <div className="page-header--links" {...OPE(onPageEditing.links)}>
              {links.map(link => (
                <Link
                  className="page-header--link"
                  key={link.url}
                  useButtonStyles={true}
                  theme={[Button.themes.fill, Button.themes.big]}
                  {...link}
                />
              ))}
            </div>
          </ContentContainer>
        )}
      </div>
    )}
    {title && (
      <div className="page-header--title" {...OPE(onPageEditing.title)}>
        <Heading level={1}>{title}</Heading>
      </div>
    )}
    <ContentContainer>
      {portfolioHeader && <PortfolioHeader {...portfolioHeader} />}
      <div {...OPE(onPageEditing.ingress)}>
        {ingress && (
          <p
            className={cn('page-header--ingress', {
              '-has-portfolio-links': portfolioLinks
            })}
          >
            {ingress}
          </p>
        )}
        {portfolioLinks && !!portfolioLinks.length && (
          <div className="page-header--portfolio-links">
            {portfolioLinks.map((link, index) => {
              const theme = !index
                ? [Button.themes.fill, Button.themes.big]
                : [Button.themes.orangeOutline, Button.themes.big];
              return (
                <Link
                  key={link.text}
                  className="page-header--portfolio-link"
                  {...link}
                  theme={theme}
                  useButtonStyles
                />
              );
            })}
          </div>
        )}
        {tags && <TagLinkList {...tags} />}
      </div>
    </ContentContainer>
  </div>
);

PageHeader.propTypes = {
  image: PropTypes.exact(FluidImage.propTypes),
  ingress: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  portfolioLinks: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  map: PropTypes.exact(Map.propTypes),
  onPageEditing: PropTypes.exact({
    title: PropTypes.string,
    ingress: PropTypes.string,
    links: PropTypes.string
  }),
  title: PropTypes.string,
  tags: PropTypes.exact(TagLinkList.propTypes),
  portfolioHeader: PropTypes.exact(PortfolioHeader.propTypes)
};

PageHeader.defaultProps = {
  onPageEditing: {}
};

export default PageHeader;
