import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from 'components-old/icon';
import Image from 'components-old/image';
import Heading from 'components-old/heading';
import Button from 'components-old/button';

import LinkWrapper from 'components-old/link-wrapper';

const ProcessItem = ({ icon, text, index, url, title }) => {
  const { OuterLink } = LinkWrapper(!!url, url);
  const [hasOverflowingText, setHasOverflowingText] = useState(false);
  const [expand, setExpand] = useState(false);
  const [hover, setHover] = useState(false);
  let wrapperRef = useRef(null);

  useEffect(() => {
    setHasOverflowingText(
      wrapperRef.current.offsetHeight < wrapperRef.current.scrollHeight
    );
  }, [wrapperRef]);

  const expandText = e => {
    e.stopPropagation();
    setExpand(!expand);
  };

  return (
    <div
      className={cn('process-item', {
        'process-item--expand': expand,
        'process-item--has-link': url,
        'process-item--button-hover': hover
      })}
    >
      <OuterLink className="process-item--link" noHoverEffect={true}>
        <div className="process-item--wrapper" ref={wrapperRef}>
          <div className="process-item--header">
            {icon ? (
              <Image className="process-item--icon" {...icon} />
            ) : (
              <Heading className="process-item--number" level={2}>
                {index + 1}
              </Heading>
            )}
            <Icon className="process-item--arrow" name="long-arrow" fill />
          </div>

          {title && <p className="process-item--title">{title}</p>}
          {text && <p className="process-item--text">{text}</p>}
        </div>
      </OuterLink>
      {hasOverflowingText && (
        <Button
          className="process-item--button"
          icon="small-arrow-down"
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={e => expandText(e)}
        />
      )}
    </div>
  );
};

ProcessItem.propTypes = {
  icon: PropTypes.exact(Image.propTypes),
  text: PropTypes.string,
  index: PropTypes.number,
  url: PropTypes.string,
  title: PropTypes.string
};

ProcessItem.propTypesMeta = {
  index: 'exclude'
};

export default ProcessItem;
