import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Link from 'components/link';
import Heading from 'components/heading';
import DocumentIcon from 'components/document-icon';
import DownloadIcon from 'images/download-icon.svg';
import FluidImage from 'components/fluid-image';
import Icon from 'components/icon';

import cn from 'classnames';
import Button from 'components/button';

const themes = {
  narrow: '-theme-narrow',
  grey: '-theme-grey',
  prominent: '-theme-prominent'
};

const DownloadList = ({
  groups,
  downloadAllText,
  downloadAllUrl,
  theme,
  requiredText
}) => {
  return (
    <div className={cn('download-list', theme)}>
      {groups.map((group, index) => (
        <div className="download-list--group" key={group.heading + index}>
          {group.heading && (
            <Heading className="download-list--heading">
              {group.heading}
            </Heading>
          )}
          {group.subheading && (
            <Heading className="download-list--subheading">
              {group.subheading}
            </Heading>
          )}
          <ul>
            {group.items.map(item => (
              <li key={item.text}>
                <Link
                  className="download-list--item"
                  url={item.url}
                  noHoverEffect
                >
                  <DocumentIcon
                    className="download-list--item-icon"
                    size={
                      theme === themes.narrow
                        ? DocumentIcon.sizes.small
                        : undefined
                    }
                    iconTheme={item.iconTheme}
                    isRequired={item.isRequired}
                  />
                  <div className="download-list--item-text">
                    <div className="download-list--item-text-name">
                      <span>{item.text}</span>
                    </div>
                    <div className="download-list--item-text-size">
                      {item.size}
                    </div>
                  </div>
                  <div className="download-list--download-icon">
                    <FluidImage src={DownloadIcon} alt="ikon" />
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
      {requiredText && (
        <div className="download-list--required">
          <Icon name="asterix" />
          {requiredText}
        </div>
      )}

      <div>
        {downloadAllText && downloadAllUrl && (
          <div className="download-list--download-all">
            <Link
              icon="download-round-large"
              url={downloadAllUrl}
              text={downloadAllText}
              theme={[Button.themes.secondary, Button.themes.small]}
              useButtonStyles
            />
          </div>
        )}
      </div>
    </div>
  );
};

DownloadList.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.exact({
      heading: PropTypes.string,
      subheading: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          iconTheme: DocumentIcon.propTypes.iconTheme,
          url: PropTypes.string,
          isRequired: PropTypes.bool
        })
      )
    })
  ),
  downloadAllText: PropTypes.string,
  downloadAllUrl: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(themes)),
  requiredText: PropTypes.string
};

DownloadList.defaultProps = {
  groups: []
};

DownloadList.propTypesMeta = {
  theme: 'exclude'
};

DownloadList.themes = themes;

export default DownloadList;
