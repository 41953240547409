import React from 'react';
import PropTypes from 'prop-types';

import DescriptionListBlock from 'components/description-list-block';
import TableBlock from 'components/table-block';

const DescriptionListAndTables = ({ descriptionList, tables }) => (
  <React.Fragment>
    <DescriptionListBlock {...descriptionList} />
    {tables.map(table => (
      <div className="description-list-and-tables--table" key={table.title}>
        <TableBlock key={table.title} {...table} isWide />
      </div>
    ))}
  </React.Fragment>
);

DescriptionListAndTables.propTypes = {
  descriptionList: PropTypes.exact(DescriptionListBlock.propTypes),
  tables: PropTypes.arrayOf(PropTypes.exact(TableBlock.propTypes))
};

DescriptionListAndTables.defaultProps = {
  tables: []
};

export default DescriptionListAndTables;
